import { nanoid } from 'nanoid';
import { PICKLIST_PLUGIN_ID } from './types';
import type { PicklistPluginElement, ReportPicklistBeforeSubmissionOptions } from './types';
import { Editor, Node, Transforms } from '../../core';
import type { NodeEntry } from '../../core';
import { getMacroEntry } from '../macroPlaceholder';
import type { MacroPlaceholderType, Macro } from '../macroPlaceholder';
import { unreachableCaseLog } from 'types';
import type { SlateContent } from '../../types';
import { replaceNodeAtPath, fragmentToString } from '../../utils';
import { isMacro } from '../macroPlaceholder/utils';
import { stitchNodesIntoEditor } from '../../stitching';
import { Element } from 'slate';

export const insertPicklist = (): PicklistPluginElement => ({
  // Unique id used to associate the picklist element with its metadata
  picklistID: nanoid(),
  type: PICKLIST_PLUGIN_ID,
  children: [{ text: '' }],
});

const getInsertPicklistMacro = (
  editor: Editor,
  picklistEntry: NodeEntry<PicklistPluginElement>,
  picklistContent: SlateContent
): [MacroPlaceholderType, Macro] => {
  const [picklistNode] = picklistEntry;

  const [macroType, macro] = getMacroEntry(editor, picklistContent);

  switch (macroType) {
    case 'plainText':
      return ['plainText', [{ ...picklistNode, children: [{ text: picklistContent }] }]];
    case 'macroText':
      return ['macroText', [{ ...picklistNode, children: [...macro] }]];
    case 'macroInline':
      return ['macroInline', macro];
    case 'macroBlock':
      return ['macroBlock', macro];

    default:
      unreachableCaseLog(macroType);
      // @ts-expect-error [invalid-tuple-arity] we handle this case in the calling function
      return [];
  }
};

// In the report editor, when a picklist is required and touched, the required field indicator is
// displayed and needs to be removed after insertion
export const insertSelectedPicklistItem = (
  editor: Editor,
  picklistEntry: NodeEntry<PicklistPluginElement>,
  picklistContent: SlateContent,
  onInsertNonEmptyPicklist?: () => void
): void => {
  Editor.withoutNormalizing(editor, () => {
    const [, content] = getMacroEntry(editor, picklistContent);
    const [macroType, normalizedMacro] = getInsertPicklistMacro(
      editor,
      picklistEntry,
      picklistContent
    );

    const [, picklistPath] = picklistEntry;

    const stitchPicklistContent = () => {
      Transforms.delete(editor, { at: Editor.range(editor, picklistPath) });

      stitchNodesIntoEditor(editor, content, {
        at: Editor.range(editor, picklistPath),
        select: true,
      });
    };

    switch (macroType) {
      case 'plainText':
      case 'macroText':
        replaceNodeAtPath(editor, normalizedMacro, { at: picklistPath });
        break;

      case 'macroInline':
      case 'macroBlock':
        stitchPicklistContent();
        break;

      default:
        unreachableCaseLog(macroType);
    }

    // handle required fields
    if (
      onInsertNonEmptyPicklist !== undefined &&
      normalizedMacro.length === 1 &&
      normalizedMacro[0].children[0].text !== ''
    ) {
      onInsertNonEmptyPicklist();
    }
  });
};

export const getPicklistOptionDisplayName = (
  option: ReportPicklistBeforeSubmissionOptions
): string => {
  return option.name != null
    ? option.name
    : isMacro(option.text)
      ? fragmentToString(option.text)
      : // @ts-expect-error [EN-7967] - TS2322 - Type 'readonly JSON[]' is not assignable to type 'string'.
        option.text;
};

export const getPicklistsNodesFromParentNode = (root: Node): PicklistPluginElement[] => {
  const nodes = Node.nodes(root);

  const picklistNodes: Array<PicklistPluginElement> = [];
  for (const [picklistNode] of Array.from(nodes)) {
    if (Element.isElement(picklistNode) && picklistNode.type === PICKLIST_PLUGIN_ID) {
      picklistNodes.push({
        picklistID: picklistNode.picklistID,
        children: picklistNode.children,
        type: PICKLIST_PLUGIN_ID,
      });
    }
  }

  return picklistNodes;
};
