import { useReducer, useCallback, useRef, useMemo, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { nanoid } from 'nanoid';
import Input from 'common/ui/Input';
import Table from 'common/Table';
import { Button } from 'common/ui/Button';
import { Colors, TextCss } from 'styles';
import { Transforms, useEditor, ReactEditor, useSelected, Editor } from '../../../core';
import { find, fragmentToString } from '../../../utils';
import { isMacro } from '../../macroPlaceholder/utils';
import { INLINE_BOOKMARK_PLUGIN_ID } from '../../inlineBookmark/types';
import { PICKLIST_PLUGIN_ID } from '../types';
import { useCompactUI } from 'hooks/useCompactUI';

import {
  RichTextEditor,
  ParagraphPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  PlaceholderPlugin,
  InlineBookmarkPlugin,
  ClearFormattingPlugin,
  DictaphoneControlsPlugin,
  DictationPlugin,
  BoldPlugin,
  FieldContentWrapper,
  insertSelectedPicklistItem,
} from 'domains/reporter/RichTextEditor';
import { useRecorder } from 'common/Recorder/useRecorder';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { createParagraph } from '../../paragraph/utils';
import type { ReportPicklistBeforeSubmissionOptions } from '../types';
import type { SlateContent } from '../../../../Reporter/types';

type PicklistBuilderFormProps = {
  options: Array<ReportPicklistBeforeSubmissionOptions>;
  state: any;
  onSave: (props: {
    picklistID: string;
    options: Array<ReportPicklistBeforeSubmissionOptions>;
  }) => void | null | undefined;
  onRemove: (props: { readonly picklistID: string }) => void | null | undefined;
  setOpen: (arg1: boolean) => void;
  picklistID: string;
  styles: Record<any, any>;
};

export const PicklistBuilderForm = ({
  options,
  styles,
  state,
  onSave,
  onRemove,
  setOpen,
  picklistID,
}: PicklistBuilderFormProps): React.ReactElement | null => {
  const formRef = useRef<HTMLFormElement | null | undefined>(null);
  const [text, setText] = useState<SlateContent>([createParagraph()]);
  const [error, setError] = useState('');
  const editor = useEditor();
  const { data } = useCurrentUser();
  const nvoqCredentials = data?.me?.nvoqCredentials;
  const isFocused = useSelected();
  const { setRecorderTarget } = useRecorder();
  const { showCompactHeight } = useCompactUI();

  useEffect(() => {
    setRecorderTarget('newMacro');

    return () => {
      setRecorderTarget('reporter');
    };
  }, [setRecorderTarget]);

  const [fields, fieldAction] = useReducer(
    (
      fields,
      {
        action,
        value,
      }:
        | {
            value: ReportPicklistBeforeSubmissionOptions;
            action: 'toggle' | 'update' | 'reset';
          }
        | {
            value: ReportPicklistBeforeSubmissionOptions[];
            action: 'sync';
          }
    ) => {
      if (fields == null || !Array.isArray(fields)) {
        return;
      }
      if (action === 'sync') {
        return options;
      }
      if (Array.isArray(value)) {
        return;
      }
      if (action === 'toggle') {
        if (fields.find((f) => f.id === value.id)) {
          return fields.filter((f) => f.id !== value.id);
        } else {
          return fields.concat(value);
        }
      }
      if (action === 'update') {
        return fields.map((f) => {
          if (f.id === value.id) {
            return {
              ...f,
              ...value,
            };
          }
          return f;
        });
      }
      if (action === 'reset') {
        return [];
      }
    },
    options
  );

  useEffect(() => {
    if (options) {
      fieldAction({ action: 'sync', value: options });
    }
  }, [options]);

  const getOption = useCallback(
    ({
      validate = false,
    }: {
      validate?: boolean;
    } = {}) => {
      if (formRef.current == null || fields == null || !Array.isArray(fields)) {
        return null;
      }

      // @ts-expect-error [EN-7967] - TS2339 - Property 'entries' does not exist on type 'FormData'.
      const data = Object.fromEntries(new FormData(formRef.current).entries());
      const optionName = String(data.name).trim();
      const hasText =
        text?.[0]?.children?.some((c: any) => String(c.text)?.trim() != null) ?? false;
      const isDuplicateName = !!optionName && fields.find((f) => f.name === optionName);

      if (hasText && optionName && isDuplicateName !== true) {
        if (validate) {
          setError('');
        }

        return { id: nanoid(), name: optionName, text, default: false };
      } else {
        if (validate) {
          const errorText =
            isDuplicateName === true
              ? 'Please enter a unique name for your picklist in order to save.'
              : !hasText
                ? 'Please enter text for your picklist in order to save.'
                : !optionName
                  ? 'Please enter a name for your picklist in order to save.'
                  : '';

          setError(errorText);
        }
        return null;
      }
    },
    [text, fields]
  );

  const handleAddField = useCallback(
    (evt) => {
      evt && evt.preventDefault();
      evt && evt.stopPropagation();
      const option = getOption({ validate: true });

      if (option) {
        fieldAction({
          action: 'toggle',
          value: option,
        });
        formRef.current?.reset();
      }
    },
    [fieldAction, getOption]
  );

  const columns = useMemo(
    () => [
      { header: '#', accessorFn: (_, index) => index + 1, size: 2 },
      {
        header: 'Name',
        accessorFn: (x) => x.name ?? 'N/A',
        size: 4,
      },
      {
        header: 'Insert text',
        accessorKey: 'text',
        size: 15,
      },
      {
        header: () => null,
        accessorKey: 'id',
        cell: ({ row }) => (
          <IconButton
            data-testid="picklist-builder-option-button"
            size="small"
            onClick={() =>
              fieldAction({
                action: 'toggle',
                value: {
                  id: row.original.id,
                  text: row.original.text,
                  default: row.original.default,
                },
              })
            }
          >
            <RemoveCircleIcon
              css={`
                ${TextCss.body1}
                margin: 0.2rem;
                color: ${Colors.red6};
              `}
            />
          </IconButton>
        ),
        size: 3,
      },
    ],
    []
  );

  const handleClose = useCallback(
    ({ source }) => {
      // - Submit: If there are options and no fields: Bookmark
      // - Submit: If there are no options and no fields: Bookmark
      // - Submit: If there are no options and fields: Picklist
      // - Cancel: If there are options and no fields: Picklist
      // - Cancel: If there are no options and no fields: Bookmark
      // - Cancel: If there are no options and fields: Bookmark
      if (fields == null || !Array.isArray(fields)) {
        return;
      }

      const activeEntry = find(editor, (n) => n.type === PICKLIST_PLUGIN_ID);
      if (
        (source === 'save' && options.length > 0 && fields.length === 0) ||
        (source === 'save' && options.length === 0 && fields.length === 0) ||
        (source === 'cancel' && options.length === 0 && fields.length === 0) ||
        (source === 'cancel' && options.length === 0 && fields.length > 0)
      ) {
        if (activeEntry) {
          Transforms.setNodes(
            editor,
            { type: INLINE_BOOKMARK_PLUGIN_ID, picklistID: null },
            { at: activeEntry[1] }
          );

          onRemove && onRemove(activeEntry[0]);
        }
      }

      if (activeEntry && source === 'save' && fields.length > 0) {
        insertSelectedPicklistItem(editor, activeEntry, fields[0].text);
      }

      ReactEditor.focus(editor);

      // On cancel, return focus to where the user had the cursor
      // On submit, return to the right inside edge of the bracket
      // Submit is taken care of due to the transforms running on the editor
      // inserting the text
      if (source === 'cancel' && editor.selection != null) {
        Transforms.select(editor, editor.selection);
      }
      Transforms.move(editor, { distance: 1, unit: 'offset' });
      setOpen(false);
      setError('');
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'readonly JSON[]'.
      fieldAction({ action: 'reset', value: { id: '', text: '', default: false } });
    },
    [editor, options.length, fields, setOpen, onRemove]
  );

  const handleSave = useCallback(() => {
    if (fields == null || !Array.isArray(fields)) {
      return;
    }

    // automatically add any pending field, since the user may easily forget to press
    // the "add" button and directly click on the "Done" button instead
    const pendingOption = getOption();

    onSave && onSave({ picklistID, options: pendingOption ? [...fields, pendingOption] : fields });
    handleClose({ source: 'save' });
  }, [fields, getOption, handleClose, picklistID, onSave]);

  const handleCancel = useCallback(() => {
    handleClose({ source: 'cancel' });
  }, [handleClose]);

  const plugins = useMemo(() => {
    if (
      nvoqCredentials == null ||
      nvoqCredentials.username == null ||
      nvoqCredentials.password == null
    ) {
      return [];
    }

    return [
      DictationPlugin({
        nvoqID: nvoqCredentials?.username,
        nvoqAuthorization: nvoqCredentials?.password,
        target: 'newMacro',
      }),
      ParagraphPlugin(),
      BoldPlugin(),
      ItalicPlugin(),
      UnderlinePlugin(),
      InlineBookmarkPlugin(),
      PlaceholderPlugin({
        onPlaceholderMenuClick: ({ editor, pluginID, placeholderField, instance }) => {
          Transforms.insertNodes(editor, [
            {
              type: pluginID,
              placeholderID: placeholderField.key,
              children: [{ text: placeholderField.name }],
            },
          ]);

          if (!editor.selection) return;
          const nextSelection = Editor.after(editor, editor.selection, { unit: 'offset' });

          if (nextSelection != null) {
            Transforms.select(editor, nextSelection);
          }
          instance.hide();
        },
      }),
      ClearFormattingPlugin(),
      DictaphoneControlsPlugin(),
    ];
  }, [nvoqCredentials]);

  return (
    <div
      css={`
        display: grid;
        flex: 1;
        grid-template-rows: minmax(max-content, 0) minmax(max-content, 0) auto minmax(
            max-content,
            0
          );
        grid-row-gap: ${showCompactHeight ? 0.5 : 2}rem;
        padding: 1rem 0;
      `}
    >
      <Typography variant="h5">Insert Field</Typography>
      <form ref={formRef} onSubmit={handleAddField}>
        <div
          css={`
            margin-bottom: 2rem;
          `}
        >
          <Typography variant="body2" css="margin-left: 10px; margin-bottom: 1.5rem;">
            Name
          </Typography>

          <Input
            name="name"
            data-testid="picklist-input-name"
            required
            pattern=".*\S.*"
            title="Please enter a name for your field in order to save"
            autoComplete="off"
            css={`
              width: 100%;
            `}
          />
        </div>

        <div
          css={`
            display: grid;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            align-items: center;
            grid-template-columns: 2fr /* 1fr */ auto;
          `}
        >
          <Typography variant="body2" css="margin-left: 10px;">
            Insert text
          </Typography>
          <span />
          <div
            data-testid="picklist-rich-text-field"
            css={`
              padding: 6px 0 16px;
            `}
          >
            <FieldContentWrapper isFocused={isFocused}>
              <RichTextEditor
                variant="fragment"
                onChange={setText}
                value={text}
                plugins={plugins}
              />
            </FieldContentWrapper>
          </div>

          <IconButton type="submit" data-testid="addPicklistItem">
            <AddCircleIcon />
          </IconButton>
        </div>

        <div
          css={`
            margin-left: 0.6rem;
            color: ${Colors.red6};
          `}
        >
          {error}&nbsp;
        </div>
      </form>

      <form onSubmit={(evt) => evt.preventDefault()} css="display: contents;">
        <Table
          columns={columns}
          data={(fields ?? []).map((f) => {
            return {
              ...f,
              text: isMacro(f.text) ? fragmentToString(f.text) : f.text,
            };
          })}
          css={`
            background-color: ${Colors.gray2};
            margin: 0 0.5rem;
            border-radius: 0.4rem;
            padding: 1rem;
          `}
        />
      </form>

      <div css="display: grid; grid-template-columns: auto auto; justify-content: flex-end; grid-column-gap: 10px;">
        <Button variant="ghost" onClick={handleCancel} data-testid="cancel-picklist">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} data-testid="done">
          Done
        </Button>
      </div>
    </div>
  );
};
