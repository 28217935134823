import { useCallback, useState } from 'react';
import { useTrackIdleTimeGlobally } from '@sironamedical/common/hooks/useTrackIdleTimeGlobally';
import { Countdown } from '@sironamedical/common/components/Countdown';
import GenericSnackBar from '../GenericSnackBar/GenericSnackBar';
import { useCurrentUser } from 'hooks/useCurrentUser/suspense';
import { useRecoilValue } from 'recoil';
import { recorderState } from '../Recorder/useRecorder/state';
import useAuth from 'hooks/useAuth';
import { css } from 'styled-components';
import { Colors } from 'styles';
import { logger } from 'modules/logger';

const ONE_MINUTE_IN_MS = 1000 * 60;

/**
 * InactivitySnackbar is a component that displays a snackbar message indicating the time remaining
 * until the user's session ends due to inactivity. It uses the Countdown component to show the
 * countdown timer.
 *
 * @param {string} message - The message to display in the snackbar.
 * @param {number} sessionEndTime - The timestamp when the session will end.
 * @param {boolean} paused - Optional flag for pausing the countdown.
 * @param {string} dataTestId - Optional data-testid for testing purposes.
 */
export const InactivitySnackbar = ({
  message = 'Your session will end in',
  sessionEndTime,
  paused,
  dataTestId = 'inactivity-tracking-snackbar',
}: {
  message?: string;
  sessionEndTime: number;
  paused?: boolean;
  dataTestId?: string;
}) => {
  return (
    <GenericSnackBar
      open={sessionEndTime !== null}
      severity="default"
      horizontal="left"
      actionName={null}
      msg={
        <p
          css={css`
            text-align: center;
            font-size: 2rem;
            .countdown {
              font-weight: bold;
            }
            .countdown--normal {
              color: ${Colors.yellow1};
            }
            .countdown--expired,
            .countdown--warning {
              color: ${Colors.red3};
            }
          `}
        >
          {message}{' '}
          <span>
            <Countdown endTimeInMs={sessionEndTime} paused={paused} format="human" />
          </span>
        </p>
      }
      data-testid={dataTestId}
    />
  );
};

export const InactivityTracking = ({ message }: { message?: string }): React.ReactElement => {
  const isRecording = useRecoilValue(recorderState);
  const [sessionEndTime, setSessionEndTime] = useState<number | null>(null);
  const { logout } = useAuth();
  const data = useCurrentUser();
  const configuredTimeoutInMinutes = data?.me?.sessionTimeoutInMinutes;
  const idleTimeoutInMs = configuredTimeoutInMinutes * ONE_MINUTE_IN_MS;
  const isPaused =
    configuredTimeoutInMinutes == null || isRecording || idleTimeoutInMs === 0 || !idleTimeoutInMs;
  const promptTimeoutInMs =
    idleTimeoutInMs > ONE_MINUTE_IN_MS ? ONE_MINUTE_IN_MS : ONE_MINUTE_IN_MS / 2;

  const onIdle = useCallback(() => {
    logger.info('[InactivityTracker] User has been detected to be idle, logging out');
    logout();
  }, [logout]);

  const onPrompt = useCallback(() => {
    setSessionEndTime(Date.now() + promptTimeoutInMs);
  }, [promptTimeoutInMs]);

  const onActive = useCallback(() => {
    setSessionEndTime(null);
  }, []);

  useTrackIdleTimeGlobally({
    idleTimeoutInMs: isPaused ? null : idleTimeoutInMs,
    promptTimeoutInMs: isPaused ? 0 : promptTimeoutInMs,
    onIdle,
    onPrompt,
    onActive,
    pause: isPaused,
  });

  return <InactivitySnackbar message={message} sessionEndTime={sessionEndTime} />;
};
