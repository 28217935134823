import { ToolCategory } from 'generated/graphql';
import type { Preference } from '.';

const keyboardShortcuts = {
  reporterPrevious: {
    id: 'REPORTER_PREVIOUS',
    description: 'Focus Previous',
    iconName: 'skip_previous',
    category: ToolCategory.Reporter,
  },
  reporterNext: {
    id: 'REPORTER_NEXT',
    description: 'Focus Next',
    iconName: 'skip_next',
    category: ToolCategory.Reporter,
  },
  reporterToggleDictation: {
    id: 'REPORTER_TOGGLE_DICTATION',
    description: 'Toggle Dictation',
    iconName: 'fiber_manual_record',
    category: ToolCategory.Reporter,
  },
  reporterSignReport: {
    id: 'REPORTER_SUBMIT_REPORT',
    description: 'Sign Report',
    iconName: 'submitReport',
    category: ToolCategory.Reporter,
  },
  reporterGenerateImpression: {
    id: 'REPORTER_GENERATE_IMPRESSION',
    description: 'Generate Impression',
    iconName: 'generateImpression',
    category: ToolCategory.Reporter,
  },
  reporterMedCheck: {
    id: 'REPORTER_MED_CHECK',
    description: 'Med Check',
    iconName: 'medCheck',
    category: ToolCategory.Reporter,
  },
  reporterDiscardReport: {
    id: 'REPORTER_DISCARD_REPORT',
    description: 'Discard Report',
    iconName: 'delete',
    category: ToolCategory.Reporter,
  },
  reporterDraftReport: {
    id: 'REPORTER_DRAFT_REPORT',
    description: 'Draft Report',
    iconName: 'save',
    category: ToolCategory.Reporter,
  },
} as const;

export const getKeyboardShortcutById = (
  id: string,
  interactionId?: string | null
): Preference | null | undefined => Object.values(keyboardShortcuts).find((ks) => ks.id === id);

export default keyboardShortcuts;
