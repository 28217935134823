import { PointRef } from 'domains/reporter/RichTextEditor/core';
// @ts-expect-error [EN-7967] - TS2305 - Module '"domains/reporter/RichTextEditor/core"' has no exported member 'DIRTY_PATHS'.
import { Text, Editor, Transforms, Point, Path, Range, DIRTY_PATHS, Node } from '../core';
import { DEFAULT_HEADING_KEYWORDS } from 'hooks/useHeadingKeywords';
import { isInsideListAtPath } from '../plugins/list/utils';

import {
  createEditorWarning,
  fragmentToString,
  getPointRefSafe,
  getRangeRefSafe,
  partialEditor,
} from '../utils';
import { stringifyRange } from '../utils/stringify';
import { pipe, prop, not, last, slice, cond, head, clone } from 'ramda';
import type { Fragment } from '../types';
import { PARAGRAPH_PLUGIN_ID } from '../plugins/paragraph';
import { normalizeTextNodeAtPath } from './normalizeTextNodeAtPath';
import type { NormalizeTextAffinity } from './normalizeTextNodeAtPath';
import { isInline, isBlock } from './fragmentHelpers';
import { HEADING_PLUGIN_ID } from '../plugins/heading';
import { LIST_PLUGIN_ID } from '../plugins/list';
import {
  getDefaultFieldName,
  hasDuplicateFieldName,
  isEligibleNamedField,
} from '../utils/fieldNaming';
import { logger } from 'modules/logger';
import { isInlineParagraph, isInlineNode } from '../plugins/paragraph/utils';
import {
  getHeadingKeywordIndex,
  isCursorAfterHeadingColon,
} from '../plugins/heading/utils/normalization';
import { BRACKET_TYPES } from '../constants';
import { createLineBreak, isSelectionInLineBreak } from '../plugins/lineBreak/utils';
import { INLINE_BOOKMARK_PLUGIN_ID } from '../plugins/inlineBookmark/types';
import { capitalizeFirstWord } from './normalizationHelpers';
import { getSurroundingTextString } from '../utils/getSurroundingTextString';
import { MARKS } from '../utils/marks';
import {
  getSelectedRequiredFields,
  insertContentButPreserveRequiredFields,
} from '../utils/requiredFields';
import { isEntireFieldSelected } from '../utils/isEntireFieldSelected';
import type { ToastKey } from 'common/ui/Toaster/Toast';
import type { Toast } from 'common/ui/Toaster/Toaster';
import { HeadingLevel } from '../plugins/heading/constants';
import type { HeadingKeywords } from 'generated/graphql';
import { Element } from 'slate';
import { CustomElement } from '../slate-custom-types';

export type NormalizeFragmentAffinity = 'left' | 'right' | 'both';

const shouldRemoveDirtyBlockNode = (editor: Editor) => (path: Path) => {
  if (!isEmptyBlockAtPath(editor)(path)) {
    return false;
  }

  const [blockEntry] = Array.from(
    Editor.nodes(editor, {
      at: path,
      match: (n) =>
        Element.isElement(n) && (n.type === PARAGRAPH_PLUGIN_ID || n.type === HEADING_PLUGIN_ID),
    })
  );

  if (blockEntry == null) {
    const errorMessage = `[fragmentStitching] Cannot see if block should be deleted for path ${JSON.stringify(
      path
    )} because no block node is found!`;
    editor.selection != null &&
      logger.error(errorMessage, {
        logId: stringifyRange(editor.selection),
        editor: partialEditor(editor),
        path,
      });
    throw new Error(errorMessage);
  }

  const [blockNode, blockPath] = blockEntry;
  const isBlockInline = isInlineNode(blockNode);
  const prevNodeEntry = Editor.previous(editor, { at: blockPath });
  if (prevNodeEntry == null) {
    return !isBlockInline;
  }

  return !isBlockInline && !isInlineNode(prevNodeEntry[0]);
};

export const isEmptyBlockAtPath =
  (editor: Editor): ((arg1: Path) => boolean) =>
  (path: Path): boolean => {
    const [blockEntry] = Array.from(
      Editor.nodes(editor, {
        at: path,
        match: (n) =>
          Element.isElement(n) && (n.type === PARAGRAPH_PLUGIN_ID || n.type === HEADING_PLUGIN_ID),
      })
    );

    if (blockEntry == null) {
      const errorMessage = `[fragmentStitching] Cannot see if block is empty for path ${JSON.stringify(
        path
      )} because no block node is found!`;
      editor.selection != null &&
        logger.error(errorMessage, {
          logId: stringifyRange(editor.selection),
          editor: partialEditor(editor),
          path,
        });
      throw new Error(errorMessage);
    }

    /*
    This function is a bit tricky. It is invoked when we are deciding whether
    or not to split a block node to make room to insert a new block node.
    We determine that the node is empty IF the node at the block path is empty
    OR the child inline text node at the insertion path is empty.
    We want to look at the specific inline text node at the insertion path because
    it is possible that there may be pre-existing sibling text nodes that have content.
  */
    const [blockNode] = blockEntry;

    if (Node.string(blockNode) === '') {
      return true;
    } else if (path.length > 2) {
      try {
        const maybeBookmarkNode = Editor.node(editor, path, {
          // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'match' does not exist in type 'EditorNodeOptions'.
          match: (n) => n.type === INLINE_BOOKMARK_PLUGIN_ID,
        });

        if (maybeBookmarkNode != null) {
          const leafEntry = Editor.leaf(editor, path);
          return leafEntry != null && Node.string(leafEntry[0]) === '';
        }
      } catch (e: any) {
        if (editor.selection != null) {
          logger.error('Error checking if inline node inside block is empty', {
            logId: stringifyRange(editor.selection),
            editor: partialEditor(editor),
            path,
          });
        }
      }
    }

    return false;
  };

const generateNodePartsForPath = (editor: Editor, path: Path) => {
  if (path.length < 1) {
    const errorMessage =
      '[fragmentStitching] Cannot get paragraph path if length of path is less than 1. The first path will be that of the paragraph block element where block node children are to be inserted.';
    const error = new Error(errorMessage);
    editor.selection != null &&
      logger.error(
        errorMessage,
        {
          logId: stringifyRange(editor.selection),
          editor: partialEditor(editor),
          path,
        },
        error
      );
    throw error;
  }

  const [paragraphLocation, ...remainingPath] = path;
  return {
    paragraphPath: [paragraphLocation],
    inlineNodesPath: slice(0, remainingPath.length - 1, remainingPath),
    textNodePath: last(remainingPath),
  };
};

/**
 * Gets the target insertion path for a block node because the point ref is forward looking, meaning it will
 * be one paragraph ahead of where we want to stitch block nodes. This gives the correct target path.
 */
const getTargetInsertionPathForBlockNode = (editor: Editor) => (path: Path) => {
  if (path.length < 1) {
    const errorMessage =
      'Cannot get target insertion path if length of path is less than 1. The first path is that of the paragraph block element where block node children are to be inserted.';
    editor.selection != null &&
      logger.error(errorMessage, {
        logId: stringifyRange(editor.selection),
        editor: partialEditor(editor),
        path,
      });
    throw new Error(errorMessage);
  }

  const [paragraphPath, ...rest] = path;
  return [isEmptyBlockAtPath(editor)(path) ? paragraphPath : paragraphPath - 1, ...rest];
};

const maybeNormalizeNode =
  ({
    editor,
    point,
    enableLeftNormalize,
    enableRightNormalize,
    normalizeLeftTextAffinity,
    normalizeRightTextAffinity,
  }: {
    editor: Editor;
    point: Point;
    enableLeftNormalize: boolean;
    enableRightNormalize: boolean;
    normalizeLeftTextAffinity: NormalizeTextAffinity;
    normalizeRightTextAffinity: NormalizeTextAffinity;
  }) =>
  (node: Element) => {
    // list nodes can retain their structure and do not need this step
    if (node.type === LIST_PLUGIN_ID) {
      return node;
    }

    if (enableLeftNormalize) {
      const textNodesForNode = Array.from(Node.texts(node));

      if (textNodesForNode.length === 0) {
        const errorMessage =
          '[fragmentStitching] Cannot normalizeAndInsertNodeAtPath if the given node is not a text node or the given node does not have any text nodes as children!';
        editor.selection != null &&
          logger.error(errorMessage, {
            logId: stringifyRange(editor.selection),
            editor: partialEditor(editor),
            point,
          });
        throw new Error(errorMessage);
      }

      const [firstTextNodeForNodeToInsertNode, firstTextNodeForNodeToInsertPath] =
        head(textNodesForNode);

      const normalizedNode = normalizeTextNodeAtPath(editor, firstTextNodeForNodeToInsertNode, {
        at: point.path,
        affinity: normalizeLeftTextAffinity,
      });

      return setNodeAtPath(editor, node, normalizedNode, firstTextNodeForNodeToInsertPath);
    } else if (enableRightNormalize) {
      const textNodesForNode = Array.from(Node.texts(node));

      if (textNodesForNode.length === 0) {
        const errorMessage =
          '[fragmentStitching] Cannot normalizeAndInsertNodeAtPath if the given node is not a text node or the given node does not have any text nodes as children!';
        editor.selection != null &&
          logger.error(errorMessage, {
            logId: stringifyRange(editor.selection),
            editor: partialEditor(editor),
            point,
          });
        throw new Error(errorMessage);
      }

      const [lastTextNodeForNodeToInsertNode, lastTextNodeForNodeToInsertPath] =
        last(textNodesForNode);

      const normalizedNode = normalizeTextNodeAtPath(editor, lastTextNodeForNodeToInsertNode, {
        at: point.path,
        affinity: normalizeRightTextAffinity,
      });

      return setNodeAtPath(editor, node, normalizedNode, lastTextNodeForNodeToInsertPath);
    } else {
      return node;
    }
  };

const stitchTextNode =
  ({
    editor,
    point,
    enableLeftNormalize,
    enableRightNormalize,
    normalizeLeftTextAffinity,
    normalizeRightTextAffinity,
    enableLogging = false,
    logId = '',
    fragmentString,
  }: {
    editor: Editor;
    point: PointRef;
    nodeIndex: number;
    enableLeftNormalize: boolean;
    enableRightNormalize: boolean;
    normalizeLeftTextAffinity: NormalizeTextAffinity;
    normalizeRightTextAffinity: NormalizeTextAffinity;
    enableLogging?: boolean;
    logId?: string;
    fragmentString: string;
  }) =>
  (node: Text) => {
    const selection = editor.selection;

    enableLogging &&
      selection != null &&
      logger.info(
        `[fragmentStitching] String: "${fragmentString}". Stitching (text) node into editor.`,
        {
          logId,
          editor: partialEditor(editor),
          point,
          node,
          fragmentString,
        }
      );
    const pointToInsert = getPointRefSafe(point);

    const isSelectionEqualToPoint =
      selection != null &&
      Range.isCollapsed(selection) &&
      Point.equals(selection.anchor, pointToInsert);

    const nodeToInsert = enableLeftNormalize
      ? normalizeTextNodeAtPath(editor, node, {
          at: pointToInsert.path,
          affinity: normalizeLeftTextAffinity,
        })
      : enableRightNormalize
        ? normalizeTextNodeAtPath(editor, node, {
            at: pointToInsert.path,
            affinity: normalizeRightTextAffinity,
          })
        : node;

    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type '"bold" | "source" | "italic" | "underline" | "highlighted"'.
    const marksToInsert = Object.keys(nodeToInsert).filter((key) => MARKS.includes(key));
    const marksToRemove = MARKS.filter((mark) => !marksToInsert.includes(mark));

    let oldSelection = null;
    if (selection != null && !isSelectionEqualToPoint) {
      oldSelection = Editor.rangeRef(editor, selection);
    }

    Transforms.select(editor, pointToInsert.path);

    marksToInsert.forEach((mark) => Editor.addMark(editor, mark, nodeToInsert[mark]));
    marksToRemove.forEach((mark) => {
      Transforms.unsetNodes(editor, mark, { at: pointToInsert.path });
    });

    Transforms.select(editor, pointToInsert);

    Editor.insertText(editor, nodeToInsert.text);

    if (editor.selection != null) {
      point.current = Editor.end(editor, editor.selection);
    }

    if (oldSelection != null) {
      Transforms.select(editor, getRangeRefSafe(oldSelection));
    }
  };

const setNodeAtPath = (editor: Editor, root: Node, nodeToSet: Node, path: Path): Node => {
  const newRoot = clone(root);
  let node = newRoot;

  for (let i = 0; i < path.length; i++) {
    const p = path[i];

    if (Text.isText(node) || !node.children[p]) {
      const errorMessage = `[fragmentStitching] Cannot find a descendant at path [${path.join(
        ','
      )}] in node: ${JSON.stringify(root)}`;
      editor.selection != null &&
        logger.error(errorMessage, {
          logId: stringifyRange(editor.selection),
          editor: partialEditor(editor),
          path,
        });
      throw new Error(errorMessage);
    }

    if (path.length - 1 === i) {
      node.children[p] = nodeToSet;
      break;
    }

    node = node.children[p];
  }

  // Return a cloned version of the root node with the new node set at the requested path.
  return newRoot;
};

const stitchInlineNode =
  ({
    editor,
    point,
    enableLeftNormalize,
    enableRightNormalize,
    normalizeLeftTextAffinity,
    normalizeRightTextAffinity,
    shouldNameField = false,
    enableLogging = false,
    logId = '',
    fragmentString,
  }: {
    editor: Editor;
    point: Point;
    nodeIndex: number;
    enableLeftNormalize: boolean;
    enableRightNormalize: boolean;
    normalizeLeftTextAffinity: NormalizeTextAffinity;
    normalizeRightTextAffinity: NormalizeTextAffinity;
    shouldNameField?: boolean;
    enableLogging?: boolean;
    logId?: string;
    fragmentString: string;
  }) =>
  (node: Element) => {
    enableLogging &&
      editor.selection != null &&
      logger.info(
        `[fragmentStitching] String: "${fragmentString}". Stitching inline ${String(
          node.type
        )} node into editor`,
        {
          logId,
          editor: partialEditor(editor),
          point,
          node,
          fragmentString,
        }
      );

    const nodeToInsert = maybeNormalizeNode({
      editor,
      point,
      enableLeftNormalize,
      enableRightNormalize,
      normalizeLeftTextAffinity,
      normalizeRightTextAffinity,
    })(node);

    editor.apply({
      type: 'insert_node',
      path: point.path,
      node: nodeToInsert,
    });

    if (
      shouldNameField === true &&
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type '"autoCorrect" | "list" | "placeholder" | "lineBreak" | "heading" | "inlineBookmark" | "paragraph" | "requiredFields" | "picklist" | "macroPlaceholder" | "sectionHeader" | "deepLink"'.
      isEligibleNamedField(String(node.type)) &&
      // @ts-expect-error [EN-7967] - TS2339 - Property 'name' does not exist on type 'CustomElement'. | TS2339 - Property 'name' does not exist on type 'CustomElement'. | TS2339 - Property 'name' does not exist on type 'CustomElement'.
      (node.name == null || node.name === '' || hasDuplicateFieldName(editor, String(node.name)))
    ) {
      Transforms.setNodes(
        editor,
        { name: getDefaultFieldName(editor, [node, point.path]) },
        { at: point.path }
      );
    }

    // If a previous node exists, insert a space at the end of the text of the previousNode,
    // and trim the start of the text of the nodeToInsert
    // During the stitching process, the `maybeNormalizeNode` function only normalizes the current node in question,
    // so we need to manually move it across to the previous node

    // e.g. Dictating 'I like bananas period insert apple macro':

    // Where 'apple macro' = [I also like apples.]

    // In the context of this `stitchInlineNode` function when we come to deal with '[I also like apples.]':
    // Node.string(previousNode) === 'I like bananas.'
    // Node.string(node) === 'I also like apples.'
    // maybeNormalizeNode correctly identifies that we need a space between the two nodes, but it only normalizes the current node
    // Node.string(nodeToInsert) === ' I also like apples.'

    // The following lines will move that whitespace from the beginning of 'I also like apples.' to the end of 'I like bananas. '

    // This step will mean we end up with:
    // I like bananas. [I also like apples.]
    // Rather than:
    // I like bananas.[ I also like apples.]
    if (Node.string(nodeToInsert)?.startsWith(' ')) {
      const previousPath = Path.previous(point.path);
      const previousNode = Node.get(editor, previousPath);

      if (previousNode != null) {
        Transforms.insertText(editor, ' ', {
          at: Editor.end(editor, previousPath),
        });
        const textChildren = Node.texts(nodeToInsert);
        const firstTextChild = textChildren.next()?.value;
        if (firstTextChild != null) {
          Transforms.insertText(editor, Node.string(firstTextChild[0]).trimStart(), {
            at: [...point.path, ...firstTextChild[1]],
          });
        }
      }
    }
  };

const stitchBlockNode =
  ({
    editor,
    point,
    nodeIndex,
    fragment,
    enableLeftNormalize,
    enableRightNormalize,
    normalizeLeftTextAffinity,
    normalizeRightTextAffinity,
    shouldNameField,
    enableLogging = false,
    logId = '',
    fragmentString,
  }: {
    editor: Editor;
    point: Point;
    nodeIndex: number;
    fragment: Fragment;
    enableLeftNormalize: boolean;
    enableRightNormalize: boolean;
    normalizeLeftTextAffinity: NormalizeTextAffinity;
    normalizeRightTextAffinity: NormalizeTextAffinity;
    shouldNameField: boolean;
    enableLogging?: boolean;
    logId?: string;
    fragmentString: string;
  }) =>
  (node: Element) => {
    enableLogging &&
      editor.selection != null &&
      logger.info(
        `[fragmentStitching] String: "${fragmentString}". Stitching block ${String(
          node.type
        )} node into editor`,
        {
          logId,
          editor: partialEditor(editor),
          point,
          node,
          fragmentString,
        }
      );

    let nodeToInsert = maybeNormalizeNode({
      editor,
      point,
      enableLeftNormalize,
      enableRightNormalize,
      normalizeLeftTextAffinity,
      normalizeRightTextAffinity,
    })(node);

    const pointRef = Editor.pointRef(editor, point);

    let isCurrentNodeBrackets = false;
    if (pointRef.current != null) {
      const path = pointRef.current?.path;
      const currentNode = Editor.node(editor, path?.slice(0, path.length - 1), {
        // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'match' does not exist in type 'EditorNodeOptions'.
        match: (n) => Node.isNode(n) && !Text.isText(n),
        mode: 'lowest',
      });
      isCurrentNodeBrackets =
        currentNode != null && BRACKET_TYPES.includes((currentNode[0] as CustomElement).type);
    }

    /**
     * TODO: 11/11/22 (kim.wijaya) => Change this comment to refer to unified editor flow
     * once it is rolled out
     *
     * When we receive a block node in a fragment we need to be careful with how we handle it because
     * we can't insert it at the current path of the insertPointRef. The reason is because the point ref
     * is forward looking and would result in the block node being inserted into another block node, most
     * likely not what we want!
     *
     * Let's walk through how this works. If the user's document looks like this:
     *     [
     *      {
     *        "type": "field",
     *        "isCompleted": false,
     *        "variant": "exam",
     *        "label": "Examination",
     *        "children": [
     *          {
     *            "type": "paragraph",
     *            "children": [
     *              {
     *                "text": "There is "
     *              },
     *              {
     *                "type": "macroPlaceholder",
     *                "referenceID": "macroText",
     *                "children": [
     *                  {
     *                    "text": "knee"
     *                  }
     *                ]
     *              },
     *              {
     *                "text": " pain."
     *              }
     *            ]
     *          }
     *        ]
     *      }
     *    ]
     *
     * Incoming fragment: [{ type: 'paragraph', children: [{ text: 'hip and ' }] }]
     *
     * The user wants to stitch at the following | in the first node: "There is |". That is a point of
     * { path: [0, 0, 0], offset: 9 }
     *
     * First thing we do is split at the point regardless of the fragment because we need to make a home for
     * the incoming node(s) regardless of their layout type (text, inline, block). This makes the document look like
     * this:
     *
     *    [
     *     {
     *       "type": "field",
     *       "isCompleted": false,
     *       "variant": "exam",
     *       "label": "Examination",
     *       "children": [
     *         {
     *           "type": "paragraph",
     *           "children": [
     *             {
     *               "text": "There is "
     *             }
     *           ]
     *         },
     *         {
     *           "type": "paragraph",
     *           "children": [
     *             {
     *               "text": ""
     *             },
     *             {
     *               "type": "macroPlaceholder",
     *               "referenceID": "macroText",
     *               "children": [
     *                 {
     *                   "text": "knee"
     *                 }
     *               ]
     *             },
     *             {
     *               "text": " pain."
     *             }
     *           ]
     *         }
     *       ]
     *     }
     *   ]
     *
     * Well, that's something! We've separated what would have been in one paragraph into two paragraphs
     * exactly where the user said they wanted the magic to happen. Our living ref which is the intent of where
     * the user wants the nodes to go has updated to { path: [ 0, 1, 0 ], offset: 0 } (the empty text node), whoop!
     *
     * The problem is our point is at a text node (inline), but we want to insert a block node. If we insert the
     * block node at the current ref then we'll put our document in an invalid state and force the normalizer to
     * make some assumptions for us. That's ok most of the time, but when we're stitching we need all of the control.
     *
     * So, we split again, to give us a hole to insert the block node giving us this
     *
     *    [
     *      {
     *        "type": "field",
     *        "isCompleted": false,
     *        "variant": "exam",
     *        "label": "Examination",
     *        "children": [
     *          {
     *            "type": "paragraph",
     *            "children": [
     *              {
     *                "text": "There is "
     *              }
     *            ]
     *          },
     *          {
     *            "type": "paragraph",
     *            "children": [
     *              {
     *                "text": ""
     *              }
     *            ]
     *          },
     *          {
     *            "type": "paragraph",
     *            "children": [
     *              {
     *                "text": ""
     *              },
     *              {
     *                "type": "macroPlaceholder",
     *                "referenceID": "macroText",
     *                "children": [
     *                  {
     *                    "text": "knee"
     *                  }
     *                ]
     *              },
     *              {
     *                "text": " pain."
     *              }
     *            ]
     *          }
     *        ]
     *      }
     *    ]
     *
     * Now we're cooking! We have a home for the block node and our ref is updated to be in the right spot too.
     * Now all we need to do is iterate through the children of the paragraph node and put them in their new home.
     *
     */

    if (
      !isInlineNode(nodeToInsert) &&
      (nodeIndex > 0 || // Always split at least once. If the first node is block, the split will be handled above
        pipe(getPointRefSafe, prop('path'), isEmptyBlockAtPath(editor), not)(pointRef))
    ) {
      Transforms.splitNodes(editor, {
        always: true,
        at: getPointRefSafe(pointRef),
        match: (n) =>
          (isCurrentNodeBrackets &&
            !isInsideListAtPath(editor, getPointRefSafe(pointRef).path) &&
            Text.isText(n)) ||
          Editor.isBlock(editor, n) ||
          (Element.isElement(n) && n.type === PARAGRAPH_PLUGIN_ID) ||
          (Element.isElement(n) && n.type === HEADING_PLUGIN_ID),
        mode: 'lowest',
      });

      /**
       * Sometimes we need to split twice here because the initial split for nodes was used on a non-block
       * node. For example, if a fragment looks like this: [[text: 'cookies'}, {type: 'paragraph', children: [{text: 'milk'}]}]]
       * the first split will be for the text node and won't break apart the ancestor paragraph element. The split immediately
       * above will, but then we're faced with the same problem as the massive comment above explains. This splits again
       * to get us to where the content of the block node will have a home.
       */
      if (
        pipe(
          getPointRefSafe,
          prop('path'),
          getTargetInsertionPathForBlockNode(editor),
          isEmptyBlockAtPath(editor),
          not
        )(pointRef)
      ) {
        Transforms.splitNodes(editor, {
          always: true,
          at: getPointRefSafe(pointRef),
          match: (n) =>
            Editor.isBlock(editor, n) ||
            (Element.isElement(n) && n.type === PARAGRAPH_PLUGIN_ID) ||
            (Element.isElement(n) && n.type === HEADING_PLUGIN_ID),
          mode: 'highest',
        });
      }
    }

    // Remember that the affinity of the ref is forward looking so that the insertPointRef will always
    // be a text node ahead of the empty paragraph block where the content needs to be stitched. To
    // accommodate that, we go backwards by one at the second level to make sure the block node's
    // children is put in the right spot.
    const targetInsertionPath = pipe(
      getPointRefSafe,
      prop('path'),
      getTargetInsertionPathForBlockNode(editor)
    )(pointRef);

    // Get the path of the parent, this will be a paragraph element
    const { paragraphPath, inlineNodesPath, textNodePath } = generateNodePartsForPath(
      editor,
      targetInsertionPath
    );

    // Get the starting index for the path stitching, will likely be 0
    let index = textNodePath != null && textNodePath !== -1 ? textNodePath : 0;

    if (
      nodeToInsert.type === HEADING_PLUGIN_ID &&
      isInsideListAtPath(editor, getPointRefSafe(pointRef).path)
    ) {
      nodeToInsert = { type: PARAGRAPH_PLUGIN_ID, children: [{ text: Node.string(nodeToInsert) }] };
    }

    if (nodeToInsert.type === HEADING_PLUGIN_ID || nodeToInsert.type === LIST_PLUGIN_ID) {
      editor.apply({ type: 'insert_node', path: paragraphPath, node: nodeToInsert });
    } else {
      let hasInsertedNewLine = false;
      if (
        isCurrentNodeBrackets &&
        // @ts-expect-error [EN-7967] - TS2339 - Property 'shouldForceInline' does not exist on type 'CustomElement'.
        node.shouldForceInline !== true &&
        !isInsideListAtPath(editor, getPointRefSafe(pointRef).path)
      ) {
        editor.apply({
          type: 'insert_node',
          path: paragraphPath.concat(inlineNodesPath).concat(index),
          node: createLineBreak(),
        });
        hasInsertedNewLine = true;
        index++;
      }

      /**
       * Go through all the children of the block node to stitch and manually apply them to the
       * editor. We use editor.apply because Transforms.insertNodes is too opinionated and breaks
       * our stitching.
       */
      for (const [childNodeIndex, childNode] of nodeToInsert.children.entries()) {
        const clonedChildNode = { ...childNode } as const;
        const path = paragraphPath.concat(inlineNodesPath).concat(index);
        index++;
        if (shouldNameField && isEligibleNamedField(clonedChildNode.type)) {
          const defaultFieldName = getDefaultFieldName(editor, [clonedChildNode, path]);
          const nodeAtPath = Node.get(editor, path);
          if (isEligibleNamedField((nodeAtPath as CustomElement).type)) {
            Transforms.setNodes(editor, { name: defaultFieldName }, { at: path });
          }
        }

        // If we just added a new line, that means the first child of this block node
        // should be capitalized and should not have any leading whitespace
        if (childNodeIndex === 0 && hasInsertedNewLine && Text.isText(clonedChildNode)) {
          clonedChildNode.text = capitalizeFirstWord(String(clonedChildNode.text)).trimStart();
        }

        editor.apply({ type: 'insert_node', path, node: clonedChildNode });
      }

      if (
        isInlineParagraph(nodeToInsert) &&
        !isInsideListAtPath(editor, getPointRefSafe(pointRef).path)
      ) {
        Transforms.setNodes(editor, { shouldForceInline: true }, { at: paragraphPath });
      } else if (!isInlineNode(nodeToInsert) && !isCurrentNodeBrackets) {
        Transforms.unsetNodes(editor, 'shouldForceInline', { at: paragraphPath, split: true });
      }

      /**
       * Sometimes we need one last split if next node is not block because the point
       * ref will be at the inline node, not the next block node. This is to keep the
       * stitching consistent with this test:
       *
       * stitchNodesIntoEditor - Should stitch nodes at a given path and split to accommodate incoming nodes
       */
      const nextNode = fragment[index + 1];
      if (nextNode != null && inlineNodesPath.length > 0 && isInline(editor)(nextNode)) {
        Transforms.splitNodes(editor, {
          always: true,
          at: getPointRefSafe(pointRef),
          match: (n) => Editor.isBlock(editor, n),
        });
      }
    }
  };

export const stitchNodesIntoEditor = (
  editor: Editor,
  fragment: Fragment,
  {
    at,
    select = false,
    cleanEmptyParagraphs = true,
    enableNormalize = true,
    normalizeAffinity = 'both',
    normalizeLeftTextAffinity: normalizeLeftTextAffinityParam,
    normalizeRightTextAffinity: normalizeRightTextAffinityParam,
    shouldNameField = true,
    enableLogging = false,
    enqueueToast,
    headingKeywords = DEFAULT_HEADING_KEYWORDS,
    isInsertingMacro = false,
  }: {
    at: Path | Point | Range;
    select?: boolean;
    cleanEmptyParagraphs?: boolean;
    enableNormalize?: boolean;
    normalizeAffinity?: NormalizeFragmentAffinity;
    normalizeLeftTextAffinity?: NormalizeTextAffinity;
    normalizeRightTextAffinity?: NormalizeTextAffinity;
    shouldNameField?: boolean;
    enableLogging?: boolean;
    enqueueToast?: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey;
    headingKeywords?: HeadingKeywords;
    isInsertingMacro?: boolean;
  }
) => {
  // We want to stringify the selection before it is mutated by the stitching
  let logId;

  const fragmentString = fragmentToString(fragment);

  if (editor.selection) logId = stringifyRange(editor.selection);

  enableLogging &&
    logId != null &&
    logger.info(
      `[fragmentStitching] String: "${fragmentString}". Started stitching ${fragment.length} nodes into editor.`,
      {
        logId,
        editor: partialEditor(editor),
        fragment,
        fragmentString,
        at,
      }
    );
  /**
   * We do not want to normalize the contents of the fragment based on feedback from radiologists. We only
   * want to normalize the edges of the fragment to stitch it in pretty.
   */
  const normalizeLeftTextAffinity =
    normalizeLeftTextAffinityParam != null
      ? normalizeLeftTextAffinityParam
      : fragment.length > 1
        ? 'left'
        : 'both';
  const normalizeRightTextAffinity =
    normalizeRightTextAffinityParam != null
      ? normalizeRightTextAffinityParam
      : fragment.length > 1
        ? 'right'
        : 'both';

  if (fragment.length === 0) {
    const stitchingWarning = `[fragmentStitching] Cannot stitch fragment to editor if it contains no nodes. Ignoring.`;
    createEditorWarning(stitchingWarning);
    logger.info(stitchingWarning, {
      logId,
      editor: partialEditor(editor),
      fragment,
      at,
    });
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    // Regardless of the location passed, we need to get to the point level because we need to split
    // at a location to stitch in the fragment without deleting other content
    let targetPoint: Point | null | undefined;

    // Convert path to be a point.
    if (Path.isPath(at)) {
      // Assume the end of the path because the beginning will make the current nodes in the path appear
      // after the point
      targetPoint = Editor.point(editor, at, { edge: 'end' });
    }

    // If it's a range, convert it into a point
    if (Range.isRange(at)) {
      // If it's collapsed, the take the anchor since the focus would be the same value
      if (Range.isCollapsed(at)) {
        targetPoint = at.anchor;
      } else {
        // If it's expanded, delete the content in the range and then set the target point to the end of the resulting location
        const [, end] = Range.edges(at);
        const pointRef = Editor.pointRef(editor, end);

        const requiredNodeEntries = getSelectedRequiredFields(editor);
        const entirelySelectedRequiredEntries = requiredNodeEntries.filter(
          ([node, path]: [any, any]) => isEntireFieldSelected(editor, path, at)
        );
        if (entirelySelectedRequiredEntries.length > 0) {
          insertContentButPreserveRequiredFields({
            editor,
            entirelySelectedRequiredEntries,
            // $FlowFixMe[incompatible-call] - Checked above
            selection: at,
            enqueueToast,
          });
          targetPoint =
            editor.selection != null ? Editor.end(editor, editor.selection) : pointRef.unref();
        } else {
          Transforms.delete(editor, { at });
          targetPoint = pointRef.unref();
        }
      }
    }

    if (Point.isPoint(at)) {
      targetPoint = at;
    }

    if (targetPoint == null) {
      const errorMessage =
        '[fragmentStitching] Unsupported location type for stitching nodes or location does not exist!';
      editor.selection != null &&
        logger.error(errorMessage, {
          logId: stringifyRange(editor.selection),
          editor: partialEditor(editor),
          fragment,
          at,
        });
      throw new Error(errorMessage);
    }

    fragment = fragment
      .map((node) => {
        if (
          node.type === HEADING_PLUGIN_ID &&
          targetPoint != null &&
          isInsideListAtPath(editor, targetPoint.path)
        ) {
          return [{ text: Node.string(node as Node).toLowerCase() }];
        }

        if (
          targetPoint == null ||
          isInsideListAtPath(editor, targetPoint.path) ||
          node.type !== PARAGRAPH_PLUGIN_ID ||
          node.children.length === 0 ||
          !Text.isText(node.children[0])
        ) {
          return [node];
        }

        const [headingKeyword, textArrayIndex] = getHeadingKeywordIndex(editor, headingKeywords, [
          node as Node,
          [0],
        ]);

        if (
          isInsertingMacro === false &&
          headingKeyword != null &&
          textArrayIndex != null &&
          textArrayIndex === 0 &&
          Node.string(node as Node)
            .toLowerCase()
            .includes(headingKeyword.toLowerCase() + ':')
        ) {
          return [
            {
              type: HEADING_PLUGIN_ID,
              level: HeadingLevel.H1,
              children: [{ text: headingKeyword + ':' }],
            },
            {
              type: PARAGRAPH_PLUGIN_ID,
              shouldForceInline: true,
              children: [{ text: node.children[0].text.substring(headingKeyword.length + 1) }],
            },
          ];
        }

        return [node];
      })
      .flat()
      .filter((n) => n != null);

    const [firstFragment] = fragment;

    // Create a living ref so that we always have a living point in the editor where the user's intent for
    // nodes to be stitched was at
    const insertPointRef = Editor.pointRef(editor, targetPoint, { affinity: 'forward' });

    const path = getPointRefSafe(insertPointRef).path;

    const [currentNode] = Editor.node(editor, path.slice(0, path.length - 1), {
      // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'match' does not exist in type 'EditorNodeOptions'.
      match: (n) => Node.isNode(n) && !Text.isText(n),
      mode: 'lowest',
    });
    const isCurrentNodeBrackets = BRACKET_TYPES.includes((currentNode as CustomElement).type);

    // We need to split the nodes at the targetLocation so we can surgically insert fragments (collections of nodes)
    // anywhere in the document. This matcher lets us define what level to split at in the path hierarchy.
    const matcherForSplit = (() => {
      if (
        Text.isText(firstFragment) ||
        (isCurrentNodeBrackets && !isInsideListAtPath(editor, path))
      ) {
        return Text.isText;
      } else if (editor.isInline(firstFragment)) {
        return (n) => Text.isText(n) || Editor.isInline(editor, n);
      } else {
        return (n) =>
          n.type === PARAGRAPH_PLUGIN_ID ||
          n.type === HEADING_PLUGIN_ID ||
          Editor.isBlock(editor, n);
      }
    })();

    // Split the nodes so we have a home to insert any number of nodes
    // NOTE: BLOCK NODES ARE WEIRD AND MAY SPLIT MORE TIMES
    Transforms.splitNodes(editor, {
      always: true,
      match: matcherForSplit,
      at: getPointRefSafe(insertPointRef),
      // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'split' does not exist in type '{ at?: Location; match?: NodeMatch<any>; mode?: RangeMode; always?: boolean; height?: number; voids?: boolean; }'.
      split: true,
    });

    const shouldLeftNormalize = (nodeIndex: number, isInline: boolean) => {
      return (
        enableNormalize &&
        ['left', 'both'].includes(normalizeAffinity) &&
        (isInline || nodeIndex === 0)
      );
    };

    const shouldRightNormalize = (nodeIndex: number) => {
      return (
        enableNormalize &&
        ['right', 'both'].includes(normalizeAffinity) &&
        nodeIndex === fragment.length - 1
      );
    };

    fragment.forEach((node, nodeIndex) => {
      if (
        Element.isElement(node) &&
        node.type === HEADING_PLUGIN_ID &&
        !isInsideListAtPath(editor, getPointRefSafe(insertPointRef).path) &&
        isCurrentNodeBrackets
      ) {
        Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] });
        if (insertPointRef.current != null) {
          const nextNode = Editor.next(editor, { at: getPointRefSafe(insertPointRef) });
          if (nextNode != null) {
            insertPointRef.current = Editor.start(editor, nextNode[1]);
          }
        }
      }

      /**
       * For every node to be inserted we need to make sure that the point ref is in an empty
       * text node because text node normalization relies on that being the case. If we let
       * normalizeTextNodeAtPath handle it then the insertPointRef is no longer correct.
       *
       * I chose to make this the responsibility of this function to avoid passing a mutable ref to
       * the other function coupling them and making it hard to test.
       */
      if (
        getPointRefSafe(insertPointRef).offset !== 0 ||
        Node.get(editor, getPointRefSafe(insertPointRef).path)?.text?.length !== 0
      ) {
        Transforms.splitNodes(editor, {
          always: true,
          match: Text.isText,
          at: getPointRefSafe(insertPointRef),
        });

        const pointBefore = Editor.before(editor, getPointRefSafe(insertPointRef));
        if (pointBefore == null) {
          const beforeWarningMessage = `[fragmentStitching] No point before found when stitching nodes, but the current pointRef is not empty. Allowing this to pass, but it could break normalization.`;
          createEditorWarning(beforeWarningMessage);
          logger.warn(beforeWarningMessage, {
            editor: editor != null ? partialEditor(editor) : 'null',
            fragment,
            node,
          });
        } else {
          insertPointRef.current = pointBefore;
        }

        if (
          getPointRefSafe(insertPointRef).offset !== 0 ||
          Node.get(editor, getPointRefSafe(insertPointRef).path)?.text?.length !== 0
        ) {
          Transforms.splitNodes(editor, {
            always: true,
            match: Text.isText,
            at: getPointRefSafe(insertPointRef),
          });
        }
      }

      cond([
        [
          Text.isText,
          stitchTextNode({
            editor,
            point: insertPointRef,
            nodeIndex,
            enableLeftNormalize: shouldLeftNormalize(nodeIndex, isInline(editor)(node)),
            enableRightNormalize: shouldRightNormalize(nodeIndex),
            normalizeLeftTextAffinity,
            normalizeRightTextAffinity,
            enableLogging,
            logId,
            fragmentString,
          }),
        ],
        [
          isInline(editor),
          stitchInlineNode({
            editor,
            point: getPointRefSafe(insertPointRef),
            nodeIndex,
            enableLeftNormalize: shouldLeftNormalize(nodeIndex, isInline(editor)(node)),
            enableRightNormalize: shouldRightNormalize(nodeIndex),
            normalizeLeftTextAffinity,
            normalizeRightTextAffinity,
            shouldNameField,
            enableLogging,
            logId,
            fragmentString,
          }),
        ],
        [
          isBlock(editor),
          stitchBlockNode({
            editor,
            point: getPointRefSafe(insertPointRef),
            nodeIndex,
            fragment,
            enableLeftNormalize: shouldLeftNormalize(nodeIndex, isInline(editor)(node)),
            enableRightNormalize: shouldRightNormalize(nodeIndex),
            normalizeLeftTextAffinity,
            normalizeRightTextAffinity,
            shouldNameField,
            enableLogging,
            logId,
            fragmentString,
          }),
        ],
      ])(node);
    });

    enableLogging &&
      logId &&
      logger.info(
        `[fragmentStitching] String: "${fragmentString}". Done stitching ${
          fragment.length
        } nodes into editor with selection at "${getSurroundingTextString(editor)}"`,
        {
          logId,
          editor: partialEditor(editor),
          fragment,
          fragmentString,
          at,
        }
      );

    /**
     * Transforms.splitNodes will inherit the parent nodes of a given point which is
     * what we want most of the time. However, for empty paragraphs we want them
     * to be clean. This looks at all of the dirty paths after the stitching
     * algorithm is ran, finds empty paragraphs, and removes any brackets that
     * may have been left over due to a split.
     */
    if (cleanEmptyParagraphs) {
      const dirtyPaths = DIRTY_PATHS.get(editor) ?? [];

      const dirtyBlockPaths = dirtyPaths.filter((path) => path.length === 1);
      const removedPaths: Array<Path> = [];

      dirtyBlockPaths.forEach((path) => {
        if (
          shouldRemoveDirtyBlockNode(editor)(path) &&
          (editor.selection == null ||
            !Range.includes(
              { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) },
              editor.selection
            ))
        ) {
          removedPaths.push(path);
          Transforms.removeNodes(editor, { at: [...path, 0] });

          // This would get normalized in but we add it anyways to make our
          // intent more clear
          Transforms.insertNodes(editor, { text: '' }, { at: [...path, 0] });
        }
      });

      logger.info(
        `[fragmentStitching] String: "${fragmentString}". ${removedPaths.length}/${dirtyBlockPaths.length} dirty block paths removed after stitching.`,
        {
          logId,
          editor: partialEditor(editor),
          fragment,
          fragmentString,
          dirtyPaths,
          dirtyBlockPaths,
          removedPaths,
        }
      );
    }

    const point = insertPointRef.unref();

    // Update selection of editor if flag passed in
    if (select && point != null) {
      if (
        !isCursorAfterHeadingColon(editor, { anchor: point, focus: point }) &&
        !isSelectionInLineBreak(editor, { anchor: point, focus: point })
      ) {
        Transforms.select(editor, point);
      }
    }
  });
};
