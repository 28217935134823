import { css } from 'styled-components';
import { forwardRef, Fragment, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import { Colors, TextCss, Spacings } from 'styles';
import { transparentize } from 'color2k';
import { Stack } from 'common/ui/Layout';

export type ButtonProps = PropsWithChildren<{
  id?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-alt'
    | 'light'
    | 'error'
    | 'ghost'
    | 'ghost-blue'
    | 'ghost-error'
    | 'yellow';
  to?: string | Location;
  external?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPlacement?: 'left' | 'right';
  css?: string;
  className?: string;
  loading?: boolean;
  onClick?: (arg1: React.MouseEvent<HTMLElement>) => void | Promise<void>;
  type?: 'submit' | 'button' | 'reset';
  onMouseEnter?: (arg1: React.SyntheticEvent<HTMLElement>) => void;
  onMouseLeave?: (arg1: React.SyntheticEvent<HTMLElement>) => void;
  onMouseDown?: (arg1: React.SyntheticEvent<HTMLElement>) => void;
  onFocus?: (arg1: React.SyntheticEvent<HTMLElement>) => void;
  onBlur?: (arg1: React.SyntheticEvent<HTMLElement>) => void;
  ['data-testid']?: string;
  autoFocus?: boolean;
}>;

const VARIANT_COLORS = new Map([
  ['primary', Colors.blue2],
  ['secondary', Colors.blue3],
  ['secondary-alt', Colors.teal3],
  ['light', Colors.gray10],
  ['ghost', 'transparent'],
  ['ghost-blue', 'transparent'],
  ['error', Colors.red2],
  ['ghost-error', 'transparent'],
  ['yellow', Colors.yellow3],
]);

type WrapperProps = Readonly<{
  external: boolean;
  href: string;
  to: string | Location;
  children: React.ReactNode;
  disabled: boolean;
}>;

const StyledLink = ({
  external,
  href,
  to,
  children,
  disabled,
}: WrapperProps): React.ReactElement =>
  external ? (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      css={`
        all: unset;
        text-decoration: none;
      `}
      onClick={(event) => {
        if (disabled) {
          event.preventDefault();
        }
      }}
    >
      {children}
    </a>
  ) : (
    <Link to={disabled ? '#' : to}>{children}</Link>
  );

export const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      id,
      variant = 'primary',
      external = false,
      disabled = false,
      loading = false,
      icon,
      iconPlacement = 'left',
      to,
      children,
      ...rest
    },
    ref
  ) => {
    const Wrapper = to != null ? StyledLink : Fragment;
    const backgroundColor = VARIANT_COLORS.get(variant) ?? Colors.blue2;

    const wrapperProps =
      to != null
        ? external && typeof to === 'string'
          ? {
              external: true,
              href: to,
              disabled,
            }
          : { to, external: false, disabled }
        : {};

    let padding =
      icon == null
        ? `${Spacings.xsmall}rem ${Spacings.xxlarge}rem`
        : `${Spacings.xsmall}rem ${Spacings.xlarge}rem`;

    if (['ghost', 'ghost-blue', 'error', 'ghost-error'].includes(variant)) {
      padding = `${Spacings.xsmall}rem ${Spacings.medium}rem`;
    }

    return (
      // `to` is not placed on `Fragment`
      // @ts-expect-error [prop-missing]
      <Wrapper {...wrapperProps}>
        <button
          {...rest}
          id={id}
          data-analytics-name={id}
          disabled={disabled || loading}
          // @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardedRef<HTMLElement>' is not assignable to type 'LegacyRef<HTMLButtonElement>'.
          ref={ref}
          css={css`
            all: unset;
            position: relative;
            background-color: ${backgroundColor};
            color: ${Colors.gray10};
            ${(variant === 'light' || variant === 'yellow') &&
            css`
              color: ${Colors.gray1};
            `}
            ${variant === 'ghost-blue' &&
            css`
              color: ${Colors.blue2};
            `}
            ${variant === 'ghost-error' &&
            css`
              color: ${Colors.red5};
            `}
            padding: ${padding};
            border-radius: 3rem;
            overflow: hidden;
            cursor: pointer;
            ${TextCss.button};

            ${variant === 'ghost' &&
            css`
              border: 1px solid ${Colors.gray10};
            `}
            ${variant === 'ghost-blue' &&
            css`
              border: 1px solid ${Colors.blue2};
            `}
            ${variant === 'light' &&
            css`
              border: 1px solid ${Colors.gray1};
            `}

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              background-color: ${backgroundColor};
              width: 100%;
              height: 100%;
              opacity: 0;
              transition:
                opacity 0.15s ease-in-out,
                background-color 0.15s ease-in-out;
            }

            &:hover:not([disabled])::before {
              background-color: ${transparentize(Colors.gray10, 0.85)};
              ${variant === 'light' &&
              css`
                background-color: ${transparentize(Colors.gray1, 0.95)};
              `}
              ${variant === 'ghost-error' &&
              css`
                background-color: ${transparentize(Colors.red5, 0.8)};
              `}
              opacity: 1;
            }

            &:active:not([disabled])::before {
              background-color: ${transparentize(Colors.gray10, 0.7)};
              opacity: 1;
            }

            &:focus-visible {
              box-shadow:
                0 0 0 1px ${Colors.blue5},
                0 0 0 0.5rem ${transparentize(Colors.blue5, 0.88)};
            }

            &[disabled] {
              background-color: ${Colors.gray6};
              cursor: not-allowed;

              ${variant.startsWith('ghost') &&
              css`
                background-color: transparent;
                border-color: ${Colors.gray7};
                color: ${Colors.gray6};
              `}
            }

            .MuiSvgIcon-root {
              font-size: 2.2rem;
            }
          `}
        >
          <Stack
            alignY="center"
            alignX="center"
            space="xsmall"
            css={`
              position: relative;
            `}
          >
            {iconPlacement === 'left' && icon}
            {children != null && children !== '' ? <span>{children}</span> : null}
            {iconPlacement === 'right' && icon}
          </Stack>
        </button>
      </Wrapper>
    );
  }
);
Button.displayName = 'Button';
