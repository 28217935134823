type BackOffFunction = (retries: number) => number;

type PromiseOrPromiseFunc = Promise<any> | (() => Promise<any> | Error) | (() => any);

type RetryOptions = {
  maxRetries: number;
  initialInterval: number;
  backoffFn?: BackOffFunction;
};

/**
 * Retry mechanism for asynchronous operations.
 */
export default class Retry {
  promOrPromFn: PromiseOrPromiseFunc;
  maxRetries: number;
  initialInterval: number;
  backoffFn: BackOffFunction | undefined;
  isRetrying: boolean;

  /**
   * Creates a new Retry instance.
   *
   * @param {Promise<void>} promOrPromFn - The asynchronous Promise or Promise function to retry.
   * @param {number} maxRetries - The maximum number of retries.
   * @param {number} initialInterval - The initial retry interval in milliseconds.
   * @param {BackOffFunction} backoffFn - The backoff function to use. If not provided, a default backoff strategy will be used.
   */
  constructor(
    promOrPromFn: PromiseOrPromiseFunc,
    { maxRetries, initialInterval, backoffFn }: RetryOptions
  ) {
    this.promOrPromFn = promOrPromFn;
    this.maxRetries = maxRetries;
    this.initialInterval = initialInterval;
    this.backoffFn = backoffFn;
    this.isRetrying = false;
  }

  static MAX_RETRIES_ERROR_MSG: string = 'Max retries exceeded';

  /**
   * Calculates the backoff value based on the number of retries and the provided backoff function.
   * Falls back to the default exponential backoff function if no custom function is provided.
   *
   * @param {number} retries - The number of retries.
   * @param {BackOffFunction} backoffFn - The backoff function to use.
   * @returns {number} - The calculated backoff value.
   */
  static getBackoff(retries: number, backoffFn?: BackOffFunction): number {
    if (backoffFn != null) {
      return backoffFn(retries);
    }
    return Math.pow(2, retries);
  }

  createNewPromise(delay: number): Promise<void> {
    return new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
      setTimeout(resolve, delay)
    );
  }

  /**
   * Retries the asynchronous function with backoff.
   *
   * @returns {Promise<void>} - A promise that resolves when the function is successfully executed or rejects when the maximum number of retries is exceeded.
   */
  async retry(): Promise<any> {
    if (this.isRetrying) {
      return;
    }

    this.isRetrying = true;
    let retries = 0;

    while (retries < this.maxRetries) {
      try {
        const result =
          this.promOrPromFn instanceof Promise
            ? await this.promOrPromFn
            : await this.promOrPromFn();

        this.isRetrying = false;
        return result;
      } catch (error: any) {
        retries++;
        const backoffDelay = this.initialInterval * Retry.getBackoff(retries, this.backoffFn);
        await this.createNewPromise(backoffDelay);
      }
    }
    throw new Error(Retry.MAX_RETRIES_ERROR_MSG);
  }
}
