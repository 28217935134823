import { useEffect, useState } from 'react';

import DropdownCombobox from 'common/ui/DropdownCombobox';
import { Stack } from 'common/ui/Layout';
import Text from 'common/ui/Text';
import styled, { css } from 'styled-components';
import { Colors } from 'styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardDoubleArrowDown from '@material-ui/icons/KeyboardArrowDownTwoTone';
import KeyboardDoubleArrowUp from '@material-ui/icons/KeyboardArrowUp';
import useStudyIds from 'hooks/useStudyIds';
import { useQuery } from '@apollo/client';
import { GET_REPORTS, GET_STUDIES_BY_SMID } from 'modules/Apollo/queries';
import type {
  GetStudiesBySmidQuery,
  GetStudiesBySmidQueryVariables,
  GetReportsQuery,
  GetReportsQueryVariables,
  AddendumFieldsFragment,
} from 'generated/graphql';
import { toUTCLocaleString } from 'utils/date';
import { Renderer } from '../../RichTextEditor';
import { Ellipsis } from 'common/ui/Ellipsis';
import Tooltip from 'common/ui/Tooltip';
import { useStudyColors } from 'hooks/useStudyColor';
import { useCaseSync } from 'hooks/useCaseSync';
import { createAddendumFieldName, formatAddendumContentByType } from '../Fields';
import IconButton from '@material-ui/core/IconButton';

/**
 * When using PriorReport component, it needs to have a z-index higher than the rest of the page to not be cutoff.
 *
 * Use this constant to comparatively set z-index for components that should sit above
 * this component.
 */
export const PRIOR_REPORT_ZINDEX = 10;

export const getDropdownValue = ({
  description,
  date,
}: {
  description: string | null | undefined;
  date: Date | null | undefined;
}): string => [description, toUTCLocaleString(date)].filter(Boolean).join(', ');

const StudyDot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${(props: { color: string }) => props.color};
  border-radius: 50%;
  flex-shrink: 0;
`;

export const renderAddendums = (
  addendums: ReadonlyArray<AddendumFieldsFragment>
): React.ReactElement => {
  // @ts-expect-error [EN-7967] - TS2739 - Type 'Element[]' is missing the following properties from type 'ReactElement<any, string | JSXElementConstructor<any>>': type, props, key
  return addendums
    .filter((addendum) => !!addendum.sentAt)
    .map((addendum, idx) => {
      return (
        <div key={addendum.smid}>
          <strong>
            {createAddendumFieldName({
              lastName: addendum.owner?.lastName ?? '',
              createdDate: addendum.submittedAt,
              addendumNumber: idx + 1,
            })}
          </strong>
          {formatAddendumContentByType(addendum.content).map((content, contentIdx) => {
            return (
              <Renderer
                key={`${addendum.smid}-${contentIdx}`}
                content={[content]}
                isReadOnly={true}
              />
            );
          }) ?? []}
        </div>
      );
    });
};

function PriorReport(): React.ReactElement | null {
  useCaseSync();
  const studyIds = useStudyIds();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const noPriorStudy = studyIds.length === 0;

  // technical warning- this query may not actually fire (which isn't a bad thing)
  // as the requested data might be fully encapsulated by getBaseViewerData,
  // which is called to fetch studies and used by `useStudyIds`
  // this also means the "No Prior Report" message shouldn't ever really show up
  const { data: studyData, loading } = useQuery<
    GetStudiesBySmidQuery,
    GetStudiesBySmidQueryVariables
  >(GET_STUDIES_BY_SMID, {
    skip: noPriorStudy,
    variables: {
      smids: studyIds,
    },
  });

  const studies = studyData?.studiesBySmid ?? [];
  const selectedStudy = studies[selectedIndex];

  const colors = useStudyColors();
  const dotColors = studies.map((study) => colors[study.smid]);
  const label = getDropdownValue({
    description: selectedStudy?.description,
    date: selectedStudy?.studyDate,
  });

  const dropdownValues = studies.map(({ description, studyDate }) =>
    getDropdownValue({
      description,
      date: studyDate,
    })
  );

  const accessionNumbers = studies.map((study) => study.accessionNumber).filter(Boolean);
  const { data: reportsData } = useQuery<GetReportsQuery, GetReportsQueryVariables>(GET_REPORTS, {
    skip: studyData?.studiesBySmid == null || accessionNumbers.length === 0,
    variables: {
      accessionNumbers,
    },
  });

  // Whenever the reportsData changes (user selects another study, or removes a study) set the index back to 0
  // otherwise, if you select the second item, remove it on the other screen, the UI will say no prior study
  useEffect(() => {
    setSelectedIndex(0);
  }, [reportsData]);

  const report = reportsData?.reports[selectedIndex];
  const isViewingReportContent = isExpanded && report != null;

  if (noPriorStudy) {
    return null;
  }

  return (
    <Stack
      vertical
      stretchX
      css={`
        box-sizing: border-box;
        width: 100%;
        z-index: ${PRIOR_REPORT_ZINDEX};
      `}
    >
      <Stack
        data-stack-stretch
        stretchY
        vertical
        css={`
          height: 40px;
          background: ${Colors.gray3};
          border-radius: ${isViewingReportContent ? '8px 8px 0px 0px' : '8px'};
          width: 100%;
          overflow-x: scroll;
        `}
      >
        <Stack
          data-stack-stretch
          alignY="center"
          alignX="between"
          space="small"
          css={`
            padding: 4px 16px 4px 16px;
            box-sizing: border-box;
          `}
        >
          <Stack data-stack-stretch space="small">
            <Text
              variant="display1"
              css={`
                white-space: nowrap;
              `}
            >
              {loading
                ? 'Loading prior report...'
                : selectedStudy != null
                  ? 'Prior Report'
                  : 'No prior report'}
            </Text>
            {selectedStudy != null && (
              <Stack
                data-stack-stretch
                css={`
                  white-space: nowrap;
                  box-sizing: border-box;
                  z-index: ${PRIOR_REPORT_ZINDEX};
                `}
              >
                <DropdownCombobox
                  css={css`
                    flex: 1;
                  `}
                  key="prior-report"
                  onFilter={() => () => true}
                  selectedItem={label}
                  onSelectedItemChange={(value) => {
                    setSelectedIndex(dropdownValues.indexOf(value.selectedItem));
                  }}
                  items={dropdownValues}
                  renderItem={(item, index) => (
                    <Tooltip
                      content={dropdownValues[index]}
                      trigger="mouseenter"
                      placement="bottom-start"
                      offset={[0, 2]}
                      onShow={({ reference }) => {
                        const ellipsis = reference.querySelector<HTMLDivElement>('[data-ellipsis]');
                        if (ellipsis.offsetWidth === ellipsis.scrollWidth) {
                          return false;
                        }
                      }}
                    >
                      <Stack
                        stretchX
                        alignY="center"
                        space="small"
                        css={css`
                          z-index: 10;
                          cursor: pointer;
                          flex: 1;
                        `}
                      >
                        <StudyDot
                          css={css`
                            height: 16px;
                            width: 16px;
                          `}
                          color={dotColors[index]}
                        />
                        <Ellipsis data-ellipsis>{dropdownValues[index]}</Ellipsis>
                      </Stack>
                    </Tooltip>
                  )}
                  renderInput={({ getToggleButtonProps, getInputProps }) => {
                    return (
                      <Tooltip
                        content={label}
                        // FIXME: should allow focus trigger as well, but creates
                        // poor UX for mouse users when the input gets focused again
                        trigger="mouseenter"
                        placement="bottom-start"
                        offset={[0, 2]}
                        onShow={({ reference }) => {
                          // Only show the tooltip if the text is overflowing
                          const ellipsis =
                            reference.querySelector<HTMLDivElement>('[data-ellipsis]');
                          if (ellipsis.offsetWidth === ellipsis.scrollWidth) {
                            return false;
                          }
                        }}
                      >
                        <div
                          {...getToggleButtonProps()}
                          ref={getInputProps().ref}
                          css={`
                            cursor: pointer;
                            border-radius: 6px;
                            background-color: ${Colors.gray5};
                            display: flex;
                            padding: 0 1rem;
                            height: auto;
                            text-align: left;
                            font-size: 14px;
                            justify-content: start;
                            padding-left: 15px;
                            z-index: 10;
                            flex: 1;
                          `}
                        >
                          <Stack
                            stretchX
                            alignX="between"
                            alignY="center"
                            space="small"
                            css={css`
                              display: flex;
                              flex: 1;
                            `}
                          >
                            <Stack alignY="center" space="small">
                              <StudyDot
                                css={css`
                                  height: 16px;
                                  width: 16px;
                                `}
                                color={dotColors[dropdownValues.indexOf(label)]}
                              />
                              <Ellipsis
                                css={`
                                  padding: 0;
                                `}
                                data-ellipsis
                              >
                                <Text
                                  variant="display1"
                                  css={`
                                    white-space: nowrap;
                                  `}
                                >
                                  {label}
                                </Text>
                              </Ellipsis>
                            </Stack>
                            <ArrowDropDownIcon
                              css={`
                                margin-right: -0.5rem;
                              `}
                            />
                          </Stack>
                        </div>
                      </Tooltip>
                    );
                  }}
                />
              </Stack>
            )}
          </Stack>
          <IconButton
            disableRipple
            size="small"
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            disabled={selectedStudy == null || loading}
            data-testid="expand-prior-report"
          >
            {isExpanded ? <KeyboardDoubleArrowUp /> : <KeyboardDoubleArrowDown />}
          </IconButton>
        </Stack>
      </Stack>
      {isViewingReportContent && (
        <Stack
          css={`
            background-color: ${Colors.gray4};
            color: ${Colors.gray8};
            padding: 8px 16px;
            height: 20vh;
            max-height: 45vh;
            min-height: 20vh;
            overflow: auto;
            border-radius: 0 0 0.4rem 0.4rem;
          `}
        >
          <Stack vertical space="medium">
            {Array.isArray(report?.content) && [
              <Renderer key="prior-report-renderer" content={report.content} isReadOnly={true} />,
              renderAddendums(report?.addendums ?? []),
            ]}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default PriorReport;
