import { Colors } from 'styles';

import { ReactComponent as MagicWandIcon } from 'assets/MagicWand.svg';
import Tooltip from 'common/ui/Tooltip';
import { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getHeadingNodeWithSynonym } from '../plugins/heading/utils/normalization';
import { useHeadingKeywords } from 'hooks/useHeadingKeywords';
import { HeadingLevel } from '../plugins';
import { useSlateSingletonContext } from '../../Reporter/SlateSingletonContext';
import { ReactEditor } from 'slate-react';
import { Editor } from 'slate';
import { logger } from 'modules/logger';
import { usePopper } from 'react-popper';
import { omit } from 'ramda';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { GenerateImpressionState } from 'hooks/useImpressionGenerator';
import { useImpressionGenerator } from 'hooks/useImpressionGenerator';

const ButtonWrapper = styled.div`
  margin-right: 0 !important;
`;

const getButtonColor = (state: GenerateImpressionState): string => {
  switch (state) {
    case 'initial':
    case 'loading':
      return Colors.blue4;
    case 'visited':
      return Colors.gray10;
    case 'stale':
      return Colors.blue4;
    case 'error':
      return Colors.red4;
    default:
      return '';
  }
};

export const getTooltip = (state: GenerateImpressionState): string | null => {
  switch (state) {
    case 'visited':
    case 'initial':
      return 'Generate Impression';
    case 'loading':
      return null;
    case 'stale':
      return 'Generate Updated Impression';
    case 'error':
      return 'Impression generation unavailable due to connection error';
    default:
      return null;
  }
};

// only re render when button state changes to prevent intervals getting cleared out every render
const GenerateButtonWithState = memo(function GenerateButtonWithState({
  unifiedEditorRef,
  generateImpressionState,
  generateAndStitchImpression,
}: {
  unifiedEditorRef?: {
    current: HTMLElement | null | undefined;
  };
  generateImpressionState: GenerateImpressionState;
  generateAndStitchImpression: () => void;
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const headingKeywords = useHeadingKeywords();
  const [{ editor }] = useSlateSingletonContext();
  const [popperElement, setPopperElement] = useState(null);
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });
  const popperStyles = omit(['transform'], styles.popper || {});
  const popperOffsetY = state?.modifiersData?.popperOffsets?.y
    ? Math.round(state?.modifiersData?.popperOffsets?.y + 10)
    : null;
  const outOfView =
    (referenceElement?.getBoundingClientRect()?.bottom ?? 0) >
      (unifiedEditorRef?.current?.getBoundingClientRect()?.bottom ?? 0) - 32 ||
    (referenceElement?.getBoundingClientRect()?.top ?? 0) <
      (unifiedEditorRef?.current?.getBoundingClientRect()?.top ?? 0);
  const canGenerateImpression =
    generateImpressionState === 'initial' ||
    generateImpressionState === 'stale' ||
    generateImpressionState === 'visited';

  useLayoutEffect(() => {
    if (editor === null) {
      setReferenceElement(null);
      return;
    }
    const updateReferenceElement = () => {
      const impressionHeadingNode =
        getHeadingNodeWithSynonym(editor, headingKeywords.impressions, HeadingLevel.H1) ??
        getHeadingNodeWithSynonym(editor, headingKeywords.impressions, HeadingLevel.H2) ??
        Editor.last(editor, []);

      try {
        const domRange = ReactEditor.toDOMRange(
          editor,
          Editor.range(editor, impressionHeadingNode[1])
        );
        setReferenceElement(domRange);
      } catch (e: any) {
        setReferenceElement(null);
      }
    };
    updateReferenceElement();
    const intervalId = setInterval(updateReferenceElement, 16); // 16ms is ~60fps
    return () => {
      clearInterval(intervalId);
    };
  }, [editor, headingKeywords.impressions]);

  if (referenceElement == null) {
    return null;
  }

  return (
    <ButtonWrapper data-testid="impression-generator-tooltip">
      <Tooltip
        content={getTooltip(generateImpressionState)}
        backgroundColor={Colors.gray7}
        color={Colors.gray1}
      >
        <div
          onClick={() => canGenerateImpression && generateAndStitchImpression()}
          data-testid={`impression-generator-${generateImpressionState}`}
          ref={setPopperElement}
          {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
          style={{
            margin: 0,
            ...(popperOffsetY != null ? { transform: `translate(9px, ${popperOffsetY}px)` } : {}),
          }}
          css={css`
            ${css(popperStyles)};
            background-color: transparent;
            display: ${popperOffsetY != null && !outOfView ? 'flex' : 'none'};
            align-content: center;
            z-index: 0;
            height: 35px;
            :hover {
              cursor: ${canGenerateImpression ? 'pointer' : 'default'};
            }
          `}
        >
          {generateImpressionState === 'loading' ? (
            <CircularProgress data-testid="loading-icon" size={16} />
          ) : (
            <MagicWandIcon
              data-testid="wand-icon"
              css={css`
                @keyframes pulse {
                  0% {
                    fill: ${Colors.blue4};
                  }
                  50% {
                    fill: ${Colors.gray10};
                  }
                  100% {
                    fill: ${Colors.blue4};
                  }
                }
              `}
              style={{
                animation: generateImpressionState === 'stale' ? 'pulse 2s infinite' : 'none',
                fill: `${getButtonColor(generateImpressionState)}`,
                height: '16px',
                width: '16px',
              }}
            />
          )}
        </div>
      </Tooltip>
    </ButtonWrapper>
  );
});

const ImpressionGeneratorButton = ({
  unifiedEditorRef,
}: {
  unifiedEditorRef?: {
    current: HTMLElement | null | undefined;
  };
}): React.ReactElement | null => {
  const { generateImpressionState, generateAndStitchImpression, impressionGeneratorNotAllowed } =
    useImpressionGenerator();
  const [{ editor }] = useSlateSingletonContext();

  const handleGenerateImpression = useCallback(() => {
    if (editor == null) return;
    logger.info('[ImpressionGeneratorButton] Impression generation triggered by button click');
    generateAndStitchImpression(editor);
  }, [editor, generateAndStitchImpression]);

  useEffect(() => {
    logger.info(
      `[ImpressionGeneratorButton] Generate impression button state: ${generateImpressionState}`
    );
  }, [generateImpressionState]);

  if (impressionGeneratorNotAllowed) return null;

  return (
    <GenerateButtonWithState
      unifiedEditorRef={unifiedEditorRef}
      generateImpressionState={generateImpressionState}
      generateAndStitchImpression={handleGenerateImpression}
    />
  );
};

export default ImpressionGeneratorButton;
