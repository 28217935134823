/* eslint-disable no-redeclare */
import type { SeriesDataFragment } from 'generated/graphql';
import { useEffect, useRef } from 'react';
import { RecoilSync, syncEffect } from 'recoil-sync';
import { atomFamily, useRecoilValue, useRecoilState } from 'recoil';
import type { RecoilState, AtomEffect } from 'recoil';
import { custom, or, literal } from '@recoiljs/refine';
import {
  useViewportsConfigurationsSelector,
  viewportsConfigurationsEntries,
} from '../ViewportsConfigurations';
import type { Study, Series, Stack } from '../ViewportsConfigurations';
import type { ViewportTypeKeys } from 'config/constants';
import { useBaseViewerData } from './StudyLoader/useStudies';
import type { ViewportConfiguration } from '../ViewportsConfigurations/types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isStackSmidMultiLayer } from './stackUtils';
import { rehydrateSlimStackToLayeredStack } from '../ViewportsConfigurations/manipulators';
import { baseViewerDataLoadingState } from './viewerState';

type ItemType = 'study' | 'series' | 'stack' | 'viewType';
function makeItemKey(viewportId: string, type: ItemType) {
  return `${viewportId}:${type}`;
}
function parseItemKey(key: string): {
  viewportId: string;
  type: ItemType | string;
} {
  const [viewportId, type] = key.split(':');
  return { viewportId, type };
}

type ViewerRecoilContextProps = {
  children: React.ReactNode;
};

function findStudy(studies: ReadonlyArray<Study>, smid?: string) {
  return studies.find((study) => study.smid === smid);
}

function findSeries(seriesList?: ReadonlyArray<SeriesDataFragment>, smid?: string) {
  return seriesList?.find((series: any) => series.smid === smid);
}

function findStack(stackedFrames?: ReadonlyArray<Stack>, smid?: string) {
  return stackedFrames?.find((stack: Stack) => stack.smid === smid);
}

function getStoreValues(
  viewportId: string,
  viewportConfiguration: ViewportConfiguration | null | undefined,
  studies: ReadonlyArray<Study>
) {
  const study = findStudy(studies, viewportConfiguration?.study?.smid);
  const series = findSeries(study?.seriesList, viewportConfiguration?.series?.smid);
  const stack = isStackSmidMultiLayer(viewportConfiguration?.stack?.smid)
    ? rehydrateSlimStackToLayeredStack(viewportConfiguration?.stack, study)
    : findStack(study?.stackedFrames, viewportConfiguration?.stack?.smid);
  return [
    [makeItemKey(viewportId, 'viewType'), viewportConfiguration?.viewType],
    [makeItemKey(viewportId, 'study'), study],
    [makeItemKey(viewportId, 'series'), series],
    [makeItemKey(viewportId, 'stack'), stack],
  ];
}

export function ViewerRecoilContext({ children }: ViewerRecoilContextProps): React.ReactElement {
  const updateAllKnownItemsRef = useRef();
  const viewportsConfigurations = useRecoilValue(useViewportsConfigurationsSelector());
  const { studies, loading } = useBaseViewerData();

  const [baseViewerDataLoading, setBaseViewerDataLoading] = useRecoilState(
    baseViewerDataLoadingState
  );
  useEffect(() => {
    // we only care about telling recoil state that we are done loading
    if (baseViewerDataLoading === true && loading === false) {
      setBaseViewerDataLoading(loading);
    }
  }, [baseViewerDataLoading, loading, setBaseViewerDataLoading]);

  useEffect(() => {
    if (viewportsConfigurations == null) return;
    const updateAllKnownItems = updateAllKnownItemsRef.current;
    if (updateAllKnownItems == null) return;

    const diff = new Map(
      viewportsConfigurationsEntries(viewportsConfigurations).reduce<Array<any>>(
        (acc, [viewportId, viewportConfiguration]: [any, any]) => {
          if (viewportConfiguration == null) return acc;

          return [...acc, ...getStoreValues(viewportId, viewportConfiguration, studies)];
        },
        []
      )
    );

    // Updating synced atom families to null will cause unwrapped exception because it will be null
    // from the sync effect. Let it stay previous until we have new items
    if (loading && diff.size === 0) {
      return;
    }

    // @ts-expect-error [EN-7967] - TS2722 - Cannot invoke an object which is possibly 'undefined'.
    updateAllKnownItems(diff);
  }, [studies, viewportsConfigurations, loading]);

  if (viewportsConfigurations == null) {
    return (
      <LinearProgress
        data-testid="viewer-recoil-context-loader"
        css="position: absolute; inset: 0;"
      />
    );
  }

  return (
    <RecoilSync
      storeKey="viewer"
      read={(itemKey) => {
        const { viewportId } = parseItemKey(itemKey);
        const viewportConfiguration = viewportsConfigurations[viewportId];
        const values = getStoreValues(viewportId, viewportConfiguration, studies);

        return values.find(([key]: [any]) => key === itemKey)?.[1];
      }}
      listen={({ updateAllKnownItems }) => {
        // @ts-expect-error [EN-7967] - TS2322 - Type 'UpdateAllKnownItems' is not assignable to type 'undefined'.
        updateAllKnownItemsRef.current = updateAllKnownItems;
      }}
    >
      {children}
    </RecoilSync>
  );
}

const viewportContextSyncEffect = <I extends ItemType, R extends unknown>({
  viewportId,
  type,
}: {
  viewportId: string;
  type: I;
}): AtomEffect<R> => {
  return syncEffect({
    refine: or(
      custom((x) => x),
      literal(undefined)
    ),
    storeKey: 'viewer',
    itemKey: makeItemKey(viewportId, type),
  });
};

export const currentStudyAtomFamily: (viewportId: string) => RecoilState<Study | null | undefined> =
  atomFamily({
    key: 'viewer.currentStudyAtomFamily',
    default: null,
    effects: (viewportId) => [
      viewportContextSyncEffect<'study', Study | null | undefined>({ viewportId, type: 'study' }),
    ],
  });

export const currentSeriesAtomFamily: (
  viewportId: string
) => RecoilState<Series | null | undefined> = atomFamily({
  key: 'viewer.currentSeriesAtomFamily',
  default: null,
  effects: (viewportId) => [
    viewportContextSyncEffect<'series', Series | null | undefined>({ viewportId, type: 'series' }),
  ],
});

export const currentStackAtomFamily: (viewportId: string) => RecoilState<Stack | null | undefined> =
  atomFamily({
    key: 'viewer.currentStackAtomFamily',
    default: null,
    effects: (viewportId) => [
      viewportContextSyncEffect<'stack', Stack | null | undefined>({ viewportId, type: 'stack' }),
    ],
  });

// @ts-expect-error [EN-7967] - TS2322 - Type '(param: string) => RecoilState<ViewportTypeKeys>' is not assignable to type '<T extends ViewportTypeKeys>(viewportId: string) => RecoilState<T>'.
export const currentViewTypeAtomFamily: <T extends ViewportTypeKeys>(
  viewportId: string
) => RecoilState<T> = atomFamily({
  key: 'viewer.currentViewTypeAtomFamily',
  default: 'TWO_D_DRE',
  effects: (viewportId) => [
    viewportContextSyncEffect<'viewType', ViewportTypeKeys | null | undefined>({
      viewportId,
      type: 'viewType',
    }),
  ],
});
