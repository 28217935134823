/* eslint-disable import/extensions */

import downsampleRawPCMSpeechProcessorUrl from './DownsampleRawPCMSpeech.processor?worker&url';
import downsampleRawPCMSpeechProcessorUrlDev from './DownsampleRawPCMSpeech.processor?url';

export const getDownsampleRawPCMSpeechProcessorUrl = (): string => {
  return import.meta.env.PROD
    ? downsampleRawPCMSpeechProcessorUrl
    : downsampleRawPCMSpeechProcessorUrlDev;
};
