import { useEffect, useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import Mousetrap from 'mousetrap';

import { GamepadListener } from 'gamepad.js';
import { GamepadActionId } from 'generated/graphql';
import { useToolPreferences } from 'hooks/usePreferences';
import { env } from 'config/env';
import { useMicrophone } from 'domains/reporter/useMicrophone';

const USE_VIRTUAL_GAMEPAD = env.NODE_ENV === 'test' || env.E2E_TEST === 'true';

export const PHILIPS_SPEECHMIKE_KEYCODE_MAPPINGS = {
  '0': 'back',
  '5': 'record',
  '2': 'forward',
  '14': 'submitReport',
  '7': 'toggleFocusMode',
} as const;

// The following code is a way to let developers without a dictaphone
// test the dictaphone-related functionalities
class VirtualGamepad {
  constructor() {
    // By default, Mousetrap disables shortcuts while the user is typing in a text input
    // we need to override its default behavior to allow the Virtual Gamepad shortcuts to
    // work while the developer is typing inside a text editor.
    const { stopCallback } = Mousetrap.prototype;
    Mousetrap.prototype.stopCallback = function (e: any, element: any, combo: any) {
      if (['ctrl+1', 'ctrl+2', 'ctrl+3', 'ctrl+4', 'ctrl+5', 'space'].includes(combo)) {
        return false;
      }
      return stopCallback.call(e, e, element, combo);
    };
    Mousetrap.bind('ctrl+1', (evt) => this.press(0));
    Mousetrap.bind('ctrl+2', (evt) => this.press(5));
    Mousetrap.bind('ctrl+3', (evt) => this.press(2));
    Mousetrap.bind('ctrl+4', (evt) => this.press(14));
    Mousetrap.bind('ctrl+5', (evt) => this.press(7));
    Mousetrap.bind('space', (evt) => this.press(7));
  }

  press(keyCode: number) {
    window.__SIRONA_GAMEPAD_ADAPTER__.emit('gamepad:button', {
      pressed: true,
      button: keyCode,
    });
  }
}

const gamepadListener = new GamepadListener();
const gamepadState = atom({
  key: 'gamepadIndex',
  default: { gamepadListener, capture: false },
  dangerouslyAllowMutability: true,
});

if (USE_VIRTUAL_GAMEPAD) {
  window.__SIRONA_GAMEPAD_ADAPTER__ = gamepadListener;
  new VirtualGamepad();
}

export const useGamepad = ({
  callback,
  capture = false,
}: {
  callback: (arg1: { keyCode: string; pressed: boolean }) => void;
  capture?: boolean;
}) => {
  // Gamepad behavior should only be enabled if a dictaphone is the active input
  const { isDictaphone } = useMicrophone();

  const [{ gamepadListener }, setGamepad] = useRecoilState(gamepadState);

  useEffect(() => {
    if (!capture) {
      return;
    }

    setGamepad((state) => ({ ...state, capture }));

    return () => {
      setGamepad((state) => ({ ...state, capture: false }));
    };
  }, [capture, setGamepad]);

  useEffect(() => {
    if (gamepadListener == null || !isDictaphone) {
      return;
    }

    const cb = ({
      detail,
    }: {
      detail: {
        pressed: boolean;
        button: string;
      };
    }) => {
      callback({ keyCode: String(detail.button), pressed: detail.pressed });
    };

    gamepadListener.start();
    gamepadListener.on('gamepad:button', cb);

    return () => {
      gamepadListener.off('gamepad:button', cb);
      // If nothing else is listening to the gamepad we can stop the listener
      // in order to speed up the whole app
      if (gamepadListener?.events != null && Object.keys(gamepadListener.events).length === 0) {
        gamepadListener.stop();
      }
    };
  }, [gamepadListener, capture, callback, setGamepad, isDictaphone]);
};

export const useGamepadBindings = ({
  callback,
}: {
  callback: (actionID: GamepadActionId, pressed: boolean, keyCode: string) => void;
}) => {
  const [toolPreferences] = useToolPreferences();

  const [{ capture }] = useRecoilState(gamepadState);

  let keyMapping = toolPreferences?.dictaphone.keyMapping;

  // on local development builds, if the developer doesn't have some key mapping
  // set on their profile, fallback to the config needed to make the virtual gamepad work
  if (env.NODE_ENV === 'development' || env.NODE_ENV === 'test' || env.E2E_TEST === 'true') {
    if (keyMapping == null || keyMapping.length === 0) {
      const DEV_KEYMAPPING: ReadonlyArray<{
        id: GamepadActionId;
        key: string;
      }> = [
        { id: GamepadActionId.Back, key: '0' },
        { id: GamepadActionId.Record, key: '5' },
        { id: GamepadActionId.Forward, key: '2' },
        { id: GamepadActionId.SubmitReport, key: '14' },
        { id: GamepadActionId.ToggleFocusMode, key: '7' },
      ];
      keyMapping = DEV_KEYMAPPING;
    }
  }
  const gamepadCallback = useCallback(
    (evt: { keyCode: string; pressed: boolean }) => {
      if (capture) {
        return;
      }

      const action = keyMapping?.find((k: any) => k.key === evt.keyCode);
      if (action != null) {
        callback(action.id, evt.pressed, evt.keyCode);
      }
    },
    [callback, capture, keyMapping]
  );

  useGamepad({ callback: gamepadCallback });
};
