import { Node } from 'domains/reporter/RichTextEditor/core';
import { Editor, Transforms, Text, Range } from '../../core';
import type { BaseText } from '../../core';
import type { CreateEnhanceProvider } from '../../types';
import type { AnatomicNavigatorPluginPropertyOptions } from './types';
import { useEffect } from 'react';
import type { DeepLink } from '../deepLink/types';
import { useDebouncedCallback } from 'use-debounce';
import { createDeepLinkWrapper } from '../deepLink/utils';
import { DEEP_LINK_PLUGIN_ID } from '../deepLink/types';
import { atom, useRecoilValue } from 'recoil';
import type { RecoilState } from 'recoil';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import type { SlateProps } from 'slate-react';
import { Element } from 'slate';

export const AN_DEEP_LINKS_KEY = 'anDeepLinks';

type ProviderComponentProps = Readonly<{
  editor: Editor;
  children: React.ReactNode;
}>;

const TYPING_SMART_INSERT_DEBOUNCE = 300;

const makeRegexFor = (loc: string): string => {
  if (loc[0] === loc[3]) {
    return `(?<disc>[${loc[0]}])${loc[1]}-\\1?${loc[4]}`;
  }

  return loc;
};

export const deepLinks: RecoilState<DeepLink[]> = atom({
  key: AN_DEEP_LINKS_KEY,
  default: [],
  effects: [broadcastChannelSynchronizerEffect()],
});

/**
 * The provider component.
 *
 * Attaches an event handler when the editor is loaded, which listens for messages on the anatomic navigator broadcast channel.
 * @param {ProviderComponentProps} props - Provider component properties.
 */
const ProviderComponent = ({
  children,
  editor,
  ...rest
}: ProviderComponentProps): React.ReactElement => {
  const discriminatoryLabels = useRecoilValue(deepLinks);

  const debouncedHandleSmartLink = useDebouncedCallback(() => {
    if (discriminatoryLabels != null) {
      handleSmartLinks(
        editor,
        discriminatoryLabels.reduce<{
          [key: string]: DeepLink;
        }>((acc, deepLink) => {
          if (deepLink.variant === 'point') {
            acc[deepLink.context.label] = deepLink;
          }
          return acc;
        }, {})
      );
    }
  }, TYPING_SMART_INSERT_DEBOUNCE);

  useEffect(() => {
    handleSmartLinks(
      editor,
      discriminatoryLabels.reduce<{
        [key: string]: DeepLink;
      }>((acc, deepLink) => {
        if (deepLink.variant === 'point') {
          acc[deepLink.context.label] = deepLink;
        }
        return acc;
      }, {})
    );
  }, [discriminatoryLabels, editor]);

  useEffect(() => {
    const initialChange = editor.onChange;

    editor.onChange = (...change: []) => {
      // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
      debouncedHandleSmartLink();

      initialChange(...change);
    };
  }, [editor, debouncedHandleSmartLink]);

  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

/**
 * Creates the provider component.
 *
 * @param {DeepLinkPluginPropertyOptions} props - Plugin property options for section header
 */
export const enhanceProvider: CreateEnhanceProvider<AnatomicNavigatorPluginPropertyOptions> =
  ({ pluginID }) =>
  (Component: React.ComponentType<SlateProps>) =>
    function EnhanceProviderAnatomicNavigator(props: SlateProps) {
      return (
        <ProviderComponent editor={props.editor}>
          <Component {...props} />
        </ProviderComponent>
      );
    };

const handleSmartLinks = (
  editor: Editor,
  links: {
    [key: string]: DeepLink;
  }
) => {
  const locRegExp = Object.keys(links).map((loc): [string, RegExp] => [
    loc,
    new RegExp(`(?<!\\S)\\b${makeRegexFor(loc)}(?!-)\\b`, 'gi'),
  ]);

  const textElements = Editor.nodes(editor, {
    at: [],
    match: (n: Node) => Text.isText(n) && String(n.text).trim() !== '',
  });

  Editor.withoutNormalizing(editor, () => {
    for (const [node, path] of Array.from(textElements)) {
      const parent = Editor.parent(editor, path);

      if (
        parent != null &&
        Element.isElement(parent[0]) &&
        parent[0].type !== DEEP_LINK_PLUGIN_ID
      ) {
        locRegExp.forEach(([loc, regexp]: [any, any]) => {
          const allMatches = (node as BaseText).text.matchAll(regexp);

          for (const match of Array.from(allMatches)) {
            //Split the text at all matches first before we wrap in case there are dupes

            const at = {
              anchor: { path, offset: match.index },
              focus: { path, offset: match.index + match[0].length },
            } as const;
            const start = Editor.start(editor, at);
            const end = Editor.end(editor, at);

            Transforms.splitNodes(editor, {
              at: end,
              match: (n) => Text.isText(n),
            });

            Transforms.splitNodes(editor, {
              at: start,
              match: (n) => Text.isText(n),
            });
          }
        });
      }
    }

    const replacementTextElements = Editor.nodes(editor, {
      at: [],
      match: (n: Node) => Text.isText(n) && (n as Text).text.trim() !== '',
    });

    for (const [node, path] of Array.from(replacementTextElements)) {
      const parent = Editor.parent(editor, path);

      if (
        parent != null &&
        Element.isElement(parent[0]) &&
        parent[0].type !== DEEP_LINK_PLUGIN_ID
      ) {
        locRegExp.forEach(([loc, regexp]: [any, any]) => {
          const deepLink = links[loc];
          const allMatches = Text.isText(node) && node.text.matchAll(regexp);

          for (const match of Array.from(allMatches)) {
            //Split the text at all matches first before we wrap in case there are dupes
            Transforms.wrapNodes(editor, createDeepLinkWrapper(deepLink) as Element, {
              at: {
                anchor: { path, offset: match.index },
                focus: { path, offset: match.index + match[0].length },
              },
              split: true,
            });
          }
        });
      }
    }
  });

  const selection = editor.selection;
  if (selection != null && Range.isCollapsed(selection)) {
    const [node, location] = Editor.parent(editor, selection);
    // If selection is in a deep link - lets move it to one after (after we normalize the document)
    if (Element.isElement(node) && node.type === DEEP_LINK_PLUGIN_ID) {
      const after = Editor.after(editor, location);
      if (after != null) {
        Transforms.select(editor, after);
      }
    }
  }
};
