import { Element } from '../core';
import type { ElementPlugin } from '../plugins';
import { ElementComponent } from './Element';
import { TextComponent } from './Text';
import type { RendererRenderElement, RendererRenderText } from './types';

export type ChildrenProps = Readonly<{
  node: ElementPlugin;
  renderElement: RendererRenderElement;
  renderText: RendererRenderText;
}>;

export const Children = ({
  node,
  renderElement,
  renderText,
}: ChildrenProps): React.ReactElement => {
  const children = node.children.map((n, i) => {
    if (Element.isElement(n)) {
      return (
        <ElementComponent key={i} node={n} renderElement={renderElement} renderText={renderText} />
      );
    } else {
      return <TextComponent key={i} node={n} renderText={renderText} />;
    }
  });

  // @ts-expect-error [EN-7967] - TS2739 - Type 'Element[]' is missing the following properties from type 'ReactElement<any, string | JSXElementConstructor<any>>': type, props, key
  return children;
};
