import type { TriggerToolInteractionMutation, ToolInteractionPayload } from 'generated/graphql';
import { usePayload } from './preparePayload';
import { NAMESPACES, sendEvent, useEventsListener } from 'modules/EventsManager/eventsManager';
import { useConfigBasedTool } from './ConfigBasedTool';
import { useCallback } from 'react';
import { ToolPayloadCapability, ToolInteractionResponseType } from 'generated/graphql';
import type { HandleCreateAnnotation } from '../Toolbox';
import type { VtkAnnotation } from '../../Annotations/types';

const TOOL_INTERACTION_TYPE_TO_RESPONSE_TYPE_NAME_MAP = {
  [ToolInteractionResponseType.AddInteraction]: 'ToolInteractionAddInteractionResponse',
  [ToolInteractionResponseType.Annotation]: 'ToolInteractionAnnotationResponse',
  [ToolInteractionResponseType.InsertText]: 'ToolInteractionInsertTextResponse',
  [ToolInteractionResponseType.KeyboardShortcut]: 'ToolInteractionKeyboardShortcutResponse',
  [ToolInteractionResponseType.Message]: 'ToolInteractionMessageResponse',
  [ToolInteractionResponseType.StartAnnotation]: 'ToolInteractionStartAnnotationResponse',
  [ToolInteractionResponseType.UpdateToolActiveState]:
    'ToolInteractionUpdateToolActiveStateResponse',
  [ToolInteractionResponseType.UpdateToolbarIcon]: 'ToolInteractionUpdateToolbarButtonIconResponse',
  [ToolInteractionResponseType.UpdateViewportPresentationState]:
    'ToolInteractionUpdateViewportPresentationStateResponse',
  [ToolInteractionResponseType.UpdateViewportSlice]: 'ToolInteractionUpdateViewportSliceResponse',
  [ToolInteractionResponseType.Widget]: 'ToolInteractionWidgetResponse',
} as const;

export const useClientSDKHandler = ({
  createAnnotation,
  annotations,
}: {
  createAnnotation: HandleCreateAnnotation;
  annotations: Array<VtkAnnotation>;
}): void => {
  const { handleInteractionResponse } = useConfigBasedTool({
    createAnnotation,
    annotations,
    payloadCapabilities: [], // they will be overridden at `triggerToolInteraction` call
  });

  const handleDREViewportMessage = useCallback(
    ({ payload }) => {
      if (payload.type !== 'SDKTriggerToolInteractionResponse') {
        return;
      }

      handleInteractionResponse(payload.responses, 'SDK');
    },
    [handleInteractionResponse]
  );

  useEventsListener(NAMESPACES.DRE, handleDREViewportMessage);
};

export const ClientSDKAdaptor = ({
  createAnnotation,
  annotations,
}: {
  createAnnotation: HandleCreateAnnotation;
  annotations: Array<VtkAnnotation>;
}): React.ReactElement | null => {
  useClientSDKHandler({
    createAnnotation,
    annotations,
  });

  return null;
};

export function triggerToolInteractionResponse(
  responses: TriggerToolInteractionMutation['triggerToolInteraction']
) {
  sendEvent(NAMESPACES.DRE, {
    type: 'SDKTriggerToolInteractionResponse',

    /**
     * GraphQL doesn't support assigning a specific enum value to a property,
     * this means we end up with the `type` property being the whole enum for every case
     * even though we know it will be assigned to a specific enum value.
     * Here we are trying to reconcile this by assigning the `__typename` property
     * to its corresponding GraphQL type name. Flow correctly tries to warn us that
     * we may end up with a wrong value, but we know that this is not the case.
     *
     * See https://github.com/graphql/graphql-spec/issues/878
     */
    responses: responses.map((response) => ({
      ...response,
      __typename:
        // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type '{ readonly __typename?: "ToolInteractionAddInteractionResponse"; } | { readonly __typename?: "ToolInteractionAnnotationResponse"; readonly type: ToolInteractionResponseType; readonly annotation: { ...; }; } | ... 8 more ... | { ...; }'.
        response.type != null
          ? // @ts-expect-error [incompatible-call]
            TOOL_INTERACTION_TYPE_TO_RESPONSE_TYPE_NAME_MAP[response.type]
          : undefined,
    })),
  });
}

export function useSDKPayload(): ToolInteractionPayload {
  return usePayload(Object.values(ToolPayloadCapability));
}
