import { useSuspenseQuery } from '@apollo/client';
import type { SuspenseQueryHookOptions } from '@apollo/client';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { GET_ME } from 'modules/Apollo/queries';

/**
 * NOTE: uses Suspense, make sure to handle it accordingly
 */
export function useCurrentUser(
  options?: SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>
): GetMeQuery {
  const { data } = useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GET_ME, options);
  return data;
}
