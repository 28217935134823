import { currentStackAtomFamily } from 'domains/viewer/Viewer/viewerRecoilContext';
import { useImagingContext } from 'domains/viewer/ViewportDre/modules/imaging/ImagingContext';
import { useViewType } from 'domains/viewer/ViewportDre/modules/state';
import {
  DEFAULT_ZOOM_VALUE,
  useSetLayeredWindowLevel,
  useSetZoom,
} from 'domains/viewer/ViewportDre/modules/useSetCamera';
import { calculateCameraParameters } from 'domains/viewer/ViewportDre/utils/math';
import { viewportDisplayConfigurationSelector } from 'domains/viewer/ViewportsConfigurations/state';

import { useContext } from 'react';
import { Contexts } from 'react-vtk-js';
import { atomFamily, useRecoilCallback } from 'recoil';
import { useViewCamera } from './useViewCamera';
import type { RecoilState } from 'recoil';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import { useSetWindowLevel } from './useSetCamera';
import type { IView } from 'react-vtk-js';
import { useShouldAlignChestWallWithSideOfViewport } from 'hooks/useClassificationBasedMammoFeatures';

export const isCameraInitializedState: (viewportId: string) => RecoilState<boolean> = atomFamily({
  key: 'viewer.dre.isCameraInitialized',
  default: false,
});

export const isMipDataReadyState: (viewportId: string) => RecoilState<boolean> = atomFamily({
  key: 'viewer.dre.isMipDataReadyState',
  default: false,
});

export const calculateDefaultWindowLevel = (
  range?: [number, number] | null
): {
  window: number;
  level: number;
} => {
  const fallback = { window: 1, level: 0 } as const;
  if (range == null) return fallback;
  const [minLevelValue, maxLevelValue]: [number, number] = range;
  const middle = (minLevelValue + maxLevelValue) / 2;
  const window = maxLevelValue - minLevelValue;
  return {
    window,
    level: middle,
  };
};

function applyCameraParameters(camera: vtkCamera, position: vec3, focalPoint: vec3, viewUp: vec3) {
  camera.setPosition(...position);
  camera.setFocalPoint(...focalPoint);
  camera.setViewUp(...viewUp);
}

export function useInitCamera(viewportId: string): (
  arg1?:
    | {
        ignoreCachedParams?: boolean;
      }
    | null
    | undefined
) => void {
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);
  const viewType = useViewType();
  const setZoom = useSetZoom();
  const setWindowLevel = useSetWindowLevel();
  const setLayeredWindowLevel = useSetLayeredWindowLevel();
  const { imagingProvider } = useImagingContext();
  const camera = useViewCamera(view);
  const shouldAlignChestWall = useShouldAlignChestWallWithSideOfViewport();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        options:
          | {
              ignoreCachedParams?: boolean;
            }
          | null
          | undefined
      ) => {
        const ignoreCachedParams = options?.ignoreCachedParams ?? false;
        const stack = snapshot.getLoadable(currentStackAtomFamily(viewportId)).getValue();

        if (imagingProvider == null || view == null || stack == null) return;

        const viewportDisplayConfig = snapshot
          .getLoadable(
            viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId })
          )
          .getValue();

        const params2d = viewportDisplayConfig?.params2d;

        // we need our zoom operation to complete before we try to calculate
        // the camera parameters

        await setZoom(
          ignoreCachedParams
            ? DEFAULT_ZOOM_VALUE
            : (viewportDisplayConfig?.params2d?.zoom ?? DEFAULT_ZOOM_VALUE)
        );

        if (
          ignoreCachedParams === false &&
          params2d != null &&
          params2d.position != null &&
          params2d.focalPoint != null &&
          params2d.viewUp != null
        ) {
          applyCameraParameters(camera, params2d.position, params2d.focalPoint, params2d.viewUp);
        } else {
          const { position, focalPoint, viewUp } = calculateCameraParameters(
            imagingProvider,
            view,
            viewType,
            viewportId,
            shouldAlignChestWall
          );
          applyCameraParameters(camera, position, focalPoint, viewUp);
        }

        // if the cache and imaging provider have no value we can use the intensity of the pixels to
        // take a guess and give the rad a visible image
        const { window, level } = calculateDefaultWindowLevel(imagingProvider?.getRange());
        const colorLevel = ignoreCachedParams
          ? imagingProvider?.imageParams.colorLevel
          : (viewportDisplayConfig?.params2d?.level ?? imagingProvider?.imageParams.colorLevel);
        const colorWindow = ignoreCachedParams
          ? imagingProvider?.imageParams.colorWindow
          : (viewportDisplayConfig?.params2d?.window ?? imagingProvider?.imageParams.colorWindow);

        setWindowLevel({ window: colorWindow ?? window, level: colorLevel ?? level });

        // @ts-expect-error [EN-7967] - TS2339 - Property 'stackLayers' does not exist on type 'Stack'.
        const layeredStackSmid = stack.stackLayers?.[1].stackSmid;
        // If this viewport is layered, do the same as the above but for the layered image params
        if (imagingProvider.isLayeredStack() && layeredStackSmid != null) {
          const layeredImagingProvider =
            imagingProvider.getRefProviderByStackSmid(layeredStackSmid);
          // Assumes a single overlay layer @ idx 1. If we extend to n > 1 layers in the future,
          // we'll need to modify this function to loop over all the layers
          const { window: layeredWindow, level: layeredLevel } = calculateDefaultWindowLevel(
            layeredImagingProvider?.getRange()
          );
          const layeredColorLevel = ignoreCachedParams
            ? layeredImagingProvider?.imageParams.colorLevel
            : (viewportDisplayConfig?.layeredParams2d?.level ??
              layeredImagingProvider?.imageParams.colorLevel);
          const layeredColorWindow = ignoreCachedParams
            ? layeredImagingProvider?.imageParams.colorWindow
            : (viewportDisplayConfig?.layeredParams2d?.window ??
              layeredImagingProvider?.imageParams.colorWindow);

          setLayeredWindowLevel({
            window: layeredColorWindow ?? layeredWindow,
            level: layeredColorLevel ?? layeredLevel,
          });
        }

        view.getRenderer()?.get()?.resetCameraClippingRange();
        view.requestRender();
        set(isCameraInitializedState(viewportId), true);
      },
    [
      viewportId,
      view,
      viewType,
      camera,
      imagingProvider,
      setZoom,
      setWindowLevel,
      setLayeredWindowLevel,
      shouldAlignChestWall,
    ]
  );
}
