/* eslint-disable react-hooks/rules-of-hooks */
// Copied from https://github.com/leny/react-use-storage/blob/master/src/index.js
import { useEffect, useCallback } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useRecoilState, atomFamily, RecoilState } from 'recoil';
import { nanoid } from 'nanoid';

const bc = new BroadcastChannel('use-storage-hook');

const atoms = new Map<Storage, (string) => RecoilState<any>>();
const getAtom = (storage: Storage) => {
  if (atoms.has(storage)) {
    return atoms.get(storage);
  }
  atoms.set(
    storage,
    atomFamily({
      key: nanoid(),
      default: (key: string) => JSON.parse(storage.getItem(key)),
    })
  );
  return atoms.get(storage);
};

const useStorage = (storage: Storage) => (key: string, defaultValue: string) => {
  const raw = storage.getItem(key);

  const [_value, setValue] = useRecoilState(getAtom(storage)(key));
  const value = _value ?? defaultValue;

  const updater = useCallback(
    (updatedValue, remove = false) => {
      setValue((oldValue) => {
        let newValue = updatedValue;
        if (typeof updatedValue === 'function') {
          newValue = updatedValue(oldValue);
        }
        storage[remove ? 'removeItem' : 'setItem'](key, JSON.stringify(newValue));
        bc.postMessage({ detail: { key } });
        return newValue;
      });
    },
    [key, setValue]
  );

  useEffect(() => {
    defaultValue != null && !raw && updater(defaultValue);
  }, [defaultValue, raw, updater]);

  useEffect(() => {
    const listener = ({ detail }) => {
      if (detail.key === key) {
        const lraw = storage.getItem(key);
        lraw !== raw && setValue(JSON.parse(lraw));
      }
    };
    bc.addEventListener('message', listener);
    return () => bc.removeEventListener('message', listener);
  });

  const reset = useCallback(() => updater(null, true), [updater]);

  return [value, updater, reset];
};

export const useLocalStorage = useStorage(localStorage);
export const useSessionStorage = useStorage(sessionStorage);
