import type { VTKModel, VTKPublicAPI } from '@kitware/vtk.js';
import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget';
import macro from '@kitware/vtk.js/macros';

export const Corners = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
} as const;

function extentOrientationCubeWidget(publicAPI: VTKPublicAPI, model: VTKModel) {
  //copied from https://kitware.github.io/vtk-js/api/Interaction_Widgets_OrientationMarkerWidget.html
  publicAPI.computeViewport = () => {
    const parentRen = model.parentRenderer || model._interactor.getCurrentRenderer();

    const [xMin, yMin, xMax, yMax] = parentRen.getViewport();

    const view = model._interactor.getView();
    const canvasSize = view.getSize();
    const [viewXSize, viewYSize] = view.getViewportSize(parentRen);
    const minViewSize = Math.min(viewXSize, viewYSize);

    let pixelSize = model.viewportSize * minViewSize;
    // clamp pixel size
    pixelSize = Math.max(
      Math.min(model.minPixelSize, minViewSize),
      Math.min(model.maxPixelSize, pixelSize)
    );

    const xFrac = pixelSize / canvasSize[0];
    const yFrac = pixelSize / canvasSize[1];

    // [left bottom right top]
    switch (model.viewportCorner) {
      case Corners.TOP_LEFT:
        return [xMin, yMax - yFrac, xMin + xFrac, yMax];
      case Corners.TOP_RIGHT:
        //Moving cube to the left
        return [xMax - xFrac - 0.08, yMax - yFrac, xMax, yMax];
      case Corners.BOTTOM_LEFT:
        return [xMin, yMin, xMin + xFrac, yMin + yFrac];
      case Corners.BOTTOM_RIGHT:
        return [xMax - xFrac, yMin, xMax, yMin + yFrac];
      default:
        return null;
    }
  };
}

function extend(publicAPI: VTKPublicAPI, model: VTKModel, initialValues: unknown = {}) {
  Object.assign(model, initialValues);

  // Inheritance
  vtkOrientationMarkerWidget.extend(publicAPI, model, initialValues);

  // Object specific methods
  extentOrientationCubeWidget(publicAPI, model);
}

const newInstance = macro.newInstance(extend, 'vtkOrientationCubeWidget');

export const dreOrientationMarkerWidget: {
  newInstance: (values: unknown) => typeof vtkOrientationMarkerWidget;
  extend: (publicAPI?: any, model?: any, initialValues?: Record<any, any>) => void;
} = { newInstance, extend };
