import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import type { DreAllViewportTypes, ViewportTypeKeys } from 'config/constants';
import { DRE_ALL_2D_VIEWPORT_TYPES, DRE_ALL_VIEWPORT_TYPES } from 'config/constants';
import { createContext } from 'common/ui/util/createContext';
import type { CreateContextReturn } from 'common/ui/util/createContext';
import { WorkerLoadedImagingProvider } from '../ViewportDre/modules/imaging/WorkerLoadedImagingProvider';
import LinkManager from '../ViewportDre/modules/imaging/LinkManager';
import { ViewerRecoilContext } from './viewerRecoilContext';
import { useBaseViewerData } from './StudyLoader/useStudies';
import { viewportDisplayConfigurationSelector } from '../ViewportsConfigurations';
import {
  useGuideViewportConfigurationSelector,
  useViewportsConfigurationsSelector,
} from '../ViewportsConfigurations/state';
import { scrollLinkModeAtom, ScrollLinkMode } from 'domains/viewer/ViewportDre/modules/state';
import { RenderManager } from '../ViewportDre/modules/imaging/RenderManager';
import { SynchronizationBroadcaster } from '../ViewportDre/modules/imaging/SynchronizationBroadcaster';
import type {
  FullMultiLayerStack,
  FullSingleLayerStack,
  Series,
  Stack,
  Study,
  ViewportsConfigurations,
} from '../ViewportsConfigurations/types';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import {
  logViewportLoadStart,
  useViewportMetrics,
} from '../ViewportDre/modules/useMeasureViewerPerformance';
import { useViewerId } from 'hooks/useViewerId';
import { usePreviousDistinct } from 'react-use';
import { viewportIdToConfig } from './viewerUtils';
import type { BaseImagingProvider } from '../ViewportDre/modules/imaging/BaseImagingProvider';
import { openedViewersState } from '../TrackOpenedViewers';
import { useRenderedViewportIdsForWindow } from '../hooks/useRenderedViewportIds';
import { useOpenPixelDataSharedWorker } from './OpenPixelDataSharedWorker';
import type { PixelDataSharedWorker } from 'modules/viewer/workers/PixelDataSharedWorker';
import { ViewerMemoryManager } from './ViewerMemoryManager';
import { decodeOverlayStackSmidToStackLayers, isStackSmidMultiLayer } from './stackUtils';
import { MultiStackImagingProvider } from '../ViewportDre/modules/imaging/MultiStackImagingProvider';
import { useViewerDownloadingFlags } from '../hooks/useViewerDownloadingFlags';
import { rehydrateSlimStackToLayeredStack } from '../ViewportsConfigurations/manipulators';
import { SUPPORTED_TEXTURES } from 'utils/textureUtils';
import { LOADING_PRIORITIES } from 'domains/viewer/loadingPriorities';
import { useCurrentUser } from 'hooks/useCurrentUser';

type SliceChangeMessageDetails = {
  viewType: DreAllViewportTypes;
  viewportId: string;
  stackSmid: string;
  slice: number;
  frameSmid: string;
};

export type SliceChangeMessage = {
  action: 'sliceChange';
  details: SliceChangeMessageDetails;
};

export type StackSyncBroadcastChannelMessage = SliceChangeMessage;

export type TStackProviders = Readonly<Map<string, BaseImagingProvider<Stack>>>;
type TViewerContext = {
  stackProviders: TStackProviders;
  linkManager: LinkManager;
  synchronizationBroadcaster: SynchronizationBroadcaster;
  syncAllSnapshots: () => void;
  pixelDataSharedWorker: PixelDataSharedWorker | null | undefined;
};

const [ContextProvider, useViewerContext]: CreateContextReturn<TViewerContext> =
  createContext<TViewerContext>({
    strict: true,
    errorMessage:
      'useViewerContext: `context` is undefined. Seems you forgot to wrap component within the ViewerContextProvider',
    name: 'ViewerContext',
  });

const InnerProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { studies } = useBaseViewerData({ shouldEmitPrecacheStatus: true });
  const caseSmid = useCurrentCaseId();
  const scrollLinkMode = useRecoilValue(scrollLinkModeAtom(caseSmid));
  const linkManagerRef = useRef(new LinkManager());
  const synchronizationBroadcaster = useRef(new SynchronizationBroadcaster());
  const renderManagerRef = useRef(new RenderManager());
  const guideViewportConfigurationSelector = useGuideViewportConfigurationSelector();
  const guideViewportConfiguration = useRecoilValue(guideViewportConfigurationSelector);
  const viewerId = useViewerId();
  const { viewerTransferType, viewerTransferProtocol, loadingViewerDownloadingFlags } =
    useViewerDownloadingFlags();

  const transferType = viewerTransferProtocol === 'http' ? 'http' : viewerTransferType;

  const pixelDataSharedWorker = useOpenPixelDataSharedWorker();

  const viewportsConfigurationsSelector = useViewportsConfigurationsSelector();
  const createImagingProvider = useRecoilCallback(
    ({ snapshot }) =>
      (
        viewportsConfigurations: ViewportsConfigurations,
        study: Study,
        stack: Stack,
        series: Series | null | undefined,
        pixelDataSharedWorker: PixelDataSharedWorker
      ) => {
        let initialLoad = false;
        const supportedTextures = SUPPORTED_TEXTURES;

        const getProvider = () => {
          if (stack.__typename === 'LayeredStack') {
            const providerParams = {
              study,
              stack,
              singleSortedSeries: true,
              linkManager: linkManagerRef.current,
              synchronizationBroadcaster: synchronizationBroadcaster.current,
              renderManager: renderManagerRef.current,
              frameOfReferenceUID: series?.frameOfReferenceUID,
              series,
              imageRegistrations: [],
              supportedTextures,
            } as const;
            return new MultiStackImagingProvider<FullMultiLayerStack>(providerParams);
          } else if (stack.__typename === 'SingleLayerStack') {
            const providerParams = {
              study,
              stack,
              singleSortedSeries: true,
              linkManager: linkManagerRef.current,
              synchronizationBroadcaster: synchronizationBroadcaster.current,
              renderManager: renderManagerRef.current,
              transferType,
              frameOfReferenceUID: series?.frameOfReferenceUID,
              series,
              pixelDataSharedWorker,
              imageRegistrations: study.seriesList
                .filter((series) => stack.frames.some((frame) => frame.series.smid === series.smid))
                .flatMap((series) => series.imageRegistrations),
              supportedTextures,
            } as const;
            return new WorkerLoadedImagingProvider<FullSingleLayerStack>(providerParams);
          }
        };
        const provider = getProvider();

        if (provider == null) {
          throw new Error('No provider generated for stack');
        }
        if (viewportsConfigurations != null && provider.canRender('vtk') && provider.hasPixels()) {
          for (const [viewportId, viewportConfig] of Object.entries(viewportsConfigurations)) {
            if (viewportConfig == null || viewportConfig.stack == null) continue;

            if (
              viewportConfig.stack.smid === stack.smid &&
              viewportIdToConfig(viewportId).windowId === viewerId
            ) {
              const viewType = 'TWO_D_DRE';
              const viewportDisplayConfig = snapshot
                .getLoadable(
                  viewportDisplayConfigurationSelector({
                    stackSmid: stack.smid,
                    viewType,
                    viewportId,
                  })
                )
                .getValue();
              const initialSlice = viewportDisplayConfig?.params2d?.imageNumber ?? 0;

              provider._updateVtkSlice(viewType, viewportId, initialSlice, false);

              logViewportLoadStart(viewportId);
              initialLoad = true;
              provider.load({
                initialSlice,
                priority: LOADING_PRIORITIES.HANGED,
              });
            }
          }
        }

        provider.setWasPartOfInitialLoad(initialLoad);

        return provider;
      },
    [transferType, viewerId]
  );

  const windowId = useViewerId();
  const windows = useRecoilValue(openedViewersState);
  const viewportsToRender = useRenderedViewportIdsForWindow(windowId ?? '');
  useViewportMetrics(viewportsToRender);
  const { data } = useCurrentUser();
  const me = data?.me;
  const viewportsConfigurations = useRecoilValue(viewportsConfigurationsSelector);
  const previousViewportsConfigurations = usePreviousDistinct(viewportsConfigurations);
  const [stackProviders, setStackProviders] = useState<TStackProviders>(new Map());
  const areViewportsReady =
    (viewportsConfigurations != null
      ? Object.values(viewportsConfigurations).some((c) => c != null)
      : false) &&
    loadingViewerDownloadingFlags === false &&
    windows.includes(windowId);

  const generateProviders = useCallback(
    (
      viewportsConfigurations: ViewportsConfigurations,
      studiesWithStackedFrames: Study[],
      pixelDataSharedWorker: PixelDataSharedWorker,
      stackProviders: TStackProviders
    ): {
      providers: TStackProviders;
      hasUpdatedProviders: boolean;
    } => {
      const filteredProviders = new Map(
        [...stackProviders].filter(
          // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
          ([stackSmid]: [any]) =>
            (isStackSmidMultiLayer(stackSmid) &&
              decodeOverlayStackSmidToStackLayers(stackSmid).every((layer) => {
                return studiesWithStackedFrames.some((study) =>
                  study.stackedFrames.some((stack) => stack.smid === layer.stackSmid)
                );
              })) ||
            studiesWithStackedFrames.some((study) =>
              study.stackedFrames.some((stack) => stack.smid === stackSmid)
            )
        )
      );

      let hasUpdatedProviders = filteredProviders.size !== stackProviders.size;

      const providers = studiesWithStackedFrames.reduce(
        (map: Map<string, BaseImagingProvider<Stack>>, study: Study) => {
          const writableMap = new Map<string, BaseImagingProvider<Stack>>(map);
          study.stackedFrames.forEach((stack) => {
            // TODO: Figure out how to handle series level data once we have stacks
            //       containing multiple distinct series.
            const series = study.seriesList.find(
              // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type '{ readonly __typename?: "LayeredStack"; readonly smid: string; readonly type: string; readonly study: { readonly __typename?: "Study"; readonly smid: string; }; readonly stackLayers: readonly { readonly __typename?: "Layer"; readonly stackSmid: string; readonly index: number; }[]; } | { ...; }'. | TS2339 - Property 'frames' does not exist on type '{ readonly __typename?: "LayeredStack"; readonly smid: string; readonly type: string; readonly study: { readonly __typename?: "Study"; readonly smid: string; }; readonly stackLayers: readonly { readonly __typename?: "Layer"; readonly stackSmid: string; readonly index: number; }[]; } | { ...; }'.
              (s) => stack.frames != null && s.smid === stack.frames[0].series.smid
            );

            if (!map.has(stack.smid)) {
              const imagingProvider = createImagingProvider(
                viewportsConfigurations,
                study,
                stack,
                series,
                pixelDataSharedWorker
              );

              hasUpdatedProviders = true;
              linkManagerRef.current.purgeOldProviderLinks(writableMap);
              imagingProvider.calculateLinkedStacks(writableMap);
              writableMap.set(stack.smid, imagingProvider);
            }
          });

          return writableMap;
        },
        filteredProviders
      );

      Object.values(viewportsConfigurations).forEach((config) => {
        if (
          config?.stack != null &&
          config.stack.smid != null &&
          isStackSmidMultiLayer(config?.stack.smid)
        ) {
          const study = studiesWithStackedFrames.find((s) => s.smid === config?.study?.smid);

          const stack = rehydrateSlimStackToLayeredStack(config.stack, study);
          if (study != null && stack != null && !providers.has(stack.smid)) {
            const imagingProvider = createImagingProvider(
              viewportsConfigurations,
              study,
              stack,
              null,
              pixelDataSharedWorker
            );

            hasUpdatedProviders = true;
            imagingProvider.calculateLayerProviders(providers);
            imagingProvider.calculateLinkedStacks(providers);
            providers.set(stack.smid, imagingProvider);
          }
        }
      });

      const readOnlyProviders: TStackProviders = providers;

      return { providers: readOnlyProviders, hasUpdatedProviders };
    },
    [createImagingProvider]
  );

  useEffect(() => {
    stackProviders.forEach((provider) => {
      provider.updateAbsoluteScrollPreference(me?.viewerSettings?.absoluteScroll ?? true);
    });
  }, [me, stackProviders]);

  useEffect(() => {
    if (viewportsConfigurations == null || !areViewportsReady || pixelDataSharedWorker == null)
      return;

    setStackProviders((previousProviders) => {
      const { providers, hasUpdatedProviders } = generateProviders(
        viewportsConfigurations,
        [...studies],
        pixelDataSharedWorker,
        previousProviders
      );

      if (hasUpdatedProviders) {
        return providers;
      } else {
        return previousProviders;
      }
    });
  }, [
    viewportsConfigurations,
    generateProviders,
    studies,
    areViewportsReady,
    pixelDataSharedWorker,
  ]);

  const getDisplayConfig = useRecoilCallback(
    ({ snapshot }) =>
      ({
        stackSmid,
        viewType,
        viewportId,
      }: {
        stackSmid: string | null | undefined;
        viewType: ViewportTypeKeys | null | undefined;
        viewportId: string | null | undefined;
      }) => {
        return snapshot
          .getLoadable(
            viewportDisplayConfigurationSelector({
              stackSmid,
              viewType,
              viewportId,
            })
          )
          .getValue();
      },
    []
  );

  useEffect(() => {
    if (viewportsConfigurations == null) {
      return;
    }

    Object.entries(viewportsConfigurations)
      .filter(
        ([id, vc]: [any, any]) =>
          vc?.stack != null &&
          stackProviders.has(vc.stack.smid) &&
          DRE_ALL_VIEWPORT_TYPES.includes(vc.viewType)
      )
      .forEach(([viewportId, { stack, viewType }]: [any, any]) => {
        const stackSmid = stack?.smid ?? ''; // we know stack exists
        const provider = stackProviders.get(stackSmid);
        const slice =
          getDisplayConfig({ stackSmid, viewType, viewportId })?.params2d?.imageNumber ?? 0;
        /**
         * Inject initial slice values into the providers so that broadcasted scroll updates
         * include stacks present on the other window. This is necessary because
         * `_updateVtkSliceForAllViewports` only broadcast states that already exist, and
         * states for the other window's viewports were only being made in response to
         * those other viewports scrolling and broadcasting new values to this window.
         * Now, we will always have the initial values pushed to those internal states so that
         * `_updateVtkSliceForAllViewports` always includes viewports in the other window.
         * However, we only should update the provider if it doesn't have state already;
         * The source we are pulling from updates more slowly than the providers.
         */
        provider?._initializeVtkSlice(viewType, viewportId, slice, false);
      });
  }, [stackProviders, viewportsConfigurations, getDisplayConfig]);

  const syncAllSnapshots = useCallback(() => {
    for (const targetProvider of stackProviders.values()) {
      targetProvider.snapshotManualSyncPosition();
    }
  }, [stackProviders]);

  const clearAllSnapshots = useCallback(() => {
    for (const targetProvider of stackProviders.values()) {
      targetProvider.clearManualSyncPosition();
    }
  }, [stackProviders]);

  const contextValue = useMemo(
    () => ({
      stackProviders,
      linkManager: linkManagerRef.current,
      synchronizationBroadcaster: synchronizationBroadcaster.current,
      syncAllSnapshots,
      pixelDataSharedWorker,
    }),
    [stackProviders, syncAllSnapshots, pixelDataSharedWorker]
  );
  // Sync linked scrolling mode recoil state to the LinkManager
  useEffect(() => {
    const linkManager = linkManagerRef.current;

    switch (scrollLinkMode) {
      case ScrollLinkMode.Disabled:
        clearAllSnapshots();
        linkManager.disableLinkedScrolling();
        break;
      case ScrollLinkMode.Manual:
        linkManager.enableManualLinkedScrolling();
        syncAllSnapshots();
        break;
      case ScrollLinkMode.Automatic:
      default:
        // by clearing snapshots, linking functions can now tell
        // if we are in manual mode only when the snapshots exist
        clearAllSnapshots();
        linkManager.enableAutomaticLinkedScrolling();
    }
  }, [scrollLinkMode, syncAllSnapshots, clearAllSnapshots]);

  useEffect(() => {
    synchronizationBroadcaster.current.setMessageHandler(
      (data: { messages: Array<StackSyncBroadcastChannelMessage> }) => {
        data.messages.forEach((message) => {
          if (message.action === 'sliceChange') {
            const { viewType, viewportId, stackSmid, slice } = message.details;
            const imagingProvider = stackProviders.get(stackSmid);
            if (imagingProvider != null) {
              // Update the imaging provider for the stack that sent the message, if it exists.
              // This causes all other linked stack to be updated.
              imagingProvider.syncSliceFromBroadcast(viewType, viewportId, slice);
            }
          }
        });
      }
    );
  }, [stackProviders]);

  useEffect(() => {
    async function loadUnhangedStacksNext() {
      if (viewportsConfigurations == null) {
        return;
      }

      // ignore viewports in other viewport windows, so that they get called
      // to load now (typically from the cache rather than over the network
      // simulatenously with the original window's load)
      const hangedStacks = Object.entries(viewportsConfigurations)
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        .filter(([viewportId]: [any]) => viewportIdToConfig(viewportId).windowId === viewerId)
        .map(([_, config]: [any, any]) => config?.stack?.smid);

      // wait for hanged stacks to finish loading
      await Promise.all(
        Array.from(stackProviders.values())
          .filter((provider) => hangedStacks.includes(provider.stack.smid))
          .map((provider) => provider.waitForLoadFinished())
      );
      for (const provider of stackProviders.values()) {
        if (
          !hangedStacks.includes(provider.stack.smid) &&
          provider.status === 'init' &&
          provider.hasPixels()
        ) {
          provider.load({ priority: LOADING_PRIORITIES.UNHANGED });
        }
      }
    }
    loadUnhangedStacksNext();
  }, [stackProviders, viewportsConfigurations, viewerId]);

  // deprioritize stacks that stop being linked to the guide viewport
  useEffect(() => {
    if (
      viewerId !== '0' ||
      viewportsConfigurations == null ||
      guideViewportConfiguration == null ||
      guideViewportConfiguration.stack?.smid == null ||
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ViewportTypeKeys' is not assignable to parameter of type 'DreAll2DViewportTypes'.
      !DRE_ALL_2D_VIEWPORT_TYPES.includes(guideViewportConfiguration.viewType)
    ) {
      return;
    }

    const guideStackSmid = guideViewportConfiguration.stack.smid;
    const links = linkManagerRef.current.getLinksForView(
      guideStackSmid,
      // @ts-expect-error [incompatible-call] we check for DRE viewtypes above
      guideViewportConfiguration.viewType
    );

    stackProviders.forEach((provider) => {
      const firstHangedViewportSlice =
        provider.getFirstHangedViewportSlice(viewportsConfigurations);

      // exclude the guide provider, anything done loading,
      // anything not hanged, and anything that links to the guide
      if (
        provider.stack.smid === guideStackSmid ||
        provider.status !== 'loading' ||
        firstHangedViewportSlice == null ||
        links.some((l) => l.targetProvider.stack.smid === provider.stack.smid)
      ) {
        return;
      }

      provider.updateLoadPriority({
        priority: LOADING_PRIORITIES.HANGED,
        focus: firstHangedViewportSlice,
      });
    });
  }, [stackProviders, guideViewportConfiguration, viewerId, viewportsConfigurations]);

  // deprioritize stacks that stop being hanged
  useEffect(() => {
    if (
      viewerId !== '0' ||
      viewportsConfigurations == null ||
      previousViewportsConfigurations == null
    ) {
      return;
    }

    stackProviders.forEach((provider) => {
      // loading, not found in current config, but WAS in previous config
      if (
        provider.status === 'loading' &&
        Object.values(viewportsConfigurations).every(
          (vc) => vc?.stack?.smid !== provider.stack.smid
        ) &&
        Object.values(previousViewportsConfigurations).some(
          (vc) => vc?.stack?.smid === provider.stack.smid
        )
      ) {
        provider.updateLoadPriority({
          priority: LOADING_PRIORITIES.UNHANGED,
          focus: provider.getFirstHangedViewportSlice(previousViewportsConfigurations) ?? 0,
        });
      }
    });
  }, [viewerId, stackProviders, viewportsConfigurations, previousViewportsConfigurations]);

  return (
    <ContextProvider value={contextValue}>
      <Suspense>
        <ViewerMemoryManager
          stackProviders={stackProviders}
          viewportsConfigurations={viewportsConfigurations}
          areViewportsReady={areViewportsReady}
        />
      </Suspense>
      {children}
    </ContextProvider>
  );
};

export const ViewerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const caseId = useCurrentCaseId();
  return (
    <ViewerRecoilContext key={caseId}>
      <InnerProvider>{children}</InnerProvider>
    </ViewerRecoilContext>
  );
};

export { useViewerContext, ContextProvider };
