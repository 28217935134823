import { Transforms, Node, Range, Editor, Path, ReactEditor, Text } from '../../../../core';
import {
  endsWithPunctuation,
  findLastIndexOfPunctuation,
  removeLastCharacterOfString,
} from '../../../../stitching';
import {
  createEditorWarning,
  selectNextBracket,
  selectPreviousBracket,
  selectNextSection,
} from '../../../../utils';
import { LIST_VARIANTS } from '../../../list/constants';
import {
  insertNextListItem,
  exitAllLists,
  insertList,
  increaseIndent,
  decreaseIndent,
  indentNextListItem,
  switchListVariant,
} from '../../../list/functions';
import { insertParagraph } from '../../../paragraph/utils';
import {
  getCurrentActiveListVariant,
  isInsideListAtPath,
  findClosestBlockPathAtSelection,
  isListItemEmpty,
  isInsideFirstListItemOfListAtPath,
  getCurrentListItemPosition,
} from '../../../list/utils';
import type {
  ParsedDictationChunkSubstitutionEditor,
  ParsedDictationChunkSubstitutionEditorGoToField,
  ParsedDictationChunkSubstitutionEditorNextNumberDynamic,
  ParsedDictationChunkSubstitutionEditorStartNumbering,
  ParsedDictationChunkSubstitutionEditorPicklistOptionSelection,
} from '../createNvoqWebsocketFactory';
import { unreachableCaseLog } from 'types';
import { insertInlineBookmark, splitInlineBookmark } from '../../../inlineBookmark/utils';
import { selectNamedField } from '../../../../utils/bracketNavigation';
import { INPUT_TYPES } from 'hooks/useMostRecentInput';
import type { InsertSelectedPicklistItemByIndexItem } from '../../usePicklistDictationSelection';
import { punctuationRegex, whitespaceRegex } from '../../../../stitching/normalizationHelpers';
import type { ImpressionContent } from 'hooks/useImpressionGenerator';
import type { Key } from 'slate-react';
import { getRequiredFieldsInRange } from 'domains/reporter/RichTextEditor/utils/requiredFields';
import { logger } from 'modules/logger';

const getTrailingPunctuationOffset = (editor: Editor) => {
  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return 0;
  }

  const [node] = Editor.node(editor, selection);
  if (!Text.isText(node)) {
    return 0;
  }

  const offset = selection.anchor.offset;
  const text = node.text.slice(offset);
  const match = text.match(punctuationRegex);

  return match ? match[0].length : 0;
};

export const maybeDoVoiceCommand = (
  editor: Editor,
  dictationChunk:
    | ParsedDictationChunkSubstitutionEditor
    | ParsedDictationChunkSubstitutionEditorGoToField
    | ParsedDictationChunkSubstitutionEditorPicklistOptionSelection
    | ParsedDictationChunkSubstitutionEditorNextNumberDynamic
    | ParsedDictationChunkSubstitutionEditorStartNumbering,
  {
    ignoreMergeFieldsInNavigation,
    setMostRecentInput,
    insertSelectedPicklistItemByIndex,
    generateAndStitchImpression,
    onInsertTextRequiredField,
  }: {
    ignoreMergeFieldsInNavigation?: boolean;
    setMostRecentInput: (arg1: string) => void;
    insertSelectedPicklistItemByIndex: InsertSelectedPicklistItemByIndexItem;
    generateAndStitchImpression?: (editor: Editor) => Promise<ImpressionContent | null | undefined>;
    onInsertTextRequiredField: (key: Key) => void;
  }
): boolean => {
  const {
    payload: { action },
  } = dictationChunk;
  let emptyTouchedRequiredFields;

  switch (action) {
    case 'Backspace':
      editor?.deleteBackward('character');
      return true;
    case 'DeleteContent':
      if (editor.selection == null) return false;

      if (Range.isCollapsed(editor.selection)) {
        if (editor.selection == null) return false;
        const [parentNode, parentPath] = Editor.parent(editor, editor.selection);

        let deletableContent: string = '';
        let offset: number = 0;
        for (const [textNode, textNodePath] of Node.texts(parentNode)) {
          if (editor.selection == null) return false;

          // Deletable content is everything left of the user's selection.
          // Anchor or focus could be used here because the selection is collapsed meaning they are equal.
          if (Path.equals([...parentPath, ...textNodePath], editor.selection?.anchor.path)) {
            deletableContent += textNode.text.substring(0, editor.selection?.anchor.offset);
            break;
          } else {
            deletableContent += textNode.text;
          }
        }

        // If the user's selection is to the immediate right of a punctuation we want to remove
        // that sentence. This mutates deletableContent otherwise the findLastIndexOfPunctuation
        // will find that punctuation and delete too much.
        if (endsWithPunctuation(deletableContent)) {
          deletableContent = removeLastCharacterOfString(deletableContent);
          offset = 1;
        }

        const distanceToDeleteBackwards =
          deletableContent.length + offset - findLastIndexOfPunctuation(deletableContent);

        if (distanceToDeleteBackwards <= 0) {
          createEditorWarning(
            'Received a deletion distance less than or equal to 0. This will cause Slate to delete all text for a given node. Ignoring delete operation.'
          );
          return true;
        }

        Transforms.delete(editor, {
          reverse: true,
          unit: 'character',
          // Sometimes we mutate the deletableContent because we want the right edge of punctuation
          // to delete the sentence. Since we alter the deletable content we need to add an offset
          // equal to the characters we removed otherwise the deletion will be off by the number
          // of characters we mutated.
          distance: distanceToDeleteBackwards,
        });
      } else {
        Transforms.delete(editor, { reverse: true });

        // Expanded selection sometimes leaves a leading whitespace which is no longer necessary.
        // If it exists, it is removed.
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const before = Editor.before(editor, start);

          if (before) {
            const range = { anchor: before, focus: start } as const;
            const text = Editor.string(editor, range);

            if (whitespaceRegex.test(text)) {
              Editor.deleteBackward(editor, { unit: 'character' });
            }
          }
        }
      }

      // Now that we've handled the backwards deletion, we want to check if we need to delete
      // forwards to remove any no-longer-necessary punctuation.
      const punctuationOffset = getTrailingPunctuationOffset(editor);
      if (punctuationOffset > 0) {
        Transforms.delete(editor, { unit: 'character', distance: punctuationOffset });
      }

      return true;
    case 'AllCaps':
      // Handled elsewhere
      return false;
    case 'Undo':
      editor?.undo();
      emptyTouchedRequiredFields = getRequiredFieldsInRange(editor, editor?.selection);
      for (const [node] of emptyTouchedRequiredFields) {
        onInsertTextRequiredField(ReactEditor.findKey(editor, node));
      }

      return true;
    case 'Redo':
      editor?.redo();
      emptyTouchedRequiredFields = getRequiredFieldsInRange(editor, editor?.selection);
      for (const [node] of emptyTouchedRequiredFields) {
        onInsertTextRequiredField(ReactEditor.findKey(editor, node));
      }

      return true;
    case 'NextBracket':
      setMostRecentInput(INPUT_TYPES.VOICE_COMMAND);
      selectNextBracket(editor, {
        ignoreMergeFieldsInNavigation,
        isVoiceCommand: true,
      });
      return true;
    case 'PreviousBracket':
      setMostRecentInput(INPUT_TYPES.VOICE_COMMAND);
      selectPreviousBracket(editor, { ignoreMergeFieldsInNavigation, isVoiceCommand: true });
      return true;
    case 'NextSection':
      setMostRecentInput(INPUT_TYPES.VOICE_COMMAND);
      selectNextSection(editor);
      return true;
    case 'InsertMacro':
      // Handled elsewhere
      return false;
    case 'EndOfLine':
      Transforms.move(editor, { distance: 1, unit: 'line' });
      return true;
    case 'BulletThat':
      const variant = getCurrentActiveListVariant(editor);

      if (variant === LIST_VARIANTS.ol) {
        switchListVariant(editor);
      } else if (variant === LIST_VARIANTS.ul) {
        // do nothing
      } else if (variant === null) {
        insertList(editor, LIST_VARIANTS.ul);
      }
      return true;
    case 'NumberThat': {
      const variant = getCurrentActiveListVariant(editor);

      if (variant === LIST_VARIANTS.ul) {
        switchListVariant(editor);
      } else if (variant === LIST_VARIANTS.ol) {
        // do nothing
      } else if (variant === null) {
        insertList(editor, LIST_VARIANTS.ol);
      }
      return true;
    }
    case 'StartBullet':
      const path = findClosestBlockPathAtSelection(editor);

      if (isInsideFirstListItemOfListAtPath(editor, path)) {
        // We are in the first list node. Don't trigger the insertion.
        return true;
      }

      insertList(editor, LIST_VARIANTS.ul);
      return true;
    case 'StartNumbering': {
      const path = findClosestBlockPathAtSelection(editor);

      if (isInsideFirstListItemOfListAtPath(editor, path)) {
        // if originalTextStepEntryExists, we want to insert the number as text, so return false.
        // else mark as voice command done (return true)
        // @ts-expect-error [EN-7967] - TS2339 - Property 'originalTextStepEntry' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | Readonly<...> | Readonly<...> | Readonly<...>'.
        return dictationChunk.payload.originalTextStepEntry == null;
      }

      insertList(editor, LIST_VARIANTS.ol);
      return true;
    }
    case 'StartIndentedBullet':
      indentNextListItem(editor, LIST_VARIANTS.ul);
      return true;
    case 'StartIndentedNumbering':
      indentNextListItem(editor, LIST_VARIANTS.ol);
      return true;
    case 'RemoveBullet':
      exitAllLists(editor, getCurrentActiveListVariant(editor));
      return true;
    case 'RemoveNumbering': {
      const variant = getCurrentActiveListVariant(editor);

      if (variant === LIST_VARIANTS.ol) {
        exitAllLists(editor, LIST_VARIANTS.ol);
      }
      return true;
    }
    case 'NextBullet':
      insertNextListItem(editor, LIST_VARIANTS.ul);
      return true;
    case 'NextNumber':
      insertNextListItem(editor, LIST_VARIANTS.ol);
      return true;
    case 'NextNumberDynamic': {
      const variant = getCurrentActiveListVariant(editor);
      // @ts-expect-error [EN-7967] - TS2339 - Property 'listItemIndex' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | Readonly<...> | Readonly<...> | Readonly<...>'.
      const { listItemIndex } = dictationChunk.payload;
      const currentListItemIndex = getCurrentListItemPosition(editor, editor.selection);

      if (variant != null && currentListItemIndex + 1 === listItemIndex) {
        insertNextListItem(editor, variant);
        return true;
      }
      return false;
    }

    case 'StopBullet': {
      const path = findClosestBlockPathAtSelection(editor);

      if (isInsideListAtPath(editor, path)) {
        const node = Node.get(editor, path);
        if (!isListItemEmpty(node)) {
          splitInlineBookmark(editor);
        }
        exitAllLists(editor, getCurrentActiveListVariant(editor));
      }
      return true;
    }
    case 'IncreaseIndent':
      increaseIndent(editor, getCurrentActiveListVariant(editor));
      return true;
    case 'DecreaseIndent':
      decreaseIndent(editor, getCurrentActiveListVariant(editor));
      return true;
    case 'NextParagraphInList': {
      const variant = getCurrentActiveListVariant(editor);
      if (variant != null) {
        insertParagraph(editor);
        return true;
      } else return false;
    }
    case 'GoToField':
      // @ts-expect-error [EN-7967] - TS2339 - Property 'fieldName' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | Readonly<...> | Readonly<...> | Readonly<...>'.
      if (dictationChunk.payload.fieldName == null) {
        return false;
      }
      setMostRecentInput(INPUT_TYPES.VOICE_COMMAND);
      // @ts-expect-error [EN-7967] - TS2339 - Property 'fieldName' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | Readonly<...> | Readonly<...> | Readonly<...>'.
      selectNamedField(editor, dictationChunk.payload.fieldName);
      return true;
    case 'PicklistOptionSelection':
      // @ts-expect-error [EN-7967] - TS2339 - Property 'pickIndex' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | Readonly<...> | Readonly<...> | Readonly<...>'.
      const { pickIndex } = dictationChunk.payload;
      if (pickIndex == null) {
        return false;
      }
      insertSelectedPicklistItemByIndex(pickIndex);
      return true;
    case 'NewField':
      const { selection } = editor;
      if (selection == null) return false;

      insertInlineBookmark(editor, { location: selection });

      return true;
    case 'GenerateImpression':
      if (editor != null && generateAndStitchImpression != null) {
        logger.info(`[maybeDoVoiceCommand] Impression generation triggered by voice command`);

        generateAndStitchImpression(editor);
        return true;
      }

      return false;
    default:
      unreachableCaseLog(action);
      return false;
  }
};
