import { useMemo, useEffect, useRef, useCallback } from 'react';
import { GET_BASE_VIEWER_DATA } from 'modules/Apollo/queries';
import useWorklistId from 'hooks/useWorklistId';
import { useRegisterStudyColors } from 'hooks/useStudyColor';
import type { Study, StudyGroup } from 'domains/viewer/ViewportsConfigurations/types';
import type {
  GetBaseViewerDataQuery,
  GetBaseViewerDataQueryVariables,
  SeriesClassification,
} from 'generated/graphql';
import { seriesToSlimSeries, stackToSlimStack } from '../../ViewportsConfigurations/manipulators';
import type { ApolloQueryResult } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import { emitPrecacheStatus } from 'modules/Apollo/emitPrecacheStatus';
import { usePrecacheQuery } from 'modules/Apollo/usePrecacheQuery';
import { useIsViewerMetadataPrecacheEnabled } from './useIsViewerMetadataPrecacheEnabled';
import { deletePrecacheQuery } from 'modules/Apollo/precacheOperation';
import { logger } from 'modules/logger';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';
import { useToasterDispatch } from 'common/ui/Toaster/Toaster';
import { atomFamily, useRecoilState } from 'recoil';
import type { PresentationState } from '../StudiesBar/PresentationStatesWidget';
import { useViewerId } from 'hooks/useViewerId';
import { useExcludedAutoloadedPriorSmids, usePriorStudiesParams } from 'hooks/studyParams';
import { GraphQLError } from 'graphql';

const dispatchedPSErrorToast = atomFamily({
  key: 'dispatchedPSErrorToast',
  default: false,
});

export const useStudyColorTracker = ({
  loadedStudies,
  pendingStudies,
}: {
  loadedStudies: string[];
  pendingStudies: string[];
}): void => {
  const registerStudyColors = useRegisterStudyColors();

  useEffect(() => {
    registerStudyColors(loadedStudies, pendingStudies);
  }, [loadedStudies, pendingStudies, registerStudyColors]);
};

type BaseViewerDataHook = {
  studies: ReadonlyArray<Study>;
  mainStudiesIds: ReadonlyArray<string>;
  priorStudiesIds: ReadonlyArray<string>;
  autoloadedStudiesIds: ReadonlyArray<string>;
  loading: boolean;
  notFound: boolean;
  classifications: SeriesClassification[];
  smids?: Array<string>;
  refetch: () => Promise<ApolloQueryResult<GetBaseViewerDataQuery>>;
  hangingProtocol?: GetBaseViewerDataQuery['matchHangingProtocolByWorklistsAndStudies'];
  networkStatus: NetworkStatus[keyof NetworkStatus];
  presentationStates: ReadonlyArray<PresentationState>;
  hasPresentationStateError: boolean;
  invalidatePrecacheForCurrentStudy: () => Promise<void>;
  invalidatePrecacheAndRefetch: () => Promise<void>;
};

const usePriorSmidsFromUrl = (): string[] => {
  const priorStudies = usePriorStudiesParams();

  const smids = useMemo(() => {
    return priorStudies?.split(',') ?? [];
  }, [priorStudies]);

  return smids;
};

export const useBaseViewerData = (
  {
    shouldEmitPrecacheStatus,
  }: {
    shouldEmitPrecacheStatus: boolean;
  } = {
    shouldEmitPrecacheStatus: false,
  }
): BaseViewerDataHook => {
  const urlPriorSmids = usePriorSmidsFromUrl();
  const excludedAutoloadedPriorSmids = useExcludedAutoloadedPriorSmids();
  const worklistId = useWorklistId();
  const emittedPrecacheStatus = useRef(false);
  const hasWorklistIds = worklistId != null;

  const shouldRefetch = useFeatureFlagEnabled(FF.VIEWER_DATA_REFETCH);
  const shouldAutoloadPriors = useFeatureFlagEnabled(FF.AUTOLOAD_PRIORS);

  const queryVariables = useMemo(
    () => ({
      worklistIds: [worklistId].filter(Boolean),
      studyIds: urlPriorSmids,
      hasWorklistIds,
      hasStudyIds: urlPriorSmids.length > 0,
      includeAutoloadedPriors: shouldAutoloadPriors,
      skippedStudyIds: excludedAutoloadedPriorSmids,
    }),
    [urlPriorSmids, worklistId, hasWorklistIds, shouldAutoloadPriors, excludedAutoloadedPriorSmids]
  );

  const precacheEnabled = useIsViewerMetadataPrecacheEnabled();

  const { loading, data, previousData, refetch, error, networkStatus } = usePrecacheQuery<
    // @ts-expect-error [EN-7967] - TS2344 - Type 'GetBaseViewerDataQuery' does not satisfy the constraint 'DocumentNode'.
    GetBaseViewerDataQuery,
    GetBaseViewerDataQueryVariables
  >(
    GET_BASE_VIEWER_DATA,
    {
      variables: queryVariables,
      skip: !hasWorklistIds,
      subscribeToNetworkChanges: true,
    },
    {
      shouldRefetch,
      precacheEnabled,
    }
  );

  // We use the previous data to avoid flickering when the query is loading
  let latestPayload = data;

  if (latestPayload == null && previousData?.worklistItems != null) {
    const hasMatches = previousData?.worklistItems?.items.some(
      (worklist) => worklist.smid === worklistId
    );

    if (hasMatches) {
      latestPayload = previousData;
    } else {
      emittedPrecacheStatus.current = false;
    }
  }

  useEffect(() => {
    if (!shouldEmitPrecacheStatus || emittedPrecacheStatus.current) return;
    emitPrecacheStatus(GET_BASE_VIEWER_DATA, queryVariables);
    emittedPrecacheStatus.current = true;
  }, [queryVariables, shouldEmitPrecacheStatus]);

  const mainStudiesIds = useMemo(
    () =>
      latestPayload?.worklistItems?.items
        .flatMap((worklist) => worklist.studies)
        .map((study) => study.smid) ?? [],
    [latestPayload]
  );

  const priorStudiesIds = useMemo(
    () => [
      ...(latestPayload?.worklistItems?.items
        .flatMap((worklist) => worklist.autoloadedPriors ?? [])
        .map((study) => study.smid) ?? []),
      ...(latestPayload?.studiesBySmid?.map((study) => study.smid) ?? []),
    ],
    [latestPayload]
  );

  // lists the autoloaded priors available in the case, even if they've been skipped
  // this exists as a stable, reliable way to handle re-adding priors that were skipped
  // without having to check the url to see if that prior's smid is in the skipped params
  const autoloadedStudiesIds = useMemo(
    () => [
      ...(latestPayload?.worklistItems?.items
        .flatMap((worklist) => worklist.allAutoloadedPriors ?? [])
        .map(
          (study: { readonly __typename?: 'DehydratedStudy'; readonly smid: string }) => study.smid
        ) ?? []),
    ],
    [latestPayload]
  );

  const studies = useMemo(() => {
    const mainStudies =
      latestPayload?.worklistItems?.items.flatMap((worklist) => worklist.studies) ?? [];

    const autoloadedPriors =
      latestPayload?.worklistItems?.items.flatMap((worklist) => worklist.autoloadedPriors) ?? [];

    const comparativeStudies = latestPayload?.studiesBySmid ?? [];

    return [...mainStudies, ...autoloadedPriors, ...comparativeStudies].filter(Boolean);
  }, [latestPayload]);

  const invalidatePrecacheForCurrentStudy = useCallback(async () => {
    logger.info('Deleting precache query GET_BASE_VIEWER_DATA with variables:', queryVariables);
    await deletePrecacheQuery({ query: GET_BASE_VIEWER_DATA, variables: queryVariables });
  }, [queryVariables]);

  // This is a failsafe mechanism to delete the cache if the query returns no studies
  // If no studies are returned it's VERY likely something in our cache is wrong and
  // it does no harm to delete it for this query since it's theoretically empty anyway
  useEffect(() => {
    if (!loading && studies.length === 0) {
      invalidatePrecacheForCurrentStudy();
    }
  }, [loading, studies.length, invalidatePrecacheForCurrentStudy]);

  useEffect(() => {
    const logIds = Array.from(
      new Set([...(autoloadedStudiesIds || []), ...(priorStudiesIds || [])])
    );

    if (logIds.length > 0) {
      logger.info(`[Autoloaded & Prior Study Ids] ${logIds.join(', ')}`);
    }
  }, [autoloadedStudiesIds, priorStudiesIds]);

  const unableToFindStudyError =
    error?.graphQLErrors?.some((err: GraphQLError) => err.extensions.code === 404) ?? false;

  const notFound = !loading && (unableToFindStudyError || studies.length === 0);

  const smids = useMemo(() => studies.map((study) => study.smid), [studies]);

  const hangingProtocol = latestPayload?.matchHangingProtocolByWorklistsAndStudies;

  // @ts-expect-error [EN-7967] - TS2322 - Type '{ readonly __typename?: "GspsPresentationState"; readonly smid: string; readonly created?: DateTimeLocalScalarConfig; readonly name?: string; readonly owner?: { readonly __typename?: "GspsOwner"; readonly firstName?: string; readonly lastName?: string; }; readonly study: { ...; }; readonly annotations: readonly { .....' is not assignable to type 'readonly PresentationState[]'.
  const presentationStates: ReadonlyArray<PresentationState> = useMemo(
    () => latestPayload?.presentationStates.filter((ps) => 'smid' in ps) ?? [],
    [latestPayload]
  );

  const { enqueueToast } = useToasterDispatch();
  const [hasDispatchedPSErrorToast, setDispatchedPSErrorToast] = useRecoilState(
    dispatchedPSErrorToast(worklistId)
  );

  const hasPresentationStateError = useMemo(
    () => latestPayload?.presentationStates.find((ps) => 'message' in ps) != null,
    [latestPayload]
  );

  const isViewer = useViewerId() != null;

  useEffect(() => {
    if (!hasPresentationStateError || hasDispatchedPSErrorToast || !isViewer) return;

    setDispatchedPSErrorToast(true);
    enqueueToast(
      `Error fetching presentation states. Editing of presentation states has been disabled.`,
      {
        severity: 'error',
        isUnique: true,
      }
    );
  }, [
    hasPresentationStateError,
    enqueueToast,
    hasDispatchedPSErrorToast,
    setDispatchedPSErrorToast,
    isViewer,
  ]);

  const invalidatePrecacheAndRefetch = useCallback(async () => {
    await invalidatePrecacheForCurrentStudy();
    await refetch();
  }, [invalidatePrecacheForCurrentStudy, refetch]);

  const classifications = useMemo(() => {
    const mainStudies =
      latestPayload?.worklistItems?.items.flatMap((worklist) => worklist.studies) ?? [];
    const priorStudies =
      latestPayload?.worklistItems?.items
        .flatMap((worklist) => worklist.autoloadedPriors)
        .filter(Boolean) ?? [];

    const series = [...mainStudies, ...priorStudies].flatMap(({ seriesList }) => seriesList);

    return series
      .map((series) => (series.classifications ? series.classifications[0] : null))
      .filter(Boolean);
  }, [latestPayload]);

  return {
    smids,
    studies,
    mainStudiesIds,
    priorStudiesIds,
    autoloadedStudiesIds,
    loading,
    notFound,
    refetch,
    hangingProtocol,
    classifications,
    // @ts-expect-error [EN-7967] - TS2322 - Type 'import("/Users/danyim/Dev/services-frontend3/frontend/node_modules/@apollo/client/core/networkStatus").NetworkStatus' is not assignable to type '((radix?: number) => string) | (() => number) | ((fractionDigits?: number) => string) | ((fractionDigits?: number) => string) | ((precision?: number) => string) | { (locales?: string | string[], options?: NumberFormatOptions): string; (locales?: LocalesArgument, options?: NumberFormatOptions): string; }'.
    networkStatus,
    presentationStates,
    hasPresentationStateError,
    invalidatePrecacheForCurrentStudy,
    invalidatePrecacheAndRefetch,
  };
};

export type StudiesHookResult = {
  groups: Array<Array<string>>;
  groupedStudies: Array<StudyGroup>;
} & BaseViewerDataHook;
export const useStudies = (): StudiesHookResult => {
  const { smids = [], studies, mainStudiesIds, ...studiesHook } = useBaseViewerData();
  const groups: Array<Array<string>> = useMemo(() => {
    const currentCaseStudies = studies.filter((study) => mainStudiesIds.includes(study.smid));

    const primaryStudySmids = currentCaseStudies.map((study) => study.smid) ?? [];
    return [
      primaryStudySmids,
      ...smids.filter((smid) => !primaryStudySmids.includes(smid)).map((smid) => [smid]),
    ].filter((group) => group.length > 0);
  }, [mainStudiesIds, smids, studies]);

  const groupedStudies: Array<StudyGroup> = useMemo(
    () =>
      groups.map((group) =>
        group.reduce<StudyGroup>((acc, studySmid) => {
          const study = studies.find((study) => study.smid === studySmid);
          if (study == null) return acc;
          return [
            ...acc,
            {
              smid: study.smid,
              description: study.description,
              seriesList: study.seriesList.map((series) => seriesToSlimSeries(series)),
              stackedFrames: study.stackedFrames.map((stack) => stackToSlimStack(stack)),
            },
          ];
        }, [])
      ),
    [groups, studies]
  );

  return {
    groups,
    groupedStudies,
    studies,
    mainStudiesIds,
    ...studiesHook,
  };
};
