import {
  Styles,
  ReporterSettings,
  TextDecoration,
  TextTransform,
  HeadingLevel as GraphQLHeadingLevel,
} from 'generated/graphql';

import { mapHeadingLevel } from '../domains/reporter/RichTextEditor/plugins/heading/utils/normalization';
import { getDefaultHeadingStyles } from '../domains/reporter/RichTextEditor/plugins/heading/utils/styling';
import { Fonts } from '../styles';
import { useMemo } from 'react';
import LineIcon from '@material-ui/icons/Remove';
import { ReactComponent as UppercaseIcon } from 'assets/Uppercase.svg';
import { ReactComponent as TitleIcon } from 'assets/TitleCase.svg';
import { HeadingLevel } from '../domains/reporter/RichTextEditor/plugins/heading/constants';

export const DEFAULT_FONT_SIZE = 14;
export const DEFAULT_TEXT_DECORATION: TextDecoration = {
  isBold: false,
  isItalic: false,
  isUnderline: false,
};
export const DEFAULT_TEXT_TRANSFORM = TextTransform.None;
export const DEFAULT_FONT_FAMILY = 'mainFont';

export function getDefaultReporterStyles(): Styles {
  return {
    bodyStyle: {
      fontSize: DEFAULT_FONT_SIZE,
      fontFamily: DEFAULT_FONT_FAMILY,
      textDecoration: DEFAULT_TEXT_DECORATION,
      textTransform: DEFAULT_TEXT_TRANSFORM,
    },
    headingStyles: getDefaultHeadingStyles(),
  };
}

export const getTextLevelSettingsLabel = (level?: GraphQLHeadingLevel | null): string => {
  const headingLevel = mapHeadingLevel(level);
  switch (headingLevel) {
    case HeadingLevel.H1:
      return 'Headings (H1)';
    case HeadingLevel.H2:
      return 'Subheadings (H2)';
    default:
      return 'body';
  }
};

export const getTextTransformSettingsLabel = (textTransform?: TextTransform | null): string => {
  switch (textTransform) {
    case 'uppercase':
      return 'Uppercase';
    case 'capitalize':
      return 'Title case';
    case 'none':
    default:
      return 'As typed';
  }
};

export const getTextTransformSettingsIcon = (
  textTransform?: TextTransform | null
): React.ComponentType<Record<any, any>> => {
  switch (textTransform) {
    case 'uppercase':
      return UppercaseIcon;
    case 'capitalize':
      return TitleIcon;
    case 'none':
    default:
      return LineIcon;
  }
};

export const getFontDisplayName = (font: string): string => {
  switch (font) {
    case 'mainFont':
      return 'Default';
    case 'serif':
      return 'Serif';
    case 'mono':
      return 'Monospace';
    default:
      return font;
  }
};

export const getFontNameFromFontFamily = (font?: string | null): string | null | undefined => {
  switch (font) {
    case "'Roboto', 'Helvetica', 'Arial', sans-serif":
      return 'mainFont';
    case 'PT Serif':
      return 'serif';
    case 'PT Mono':
      return 'mono';
    default:
      return font;
  }
};

export function useReporterStyles(reporterSettings?: ReporterSettings | null): Styles {
  const styles = reporterSettings?.styles;

  return useMemo(
    () => ({
      bodyStyle: {
        fontSize: styles?.bodyStyle.fontSize ?? DEFAULT_FONT_SIZE,
        fontFamily:
          styles?.bodyStyle.fontFamily != null
            ? Fonts[styles.bodyStyle.fontFamily]
            : Fonts[DEFAULT_FONT_FAMILY],
        textDecoration: styles?.bodyStyle.textDecoration ?? DEFAULT_TEXT_DECORATION,
        textTransform: styles?.bodyStyle.textTransform ?? DEFAULT_TEXT_TRANSFORM,
      },
      headingStyles: styles?.headingStyles ? [...styles.headingStyles] : getDefaultHeadingStyles(),
    }),
    [styles]
  );
}
