import { splitEvery } from 'ramda';
import { quickVec3Comparison } from './math';
import { Vector3 } from '@kitware/vtk.js/types';

export const stripConsecutiveEqualPoints = (points: Array<number>): Array<number> => {
  return splitEvery(3, points)
    .reduce<Vector3[]>((acc, current, index) => {
      const vec3: [number, number, number] = [current[0], current[1], current[2]];
      if (index === 0) {
        return [vec3];
      }
      if (quickVec3Comparison(vec3, acc[acc.length - 1])) {
        return acc;
      }
      return [...acc, vec3];
    }, [])
    .flat(1);
};

/**
 * Construct dashed lines (alternating cells) from a sequence of points.
 * @param {Array<number>} points Input series of points
 * @returns {Array<number>} series of index values that define vtk style line cells
 */
export const pointsToDashedLine = (points: Array<number>): Array<number> => {
  const lines: Array<number> = [];
  let c = 0;
  for (let i = 0; i < points.length; i++) {
    lines.push(2);
    lines.push(c++);
    lines.push(c++ % points.length);
  }
  return lines;
};
