import { useImagingContext } from 'domains/viewer/ViewportDre/modules/imaging/ImagingContext';
import {
  BREAST_FOCI,
  DEFAULT_LABEL_ALIGNMENT,
  RIGHT_LABEL_ALIGNMENT,
} from 'domains/viewer/ViewportDre/constants';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';

const useMammoFeatureFlag = () => {
  return useFeatureFlagEnabled(FF.ALLOW_MAMMO_SSC_FEATURES);
};

const mammoProvider = (imagingProvider) => {
  return imagingProvider && imagingProvider.classificationsIncludeFoci(BREAST_FOCI);
};

export const useClassificationBasedMammoFeatures = () => {
  const { imagingProvider } = useImagingContext();
  const allowMammoSSCFeatures = useMammoFeatureFlag();
  return allowMammoSSCFeatures && mammoProvider(imagingProvider);
};

// this is necessary for situations where we are outside of the imaging context but can
// find the right provider
export const useCheckClassificationBasedMammoFeatures = (provider) => {
  const allowMammoSSCFeatures = useMammoFeatureFlag();
  return allowMammoSSCFeatures && mammoProvider(provider);
};

export const useShouldAlignChestWallWithSideOfViewport = () => {
  const { imagingProvider } = useImagingContext();
  const mammoFeatureCheck = useCheckClassificationBasedMammoFeatures(imagingProvider);
  const orientationCheck =
    imagingProvider?.activeSliceRowDirectionIs('anterior') ||
    imagingProvider?.activeSliceRowDirectionIs('posterior');

  return mammoFeatureCheck && orientationCheck;
};

export const useClassificationBasedLabelStyles = (provider) => {
  const mammoFeatureCheck = useCheckClassificationBasedMammoFeatures(provider);
  const orientationCheck = provider?.activeSliceRowDirectionIs('anterior');

  return mammoFeatureCheck && orientationCheck ? RIGHT_LABEL_ALIGNMENT : DEFAULT_LABEL_ALIGNMENT;
};
