import { useState } from 'react';

interface Box {
  left: number;
  top: number;
  right: number;
  bottom: number;
  height: number;
  width: number;
}

export function getElementBox(el: Element): Box {
  const { left, top, right, bottom, height, width } = el.getBoundingClientRect();
  return { left, top, right, bottom, height, width };
}

export function useElementBox(el?: Element | null): Box | null {
  const [r, setRect] = useState<Box | null>(el != null ? getElementBox(el) : null);

  if (el != null) {
    const elBox = getElementBox(el);

    if (
      r == null ||
      r.left !== elBox.left ||
      r.top !== elBox.top ||
      r.right !== elBox.right ||
      r.bottom !== elBox.bottom ||
      r.width !== elBox.width ||
      r.height !== elBox.height
    ) {
      setRect(elBox);
    }
  }

  return r;
}
