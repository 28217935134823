import { Range, Editor } from 'domains/reporter/RichTextEditor/core';
import type { ParagraphPluginElement } from '../../paragraph/types';
import type {
  NvoqMessage,
  ParsedDictationChunk,
  ParsedDictationChunkText,
  VoiceCommandTypeExternal,
  VoiceCommandsEditor,
} from './createNvoqWebsocketFactory';
import { isMacro } from '../../macroPlaceholder';
import { GET_MACRO } from 'modules/Apollo/queries';
import { client } from 'modules/Apollo/client';
import type { SlateContent, Fragment } from '../../../types';
import { createParagraph } from '../../paragraph';
import { unreachableCaseLog } from 'types';
import { usePlugins } from 'domains/reporter/RichTextEditor/hooks';
import { clone, compose, cond } from 'ramda';
import { createEditor, Transforms, Text, Node, ReactEditor } from '../../../core';
import {
  getEditorSelectionSafe,
  createEditorError,
  createEditorWarning,
  slateContentToString,
  partialEditor,
} from '../../../utils';
import { stitchNodesIntoEditor, isEmptyFragment } from '../../../stitching';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import { useInsertMacro } from '../../../../Reporter/hooks/useInsertMacro';
import { useReporterDictationStepToast } from 'hooks/useReporterDictationStepToast';
import type { Macro } from 'generated/graphql';
import { logger } from 'modules/logger';
import { capitalizeEntireFirstWord } from '../../heading/utils/normalization';
import {
  PICKLIST_COMMAND_WORDS,
  usePicklistDictationSelection,
} from '../usePicklistDictationSelection';
import { useSlateSingletonContext } from '../../../../Reporter/SlateSingletonContext';
import type { GetPicklistOptionSelectionMatch } from '../usePicklistDictationSelection';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';
import { getEmptyTouchedRequiredFields } from '../../../utils/requiredFields';
import { useToasterDispatch } from 'common/ui/Toaster/Toaster';
import { useHeadingKeywords } from 'hooks/useHeadingKeywords';
import { annotateTextWithSource } from '../../textSourceStyling/utils';

export type InsertTextStep = Readonly<{
  type: 'INSERT_TEXT';
  payload: SlateContent;
}>;
export type InsertMacroStep = Readonly<{
  type: 'INSERT_MACRO';
  payload: Macro;
}>;
export type InsertVoiceCommandStep = Readonly<{
  type: 'VOICE_COMMAND';
  action: VoiceCommandsEditor;
}>;
export type InsertExternalVoiceCommandStep = Readonly<{
  type: 'EXTERNAL_VOICE_COMMAND';
  action: VoiceCommandTypeExternal;
}>;
export type Step =
  | InsertTextStep
  | InsertMacroStep
  | InsertVoiceCommandStep
  | InsertExternalVoiceCommandStep;

export type StepEntry = [Step, ParsedDictationChunk];
export type StepsQueueItem = StepEntry | null | Promise<StepEntry | null>;
export type StepsQueueItems = Array<StepsQueueItem>;

type CreateStepsOptions = {
  fieldNames: Array<string>;
  enablePicklistDictation: boolean;
  getPicklistOptionSelectionMatch: GetPicklistOptionSelectionMatch;
};

const isMacroVoiceCommand = (item: ParsedDictationChunk) =>
  item.type === 'SUBSTITUTION' && item.payload.action === 'InsertMacro';

const isVoiceCommandEditor = (item: ParsedDictationChunk) =>
  item.type === 'SUBSTITUTION' && item.payload.target === 'EDITOR';

const isExternalSubstitution = (item: ParsedDictationChunk) =>
  item.type === 'SUBSTITUTION' && item.payload.target !== 'EDITOR';

const isText = (item: ParsedDictationChunk) => item.type === 'TEXT' || item.type === 'stable_text';

const GO_TO_FIELD_TRIGGER_WORD = 'field';

const isTextAndContainsFieldKeyword = (item: ParsedDictationChunk) =>
  !!(
    isText(item) &&
    (item.payload as NvoqMessage).data?.text.toLowerCase().trim().includes(GO_TO_FIELD_TRIGGER_WORD)
  );

const isTextAndEndsInFieldKeyword = (item: ParsedDictationChunk) =>
  !!(
    isText(item) &&
    (item.payload as NvoqMessage).data?.text.toLowerCase().trim().endsWith(GO_TO_FIELD_TRIGGER_WORD)
  );

const NUMBERED_LIST_REGEX = /(?:^|\s)(\d+\.(?![.\d\w])|#\d+(?![.\w]))/;

// Map of similar phrases to numbered list commands - expand as needed
export const similarPhrasesToNumberedListMap = {
  'number won': '#1',
  'number one': '#1',
  'number too': '#2',
  'number to': '#2',
  'number free': '#3',
  'number tree': '#3',
  'number for': '#4',
  'number hive': '#5',
  'number sicks': '#6',
  'number ate': '#8',
  'free period': '3.',
  'one period': '1.',
  'to period': '2.',
  'for period': '4.',
} as const;

export const isTextAndContainsDynamicNumberedListCommand = (
  item: ParsedDictationChunk
): boolean => {
  if (!isText(item) || !(item.payload as NvoqMessage).data?.text) {
    return false;
  }

  const text = (item.payload as NvoqMessage).data.text.toLowerCase().trim();

  const matchesNumberListRegex = NUMBERED_LIST_REGEX.test(text);

  if (matchesNumberListRegex) {
    return true;
  }

  return Object.keys(similarPhrasesToNumberedListMap).some((phrase) => text.includes(phrase));
};

// Regex to match "number" (case-insensitive) as a whole word or "#" as a standalone symbol.
// Uses non-capturing group to check for:
// - "\bnumber\b": "number" surrounded by word boundaries to ensure it's not part of a larger word.
// - "(?<!\S)#(?!\S)": "#" not preceded or followed by any non-whitespace characters, ensuring it's isolated.
const NUMBER_KEYWORD_REGEX = /(?:\bnumber\b|(?<!\S)#(?!\S))/i;

const DIGIT_REGEX = /(?<!\S)\d+(?!\S)/;

const isTextAndEndsInNextNumberKeyword = (item: ParsedDictationChunk) => {
  if (isText(item) === false) return false;

  if ((item.payload as NvoqMessage).data?.text == null) return false;

  const text = (item.payload as NvoqMessage).data.text.toLowerCase().trim();
  const words = text.split(/\s+/);
  const lastWord = words.pop();

  return NUMBER_KEYWORD_REGEX.test(lastWord);
};

const isTextAndEndsInDigit = (item: ParsedDictationChunk) => {
  if (isText(item) === false) return false;

  // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'.
  if (item.payload.data?.text == null) return false;

  // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'.
  const text = item.payload.data.text.toLowerCase().trim();
  const words = text.split(/\s+/);
  const lastWord = words.pop();

  return DIGIT_REGEX.test(lastWord);
};

/**
 * Checks if a step entry contains a 'GoToField' command that potentially matches
 * or partially matches any of the provided field names.
 *
 * @param {StepEntry} stepEntry - The step entry to check.
 * @param {Array<string>} fieldNames - A list of field names to match against.
 * @returns {boolean} True if there's a potential match; false otherwise.
 */
export const containsPotentiallyIncompleteFieldCommand = (
  item: ParsedDictationChunk,
  fieldNames: Array<string>
): boolean => {
  if (fieldNames == null) {
    return false;
  }

  if (isTextAndContainsFieldKeyword(item) === false) {
    return false;
  }

  // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | ... 8 more ... | { ...; }'.
  const textAfterKeyword = item.payload.data?.text
    .slice(
      // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | ... 8 more ... | { ...; }'.
      item.payload.data?.text.indexOf(GO_TO_FIELD_TRIGGER_WORD) + GO_TO_FIELD_TRIGGER_WORD.length
    )
    .trim()
    .toLowerCase();

  if (textAfterKeyword == null) {
    return false;
  }

  const fieldNamesLowerCase = fieldNames.map((fieldName) => fieldName.toLowerCase().trim());

  return fieldNamesLowerCase.some((fieldName) => {
    if (textAfterKeyword === fieldName) {
      return false;
    } else if (fieldName.startsWith(textAfterKeyword)) {
      return true;
    } else if (textAfterKeyword.startsWith(fieldName)) {
      // If the text starts with a complete field name and has additional content,
      const remainingText = textAfterKeyword.slice(fieldName.length).trim();
      return remainingText.length === 0;
    }
    return false;
  });
};

const isTextAndContainsPicklistKeyword = (item: ParsedDictationChunk) =>
  isText(item) &&
  PICKLIST_COMMAND_WORDS.some((keyword) =>
    // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'.
    item.payload.data?.text.toLowerCase().trim().includes(keyword)
  );

const isTextAndEndsInPicklistKeyword = (item: ParsedDictationChunk) =>
  isText(item) &&
  PICKLIST_COMMAND_WORDS.map((keyword) => keyword.toLowerCase().trim()).some((keyword) =>
    // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'.
    item.payload.data?.text.toLowerCase().trim().endsWith(keyword)
  );

export const handlePicklistOptionSelectionVoiceCommand = (
  item: ParsedDictationChunkText,
  { enablePicklistDictation, fieldNames, getPicklistOptionSelectionMatch }: CreateStepsOptions
): StepsQueueItems => {
  if (enablePicklistDictation === false) {
    return [handleStableText(item)];
  }

  const text = item.payload.data?.text ?? '';

  const picklistCommandMatch = getPicklistOptionSelectionMatch(text);

  // if there is no match, then handle stable text as normal
  if (picklistCommandMatch == null) {
    return [handleStableText(item)];
  }

  const steps: Array<StepsQueueItem> = [];
  const { pickIndex, preMatchText, postMatchText } = picklistCommandMatch;

  if (preMatchText.length > 0) {
    const preMatchItem = createParsedDictationChunkText(item, preMatchText);
    const preMatchSteps = createSteps(preMatchItem, {
      fieldNames,
      enablePicklistDictation,
      getPicklistOptionSelectionMatch,
    });
    steps.push(...preMatchSteps);
  }

  steps.push([
    { type: 'VOICE_COMMAND', action: 'PicklistOptionSelection' },
    {
      type: 'SUBSTITUTION',
      payload: {
        target: 'EDITOR',
        action: 'PicklistOptionSelection',
        pickIndex,
      },
    },
  ]);

  if (postMatchText.length > 0) {
    const postMatchItem = createParsedDictationChunkText(item, postMatchText);
    const postMatchSteps = createSteps(postMatchItem, {
      fieldNames,
      enablePicklistDictation,
      getPicklistOptionSelectionMatch,
    });
    steps.push(...postMatchSteps);
  }

  return steps;
};

type FieldNameMatch = {
  originalName: string;
  preMatchText: string;
  postMatchText: string;
};

export const getFieldNameMatch = (text: string, fieldNames: string[]): FieldNameMatch | null => {
  const generateFieldNameVariants = (fieldName: string) => {
    // Tracks unique altered names to prevent duplicates
    const uniqueAlteredNames = new Set();

    // If a new variant is added, please make sure to add it to the 'getFieldNameMatch' tests
    const variants = [
      fieldName.toLowerCase().trim(),
      fieldName.replace(/\s+/g, '').toLowerCase(),
      fieldName.replace(/2/gi, ' to').toLowerCase().trim(),
      fieldName.replace(/2/gi, ' too').toLowerCase().trim(),
      fieldName.replace(/3/gi, ' free').toLowerCase().trim(),
      fieldName.replace(/4/gi, ' for').toLowerCase().trim(),
      fieldName.replace(/(\d+)/g, ' $1').replace(/\s+/g, ' ').toLowerCase().trim(),
    ];

    return variants.reduce<Array<any>>((acc, alteredName) => {
      if (!uniqueAlteredNames.has(alteredName)) {
        uniqueAlteredNames.add(alteredName);
        acc.push({ originalName: fieldName, alteredName });
      }
      return acc;
    }, []);
  };

  // Sort by length of altered name so that we match the longest name first
  const fieldNameMappings = fieldNames
    .flatMap(generateFieldNameVariants)
    .sort((a, b) => b.alteredName.length - a.alteredName.length);

  const normalizedText = text.toLowerCase();

  for (const { originalName, alteredName } of fieldNameMappings) {
    const matchIndex = normalizedText.indexOf(alteredName);
    // If the altered name is found in the text, then we have a match. Return the contextual info needed to create the steps
    if (matchIndex !== -1) {
      const fieldIndex = text.toLowerCase().indexOf(GO_TO_FIELD_TRIGGER_WORD);
      const fieldToEndText = text.slice(fieldIndex);

      const preMatchText = text.slice(0, fieldIndex).trim();
      const postMatchText = fieldToEndText
        .slice(`${GO_TO_FIELD_TRIGGER_WORD} `.length + alteredName.length)
        .trim();

      return { preMatchText, originalName, postMatchText };
    }
  }

  return null;
};

export const stepEntriesTextToString = (stepEntries: StepEntry[]): string => {
  return stepEntries
    .flatMap((stepEntry) => {
      const [step] = stepEntry;

      if (step.type === 'INSERT_TEXT') {
        return step.payload.map((fragment) => {
          return Node.string(fragment);
        });
      }

      return null;
    })
    .filter(Boolean)
    .join(' ');
};

const createParsedDictationChunkText = (
  item: ParsedDictationChunkText,
  newText: string
): ParsedDictationChunkText => ({
  ...item,
  payload: {
    ...item.payload,
    data: {
      ...item.payload.data,
      text: newText,
      substitutedText: newText,
    },
  },
});

function processTextForSimilarPhrasesToNumberedList(text: string): string {
  let processedText = text.toLowerCase();

  Object.keys(similarPhrasesToNumberedListMap).forEach((phrase) => {
    const regex = new RegExp(`\\b${phrase}\\b`, 'gi');
    processedText = processedText.replace(regex, similarPhrasesToNumberedListMap[phrase]);
  });

  return processedText;
}

export const handleNextNumberDynamicVoiceCommand = (
  item: ParsedDictationChunkText,
  options: CreateStepsOptions
): StepsQueueItems => {
  const text = processTextForSimilarPhrasesToNumberedList(item.payload.data?.text ?? '');

  const steps: Array<StepsQueueItem> = [];
  const match = NUMBERED_LIST_REGEX.exec(text);

  if (match != null) {
    const numberedListText = match[0];
    const preMatchIndex = match.index;
    const preMatchText = text.slice(0, preMatchIndex).trim();

    // #3 -> 2 or 3. -> 2 and convert from string to number
    const numberedListIndex = parseInt(numberedListText.replace(/[^\d]/g, ''), 10) - 1;

    if (preMatchText.length > 0) {
      const preMatchItem = createParsedDictationChunkText(item, preMatchText);

      const preMatchSteps = createSteps(preMatchItem, options);
      steps.push(...preMatchSteps);
    }

    // If the index is 0, then we are creating a new numbered list
    if (numberedListIndex === 0) {
      steps.push([
        { type: 'VOICE_COMMAND', action: 'StartNumbering' },
        {
          type: 'SUBSTITUTION',
          payload: {
            target: 'EDITOR',
            action: 'StartNumbering',
            originalTextStepEntry: handleStableText(
              createParsedDictationChunkText(item, numberedListText)
            ),
          },
        },
      ]);
    } else {
      steps.push([
        { type: 'VOICE_COMMAND', action: 'NextNumberDynamic' },
        {
          type: 'SUBSTITUTION',
          payload: {
            target: 'EDITOR',
            action: 'NextNumberDynamic',
            listItemIndex: numberedListIndex,
            originalTextStepEntry: handleStableText(
              createParsedDictationChunkText(item, numberedListText)
            ),
          },
        },
      ]);
    }

    const postMatchText = text.slice(preMatchIndex + numberedListText.length).trim();
    if (postMatchText.length > 0) {
      const postMatchItem = createParsedDictationChunkText(item, postMatchText);
      const postMatchSteps = createSteps(postMatchItem, options);
      steps.push(...postMatchSteps);
    }

    return steps;
  }

  // if there is no match, then handle stable text as normal
  return [handleStableText(item)];
};

export const handleGoToFieldVoiceCommand = (
  item: ParsedDictationChunkText,
  { fieldNames, enablePicklistDictation, getPicklistOptionSelectionMatch }: CreateStepsOptions
): StepsQueueItems => {
  const text = item.payload.data?.text ?? '';

  const fieldNameMatch = getFieldNameMatch(text, fieldNames);

  if (fieldNameMatch != null) {
    const steps: Array<StepsQueueItem> = [];
    const { originalName, preMatchText, postMatchText } = fieldNameMatch;

    if (preMatchText.length > 0) {
      const preMatchItem = createParsedDictationChunkText(item, preMatchText);
      const preMatchSteps = createSteps(preMatchItem, {
        fieldNames,
        enablePicklistDictation,
        getPicklistOptionSelectionMatch,
      });
      steps.push(...preMatchSteps);
    }

    steps.push([
      { type: 'VOICE_COMMAND', action: 'GoToField' },
      {
        type: 'SUBSTITUTION',
        payload: {
          target: 'EDITOR',
          action: 'GoToField',
          fieldName: originalName,
        },
      },
    ]);

    if (postMatchText.length > 0) {
      const postMatchItem = createParsedDictationChunkText(item, postMatchText);
      const postMatchSteps = createSteps(postMatchItem, {
        fieldNames,
        enablePicklistDictation,
        getPicklistOptionSelectionMatch,
      });
      steps.push(...postMatchSteps);
    }

    return steps;
  }

  // if there is no match, then handle stable text as normal
  return [handleStableText(item)];
};

const handleMacroVoiceCommand = async (item: ParsedDictationChunk): Promise<StepEntry | null> => {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'smid' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | ... 6 more ... | Readonly<...>'.
  if (item.type !== 'SUBSTITUTION' || item.payload.smid == null) return null;

  // PERF: Send off query soon as we know we have a substitution so that it
  // hopefully resolves before the user finishes recording at a given selection
  return client
    .query({
      query: GET_MACRO,
      // @ts-expect-error [EN-7967] - TS2339 - Property 'smid' does not exist on type 'Readonly<{ target: "EDITOR"; smid?: string; action: VoiceCommandsEditor; }> | Readonly<{ target: "EDITOR"; fieldName: string; action: "GoToField"; }> | ... 6 more ... | Readonly<...>'.
      variables: { id: item.payload.smid },
      fetchPolicy: 'network-only',
    })
    .then((item) => {
      const maybeMacro = item?.data?.macro;
      if (isMacro(maybeMacro.text)) {
        // @ts-expect-error [EN-7967] - TS2352 - Conversion of type '[{ type: "INSERT_MACRO"; payload: any; }, ApolloQueryResult<any>]' to type 'StepEntry' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        return [
          {
            type: 'INSERT_MACRO',
            payload: maybeMacro,
          },
          item,
        ] as StepEntry;
      }

      return null;
    });
};

const handleVoiceCommandEditor = (item: ParsedDictationChunk): StepEntry | null => {
  if (item.type === 'SUBSTITUTION' && item.payload.target === 'EDITOR') {
    return [{ type: 'VOICE_COMMAND', action: item.payload.action }, item];
  }
  return null;
};

const handleExternalSubstitution = (item: ParsedDictationChunk): StepEntry | null => {
  if (item.type === 'SUBSTITUTION' && item.payload.target !== 'EDITOR') {
    return [{ type: 'EXTERNAL_VOICE_COMMAND', action: item.payload.action }, item];
  }
  return null;
};

const handleStableText = (item: ParsedDictationChunk): StepEntry | null => {
  if (item.type !== 'TEXT' && item.type !== 'stable_text') return null;

  // @ts-expect-error [EN-7967] - TS2339 - Property 'text' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'. | TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | { ...; }'.
  const text = item.payload.text ?? item.payload.data?.text;

  if (text == null) {
    logger.error('[handleStableText] No text found in stable text', item);
    return null;
  }

  const parsedStableText = text
    .split(/\n/g) // Splits on newlines
    // @ts-expect-error [EN-7967] - TS2339 - Property 'parsedStableText' does not exist on type 'Step'.
    .reduce<Step['parsedStableText'][]>(
      (
        contentFragment:
          | Array<ParagraphPluginElement | SlateContent>
          | Array<
              | ParagraphPluginElement
              | SlateContent
              | {
                  text: string;
                }
              | {
                  children: Array<{
                    text: string;
                  }>;
                  type: 'paragraph';
                }
            >,
        item,
        index
      ) => {
        // trimming to prevent stitching of unnecessary whitespace downstream
        const text = item.trim();
        if (index === 0) {
          return [...contentFragment, { text }];
        } else if (text === '') {
          return [...contentFragment, createParagraph()];
        }

        // Automatically create a paragraph for all non-empty elements after the first one.
        // Since we're splitting on `\n`, every non-zero indexed array entry should
        // be its own paragraph.
        contentFragment.push(createParagraph());
        const previousParagraph = contentFragment[contentFragment.length - 1];

        return [
          // We're replacing the previously empty paragraph node with content
          ...contentFragment.slice(0, contentFragment.length - 1),
          // $FlowFixMe[incompatible-type] - Flow types for Slate internal is mixed. It will be a children with a single text node
          // $FlowFixMe[incompatible-use]
          createParagraph(`${previousParagraph.children[0].text}${item}`),
        ];
      },
      []
    );

  return [{ type: 'INSERT_TEXT', payload: parsedStableText }, item];
};

/**
 * Checks if the step entries are text and ends in a digit but not a potential picklist command.
 * There are cases where picklist commands and digits can clash, with priority given to picklist commands.
 *
 * E.g. text of "2" should be deferred
 *      text of "banana 2" should be deferred
 *      text of "pick 2" should not be deferred because it's a potential picklist command
 *
 */
const isTextAndEndsInDigitButNotPotentialPicklistCommand = (
  stepEntries: StepEntry[],
  i: number
) => {
  const stepEntry = stepEntries[i];

  if (!isTextAndEndsInDigit(stepEntry[1])) {
    return false;
  }

  if (isTextAndContainsPicklistKeyword(stepEntry[1])) {
    return false;
  }

  // if the previous step contains a picklist keyword, then we should not defer
  if (stepEntries[i - 1] != null && isTextAndContainsPicklistKeyword(stepEntries[i - 1][1])) {
    return false;
  }

  return true;
};

export const getDeferredSteps = (
  stepEntries: StepEntry[],
  {
    fieldNames,
  }: {
    fieldNames: Array<string>;
  }
): StepEntry[] => {
  for (let i = stepEntries.length - 1; i >= 0; i--) {
    const stepEntry = stepEntries[i];
    if (stepEntriesTextToString([stepEntry]) !== '') {
      if (
        isTextAndEndsInFieldKeyword(stepEntry[1]) ||
        containsPotentiallyIncompleteFieldCommand(stepEntry[1], fieldNames) ||
        isTextAndEndsInPicklistKeyword(stepEntry[1]) ||
        isTextAndEndsInNextNumberKeyword(stepEntry[1]) ||
        isTextAndEndsInDigitButNotPotentialPicklistCommand(stepEntries, i)
      ) {
        return stepEntries.slice(i, stepEntries.length);
      } else {
        return [];
      }
    }
  }
  return [];
};

export const maybeMergeAdjacentTextChunks = (
  parsedDictationChunks: ParsedDictationChunk[],
  fieldNames: Array<string>
): ParsedDictationChunk[] => {
  const mergedSteps: Array<ParsedDictationChunk> = [];

  for (let i = 0; i < parsedDictationChunks.length; i++) {
    const current = parsedDictationChunks[i];

    if (current.type === 'TEXT') {
      const currentText = current.payload.data.text;
      const currentSubstitutedText = current.payload.data.substitutedText;

      if (i > 0) {
        const last = mergedSteps[mergedSteps.length - 1];

        // Only consider merging if current and last step are text type
        if (last && last.type === 'TEXT') {
          if (
            last.payload.data.text === '' ||
            (isTextAndEndsInFieldKeyword(last) &&
              (currentText === '' || (currentText.length > 0 && currentText[0] === ' '))) ||
            (isTextAndEndsInPicklistKeyword(last) &&
              (currentText === '' || (currentText.length > 0 && currentText[0] === ' ')))
          ) {
            last.payload.data.text += currentText;
            last.payload.data.substitutedText += currentSubstitutedText;
            continue;
            // Merge current chunk into last if last ends in the field keyword
          } else if (
            isTextAndEndsInFieldKeyword(last) ||
            containsPotentiallyIncompleteFieldCommand(last, fieldNames) ||
            isTextAndEndsInPicklistKeyword(last)
          ) {
            last.payload.data.text += ` ${currentText}`;
            last.payload.data.substitutedText += ` ${currentSubstitutedText}`;
            continue;
          } else if (
            isTextAndEndsInDigit(last) &&
            (currentText.trim().startsWith('.') || currentText.trim() === '')
          ) {
            last.payload.data.text += currentText.trim();
            last.payload.data.substitutedText += currentSubstitutedText.trim();
            continue;
          } else if (isTextAndEndsInNextNumberKeyword(last)) {
            let trimmedText = last.payload.data.text.trim();

            if (DIGIT_REGEX.test(currentText) && NUMBER_KEYWORD_REGEX.test(trimmedText)) {
              // Replace 'number' with '#' and trim any extra whitespace
              trimmedText = trimmedText.replace(/\bnumber\b$/i, '#').trim();

              // Append the current text after the replaced text
              last.payload.data.text = `${trimmedText}${currentText.trim()}`;
              last.payload.data.substitutedText = `${trimmedText}${currentSubstitutedText.trim()}`;
            }
            continue;
          }
        }
      }
    }
    // Add the current chunk to the mergedSteps if it's not merged with the previous one
    mergedSteps.push(current);
  }
  return mergedSteps;
};

const createSteps = (
  parsedFragment: ParsedDictationChunk,
  options: CreateStepsOptions
): StepsQueueItems =>
  cond([
    [isMacroVoiceCommand, () => [handleMacroVoiceCommand(parsedFragment)]],
    [isVoiceCommandEditor, () => [handleVoiceCommandEditor(parsedFragment)]],
    [
      isTextAndContainsPicklistKeyword,
      () =>
        // @ts-expect-error [incompatible-call] - parsedFragment: doesn't like that we've cast a more specific type, though checked in 'isTextAndContainsFieldKeyword'
        handlePicklistOptionSelectionVoiceCommand(parsedFragment, options),
    ],
    [
      isTextAndContainsFieldKeyword,
      () =>
        // @ts-expect-error [incompatible-call] - parsedFragment: doesn't like that we've cast a more specific type, though checked in 'isTextAndContainsFieldKeyword'
        handleGoToFieldVoiceCommand(parsedFragment, options),
    ],
    [
      isTextAndContainsDynamicNumberedListCommand,
      () => {
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ParsedDictationChunk' is not assignable to parameter of type 'Readonly<{ type: "TEXT"; payload: Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }>; }>'.
        return handleNextNumberDynamicVoiceCommand(parsedFragment, options);
      },
    ],
    [isExternalSubstitution, () => [handleExternalSubstitution(parsedFragment)]],
    [isText, () => [handleStableText(parsedFragment)]],
  ])(parsedFragment);

/**
 * TODO: This won't be needed when we field components are removed.
 * When unfurling, fragment content is wrapped in nodes to make
 * it valid against the reporter type
 * [{ type: 'field', children: [{ type: 'paragraph', children: slateContent }] }]
 *
 * This digs down to get the unfurled content to return
 */
const getFragmentContentFromTempEditor = (
  editor: Editor,
  {
    unwrapFirstChild,
  }: {
    unwrapFirstChild: boolean;
  } = { unwrapFirstChild: false }
): Fragment => {
  const editorChildren: any = editor.children;
  // If there is only one block node, return the children of the block node
  if (editor.children?.length === 1) {
    return editorChildren?.[0]?.children;
  }

  const firstBlockNode = editor.children?.[0];
  const firstBlockNodeChildren = firstBlockNode?.children;

  // Omit first node if it's a new line
  if (
    Array.isArray(firstBlockNodeChildren) &&
    firstBlockNodeChildren.length === 1 &&
    Node.isNode(firstBlockNode) &&
    Node.string(firstBlockNode) === ''
  ) {
    const [, ...otherChildren] = editor.children;
    return otherChildren;
  }

  if (unwrapFirstChild && editor.children[0].type === 'paragraph') {
    const [initialParagraph, ...otherChildren] = editor.children;
    return [
      ...(Array.isArray(initialParagraph.children) ? initialParagraph.children : []),
      ...otherChildren,
    ];
  }

  return editor.children;
};

const handleVoiceCommandStep = (editor: Editor, step: InsertVoiceCommandStep) => {};

export const useSteps = (): {
  createStepsFromStableText: (
    ParsedDictationChunk: ParsedDictationChunk[],
    arg2: {
      fieldNames: string[];
      enablePicklistDictation: boolean;
    }
  ) => Promise<StepEntry[]>;
  resolveSteps: (maybeSteps: StepsQueueItems) => Promise<StepEntry[]>;
  buildFragmentFromSteps: (steps: StepEntry[]) => Fragment;
  applySteps: (arg1: {
    steps: StepEntry[];
    editor: Editor;
    at: Range;
    select?: boolean;
    enableNormalize?: boolean;
    source?: string;
  }) => void;
} => {
  // Get the enhancers from the editor that is currently enabled to make sure steps adhere to
  // the same ruleset as the editor.
  const { getEditorStateEnhancers } = usePlugins();
  const insertMacro = useInsertMacro();
  const { enqueueDictationStepToast } = useReporterDictationStepToast();
  const [{ editor }] = useSlateSingletonContext();
  const { getPicklistOptionSelectionMatch } = usePicklistDictationSelection({ editor });
  const { onInsertTextRequiredField } = useRequiredFieldIndicator();
  const { enqueueToast } = useToasterDispatch();
  const headingKeywords = useHeadingKeywords();

  const createStepsFromStableText = async (
    _parsedDictationChunks: ParsedDictationChunk[],
    {
      fieldNames,
      enablePicklistDictation,
    }: {
      fieldNames: string[];
      enablePicklistDictation: boolean;
    }
  ): Promise<StepEntry[]> => {
    const parsedDictationChunks = maybeMergeAdjacentTextChunks(_parsedDictationChunks, fieldNames);

    const createdStepEntries = parsedDictationChunks.flatMap((parsedFragment) =>
      createSteps(parsedFragment, {
        fieldNames,
        enablePicklistDictation,
        getPicklistOptionSelectionMatch,
      })
    );

    return resolveSteps(createdStepEntries);
  };

  /**
   * Resolves all potential steps, but does not apply them to the editor or create a fragment
   * for stitching.
   */
  const resolveSteps = async (maybeSteps: StepsQueueItems): Promise<StepEntry[]> => {
    // NOTE: nVoq handles the spaces between the dictations
    try {
      const resolvedMaybeSteps = await Promise.all(maybeSteps);

      // I'm casting here because flow won't cast the array of maybe types to not maybe types based on the predicate
      const steps = resolvedMaybeSteps.filter(
        (maybeStep) => maybeStep != null
        // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      ) as StepEntry[];

      return steps;
    } catch (error: any) {
      // Fail softly and don't crash the app since a user can dictate again
      createEditorError(error);
      logger.error('[useSteps] Error occurred when resolving steps', error);
      return [];
    }
  };

  const buildFragmentFromSteps = (steps: StepEntry[]): Fragment => {
    // PERF: If there's no steps, return early
    if (steps.length === 0) return [{ text: '' }];
    // Bootstrap a temporary Slate singleton that we can apply operations to in order
    // to maintain the same ruleset that's defined by the target editor this resolved
    // content will be stitched into.
    // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    const tempEditor = compose(...getEditorStateEnhancers())(createEditor());
    // TODO: This won't be needed when we field components are removed.
    // NOTE(mwood23) 5/31/21: This is temporary until we get rid of the fields component. I'm told
    // it's coming soon! Much code to delete ⛷
    tempEditor.children = [createParagraph()];

    // Select the text node from above so we can run transforms at a given selection
    Transforms.select(tempEditor, [0, 0]);

    // For actions that apply to a node that follows the action, we need the action to flip a switch
    let capitalizeNextStep = false;

    const stepsToApply = steps.reduce<Array<any>>(
      (
        accu: Array<
          | StepEntry
          | Array<
              | ParsedDictationChunk
              | {
                  payload: Array<{
                    text: string;
                  }>;
                  type: 'INSERT_TEXT';
                }
            >
        >,
        stepEntry
      ) => {
        const [step] = stepEntry;

        if (capitalizeNextStep && step.type === 'INSERT_TEXT') {
          capitalizeNextStep = false;
          const capitalizedStep: Step = {
            ...step,
            payload: [
              {
                text: capitalizeEntireFirstWord(slateContentToString(step.payload)),
              },
            ],
          };
          accu = [...accu, [capitalizedStep, stepEntry[1] as ParsedDictationChunk]];
          return accu;
        }

        if (step.type !== 'VOICE_COMMAND') {
          return [...accu, [step as Step, stepEntry[1] as ParsedDictationChunk]];
        }

        const { action } = step;
        const newAccu = [...accu];

        // if the action cannot work in 'maybeDoVoiceCommand()', put it here:
        switch (action) {
          // TODO 13th Sep 2023 - nVoq formatting commands are currently overriding this.
          case 'AllCaps':
            capitalizeNextStep = true;
            return newAccu;
          default:
            unreachableCaseLog(action);
            return newAccu;
        }
      },
      []
    );

    stepsToApply.forEach(([step]: [any]) => {
      switch (step.type) {
        case 'VOICE_COMMAND':
          enqueueDictationStepToast(
            `[useSteps] - Voice command triggered: '${step.action ?? 'n/a'}'`
          );
          analytics.track(reporter.usr.voiceCommand, { commandType: step.action });
          return handleVoiceCommandStep(tempEditor, step);
        case 'EXTERNAL_VOICE_COMMAND':
          enqueueDictationStepToast(
            `[useSteps] - External Voice command triggered: '${step.action ?? 'n/a'}'`
          );
          analytics.track(reporter.usr.voiceCommand, { commandType: step.action });
          return;
        case 'INSERT_MACRO':
          analytics.track(reporter.usr.voiceCommand, { commandType: 'InsertMacro' });
          enqueueDictationStepToast(
            `[useSteps] - Macro insertion voice command triggered: '${step.payload.name}'`
          );
          return insertMacro({
            editor: tempEditor,
            editorStateEnhancers: getEditorStateEnhancers(),
            macro: step.payload,
            at: getEditorSelectionSafe(tempEditor),
          });
        case 'INSERT_TEXT':
          slateContentToString(step.payload).trim().length > 0 &&
            enqueueDictationStepToast(
              `[useSteps] - Text inserted in useSteps: '${slateContentToString(step.payload)}'`
            );
          return stitchNodesIntoEditor(tempEditor, step.payload, {
            at: getEditorSelectionSafe(tempEditor),
            enableNormalize: true,
            select: true,
            shouldNameField: false, // we don't want to name the fields according to the temporary editor.
            headingKeywords,
          });
        default:
          unreachableCaseLog(step.type);
          return;
      }
    });

    const [firstStep] = steps[0];

    // We need to make sure we respect the user's intent so if a text node is the first node
    // that comes in we can't create a fragment with a wrapping paragraph node because that'll
    // cause the stitching algorithm to split nodes causing the text to go one line below their
    // desired intent.
    let unwrapFirstChild = false;
    let payloadText;

    if (firstStep.type === 'INSERT_TEXT') {
      payloadText = firstStep.payload[0];
    } else if (firstStep.type === 'INSERT_MACRO') {
      payloadText = firstStep.payload.text[0];
    }

    if (payloadText && (Text.isText(payloadText) || tempEditor.isInline(payloadText))) {
      unwrapFirstChild = true;
    }

    const fragmentContent = getFragmentContentFromTempEditor(tempEditor, {
      unwrapFirstChild,
    });

    return clone(fragmentContent);
  };

  /**
   * Applies the first step to the editor (if necessary), create a fragment of the other steps,
   * and stitches them into the editor.
   */
  const applySteps = ({
    steps,
    editor,
    at,
    select = true,
    enableNormalize = true,
    source,
  }: {
    steps: StepEntry[];
    editor: Editor;
    at: Range;
    select?: boolean;
    enableNormalize?: boolean;
    source?: string;
  }): void => {
    // PERF: If there's no steps, return early
    if (steps.length === 0) return;

    const fragment = buildFragmentFromSteps(steps);
    // If the fragment is empty, we don't want to insert anything. A common case
    // when this occurs is if a radiologist is tabbing through the report without
    // dictating, each change in selection will create an empty dictation.
    if (isEmptyFragment(fragment)) {
      if (select) {
        Transforms.select(editor, at);
      }
      return;
    }

    if (at == null) {
      const stepsErrorMessage = '[useSteps] Cannot dictate text if no location is given! Ignoring.';
      createEditorWarning(stepsErrorMessage);
      logger.error(stepsErrorMessage, {
        editor: editor != null ? partialEditor(editor) : 'null',
        steps,
      });
      return;
    }

    const emptyTouchedRequiredFields = getEmptyTouchedRequiredFields(editor, at);
    for (const [node] of emptyTouchedRequiredFields) {
      onInsertTextRequiredField(ReactEditor.findKey(editor, node));
    }

    const fragmentToStitch = source != null ? annotateTextWithSource(fragment, source) : fragment;

    stitchNodesIntoEditor(editor, fragmentToStitch, {
      at,
      select,
      enableNormalize,
      enableLogging: true,
      enqueueToast,
      headingKeywords,
    });
  };

  return { createStepsFromStableText, resolveSteps, applySteps, buildFragmentFromSteps };
};
