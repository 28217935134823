import { getRawPCMSpeechProcessorUrl } from './getRawPCMSpeechProcessorUrl';
import { getDownsampleRawPCMSpeechProcessorUrl } from './getDownsampleRawPCMSpeechProcessorUrl';
import { DEFAULT_EXPERIMENTAL_CONFIGURATION } from 'domains/reporter/Reporter/ExperimentControlTab';
import type { AudioSamplingRate } from 'domains/reporter/Reporter/ExperimentControlTab';
import { logger } from 'modules/logger';
import Retry from 'utils/retry';
import { copyGetter } from 'utils/common';

export class AudioContextLazy {
  whenInitialized: Promise<undefined>;
  audioContext: AudioContext | null | undefined = null;
  resolve: () => void;
  isRetrying: boolean = false;
  initializeCalled: boolean = false;
  gamepadConnected: boolean = false;
  gamepadIndex: number = 0;
  audioSamplingRate: AudioSamplingRate = 48000;

  constructor() {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Promise<void>' is not assignable to type 'Promise<undefined>'.
    this.whenInitialized = this.initializeCallback();
    this.initialize();
    this.addInitEventListeners();
  }

  static INITIAL_INTERVAL_DURATION_MS: number = 2000;

  static MAX_RETRIES: number = 2;

  static TIMEOUT_ERROR_MSG: string = '[AudioContextLazy] Retry timeout error';

  initializeCallback(): Promise<void> {
    return new Promise((res: () => void) => {
      this.resolve = res;
    });
  }

  async get(): Promise<AudioContext> {
    if (this.audioContext == null && this.isRetrying === false) {
      try {
        const retry = new Retry(this.whenInitialized, {
          maxRetries: AudioContextLazy.MAX_RETRIES,
          initialInterval: AudioContextLazy.INITIAL_INTERVAL_DURATION_MS,
          backoffFn: (retries) => retries,
        });
        this.isRetrying = true;
        await retry.retry();
      } catch (error: any) {
        logger.error(AudioContextLazy.TIMEOUT_ERROR_MSG, error);
        throw new Error(AudioContextLazy.TIMEOUT_ERROR_MSG);
      }
    }
    this.isRetrying = false;

    return this.audioContext;
  }

  // New AudioContext can be initialized before user gesture
  // doing so will produce "The AudioContext was not allowed to start." warning in console
  // but implementation is inline with Chrome guidelines: https://developer.chrome.com/blog/autoplay/#webaudio
  async initialize(): Promise<void> {
    if (this.initializeCalled) return;

    try {
      const audioContext = await AudioContextLazy.create(this.audioSamplingRate);
      this.initializeCalled = true;
      this.audioContext = audioContext;
      this.resolve();
    } catch (error: any) {
      logger.error('[AudioContextLazy.initialize]: Error initializing audio context', error);
    }
  }

  async reset() {
    if (
      this.initializeCalled === false ||
      this.audioContext == null ||
      this.audioContext.close == null
    ) {
      return;
    }

    this.audioContext.close();
    this.audioContext = null;
    this.initializeCalled = false;
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Promise<void>' is not assignable to type 'Promise<undefined>'.
    this.whenInitialized = this.initializeCallback();

    await this.initialize();
  }

  static async create(audioSamplingRate: AudioSamplingRate): Promise<AudioContext> {
    const audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: audioSamplingRate,
    });

    await Promise.all([
      audioContext.audioWorklet.addModule(getDownsampleRawPCMSpeechProcessorUrl()),
      audioContext.audioWorklet.addModule(getRawPCMSpeechProcessorUrl()),
    ]);

    return audioContext;
  }

  resume: () => Promise<void> = async () => {
    if (this.audioContext == null) {
      logger.error('[AudioContextLazy.resume]: Error resuming audio context; audioContext is null');
      return;
    }
    if (this.audioContext.state !== 'running' && this.audioContext.state !== 'closed') {
      await this.audioContext.resume();
    }
  };

  updateConfiguration: (arg1: { audioSamplingRate: AudioSamplingRate }) => void = ({
    audioSamplingRate,
  }) => {
    this.audioSamplingRate = audioSamplingRate;
  };

  // Per browser auto-play policy, audio context must be resumed after a user gesture
  onUserEvent: (e: Event) => Promise<void> = async (e) => {
    await this.resume();
  };

  onGamepadConnected: (
    e: Event & {
      gamepad: Gamepad;
    }
  ) => Promise<void> = async (e) => {
    await this.resume();

    let gamepad = e.gamepad;
    this.gamepadIndex = gamepad.index;
    this.gamepadConnected = true;
    const gamepads = navigator.getGamepads?.() ?? null;
    // Gamepad and GamepadButton attributes are getters/setters and are not serializable
    logger.info('[AudioContextLazy] Gamepad connected: ', {
      gamepadIndex: this.gamepadIndex,
      buttons: gamepad.buttons.map(copyGetter),
      availableGamepads: gamepads?.map(copyGetter),
    });

    const updateGamepad = () => {
      if (this.gamepadConnected === false) {
        return;
      }
      requestAnimationFrame(updateGamepad);
      const newGamepad = navigator.getGamepads?.()[this.gamepadIndex];
      if (!newGamepad) return;

      newGamepad.buttons.forEach((button, index) => {
        /**
         * RP-3318: the 'buttons' property for a Gamepad object is considered a required type,
         * but a bug surfaced where this was not the case for some strange reason, and cannot
         * be reproduced. The optional chaining here is an extra safety check.
         */
        const gamepadButton = gamepad?.buttons?.[index];
        const oldButtonPressed = gamepadButton?.pressed;
        if (button.pressed !== oldButtonPressed) {
          if (button.pressed && (oldButtonPressed == null || oldButtonPressed === false)) {
            globalThis.dispatchEvent(
              new CustomEvent('gamepadButtonDown', {
                detail: { buttonIndex: index },
              })
            );
          }
          if (!button.pressed && (oldButtonPressed == null || oldButtonPressed === false)) {
            globalThis.dispatchEvent(
              new CustomEvent('gamepadButtonUp', { detail: { buttonIndex: index } })
            );
          }
        }
      });

      gamepad = newGamepad;
    };

    updateGamepad();
  };

  onGamepadDisconnected: (
    e: Event & {
      gamepad: Gamepad;
    }
  ) => void = (e) => {
    this.gamepadConnected = false;
    const gamepads = navigator.getGamepads?.() ?? null;
    logger.info('[AudioContextLazy] Gamepad disconnected: ', {
      gamepadIndex: e.gamepad.index,
      availableGamepads: gamepads?.map(copyGetter),
    });
  };

  /**
   * Handles initialization in regular browser context or a web worker.
   */
  addInitEventListeners() {
    globalThis.addEventListener('click', this.onUserEvent, { once: true });
    globalThis.addEventListener('doubleclick', this.onUserEvent, {
      once: true,
    });
    globalThis.addEventListener('keydown', this.onUserEvent, { once: true });
    globalThis.addEventListener('touchend', this.onUserEvent, { once: true });

    globalThis.addEventListener('gamepadconnected', this.onGamepadConnected);
    globalThis.addEventListener('gamepaddisconnected', this.onGamepadDisconnected);
  }
}

export const audioContextLazy: AudioContextLazy = new AudioContextLazy();
audioContextLazy.updateConfiguration({
  audioSamplingRate: DEFAULT_EXPERIMENTAL_CONFIGURATION.audioSamplingRate,
});
