import { Operation, Node } from 'domains/reporter/RichTextEditor/core';
// @ts-expect-error [EN-7967] - TS2305 - Module '"slate"' has no exported member 'TextUnit'.
import type { NodeEntry, TextUnit } from 'slate';
import { Point, Transforms } from 'slate';

import type { CreateEnhanceEditorState } from '../../types';
import { Editor, Range, ReactEditor } from '../../core';
import type { RequiredFieldsPluginPropertyOptions } from './types';
import {
  getEmptyTouchedRequiredFields,
  getLowestRequiredFields,
  getSelectedRequiredFields,
  getRequiredFieldsAtLocation,
  isRequiredFieldInRange,
  insertContentButPreserveRequiredFields,
  warnOnRequiredFieldDeletion,
} from '../../utils/requiredFields';
import { isEntireFieldSelected } from '../../utils/isEntireFieldSelected';
import { logger } from 'modules/logger';

export const enhanceEditorStateRequiredFields: CreateEnhanceEditorState<
  RequiredFieldsPluginPropertyOptions
> =
  ({
    pluginID,
    setConfirmDeletionDialogState,
    isDialogOpenRef,
    variant,
    enqueueToast,
    onInsertTextRequiredField,
    syncRequiredFields,
  }) =>
  (editor: Editor) => {
    const { deleteBackward, deleteForward, deleteFragment, insertFragment, insertText, apply } =
      editor;

    const confirmOrDelete = (
      direction: 'backward' | 'forward',
      isMultiple: boolean,
      onConfirm: () => void
    ) => {
      if (isDialogOpenRef?.current === false && setConfirmDeletionDialogState != null) {
        setConfirmDeletionDialogState({
          deleteCallback:
            direction === 'backward'
              ? () => deleteBackward('character')
              : () => deleteForward('character'),
          open: true,
          isMultiple,
        });
      } else {
        onConfirm();
      }
    };

    const maybeHandleRequiredFieldDeletion = ({
      editor,
      requiredFieldEntry,
      direction,
      isMultiple,
    }: {
      editor: Editor;
      requiredFieldEntry: NodeEntry<never>;
      direction: 'forward' | 'backward';
      isMultiple: boolean;
    }) => {
      const [node, path] = requiredFieldEntry;

      // @ts-expect-error [EN-7967] - TS2339 - Property 'required' does not exist on type 'never'.
      if (variant === 'report' && node?.required && enqueueToast) {
        warnOnRequiredFieldDeletion([node], enqueueToast, variant);
        logger.info(
          '[enhanceEditorStateRequiredFields] Required field deletion prevented in report',
          // @ts-expect-error [EN-7967] - TS2339 - Property 'name' does not exist on type 'never'.
          { field: node.name, direction, isMultiple, variant }
        );
      } else {
        confirmOrDelete(direction, isMultiple, () => Transforms.unwrapNodes(editor, { at: path }));
      }
    };

    editor.apply = (op: Operation) => {
      if (op.type === 'set_selection' && syncRequiredFields != null) {
        const selection = op.properties;

        try {
          if (
            selection != null &&
            Range.isRange(selection) &&
            isRequiredFieldInRange(editor, selection)
          ) {
            syncRequiredFields(selection, editor);
          }
        } catch (e: any) {
          logger.error(
            '[enhanceEditorStateRequiredFields] Error when trying to sync required fields',
            e
          );
        }
      }

      apply(op);
    };
    editor.deleteForward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;

      const currentNodes = getLowestRequiredFields(editor);

      if (currentNodes.length > 0) {
        const [, currentPath] = currentNodes[0];
        if (Editor.isEnd(editor, selection.anchor, currentPath)) {
          maybeHandleRequiredFieldDeletion({
            editor,
            requiredFieldEntry: currentNodes[0],
            direction: 'forward',
            isMultiple: currentNodes.length > 1,
          });
          return;
        }
      }

      // Check if the point after the selection is inside a bracket node. If it is,
      // then ensure that the selection is equal to the point right before that bracket node.
      const pointAfter = Editor.after(editor, selection);
      if (pointAfter != null) {
        const nodesAfter = getRequiredFieldsAtLocation(editor, pointAfter);

        if (nodesAfter.length > 0) {
          const [, nodePathAfter] = nodesAfter[0];
          const pointBefore = Editor.before(editor, nodePathAfter);

          if (
            pointBefore != null &&
            Range.isCollapsed(selection) &&
            Point.equals(selection.anchor, pointBefore)
          ) {
            maybeHandleRequiredFieldDeletion({
              editor,
              requiredFieldEntry: nodesAfter[0],
              direction: 'forward',
              isMultiple: nodesAfter.length > 1,
            });
            return;
          }
        }
      }

      deleteForward(unit);
    };

    editor.deleteBackward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;

      const currentNodes = getLowestRequiredFields(editor);

      // If the selection is currently at the start of a bracket node, that means that
      // we want to unwrap the node and delete the brackets
      if (currentNodes.length > 0) {
        const [, currentPath] = currentNodes[0];

        if (Editor.isStart(editor, selection.anchor, currentPath)) {
          maybeHandleRequiredFieldDeletion({
            editor,
            requiredFieldEntry: currentNodes[0],
            direction: 'backward',
            isMultiple: currentNodes.length > 1,
          });
          return;
        }
      }

      // Check if the point before the selection is inside a bracket node. If it is,
      // then ensure that the selection is equal to the point right after that bracket node.
      const pointBefore = Editor.before(editor, selection);
      if (pointBefore != null) {
        const nodesBefore = getRequiredFieldsAtLocation(editor, pointBefore);

        if (nodesBefore.length > 0) {
          const [, nodePathBefore] = nodesBefore[0];
          const pointAfter = Editor.after(editor, nodePathBefore);

          if (
            pointAfter != null &&
            Range.isCollapsed(selection) &&
            Point.equals(selection.anchor, pointAfter)
          ) {
            maybeHandleRequiredFieldDeletion({
              editor,
              requiredFieldEntry: nodesBefore[0],
              direction: 'backward',
              isMultiple: nodesBefore.length > 1,
            });
            return;
          }
        }
      }

      deleteBackward(unit);
    };

    editor.deleteFragment = () => {
      const { selection } = editor;

      if (!selection) return;

      const requiredNodeEntries = getSelectedRequiredFields(editor);

      const entirelySelectedRequiredEntries = requiredNodeEntries.filter(
        ([node, path]: [any, any]) => isEntireFieldSelected(editor, path, selection)
      );
      if (entirelySelectedRequiredEntries.length === 0) {
        return deleteFragment();
      }

      if (variant === 'report' && enqueueToast) {
        Transforms.delete(editor, { at: selection });
        const requiredNodes = entirelySelectedRequiredEntries.map(([node, path]: [any, any]) => ({
          ...node,
          children: [{ text: '' }],
        }));
        Transforms.insertFragment(editor, requiredNodes);

        warnOnRequiredFieldDeletion(requiredNodes, enqueueToast, variant);
        logger.info(
          '[enhanceEditorStateRequiredFields] Required field deletion prevented in deleteFragment',
          { fieldNames: requiredNodes.map((node) => node.name), variant }
        );

        return;
      }
      // If the dialog is open, then we can't short circuit and need to call deleteFragment
      else if (
        entirelySelectedRequiredEntries.length > 0 &&
        isDialogOpenRef?.current === false &&
        setConfirmDeletionDialogState != null
      ) {
        setConfirmDeletionDialogState({
          open: true,
          deleteCallback: deleteFragment,
          isMultiple: entirelySelectedRequiredEntries.length > 1,
        });
        return; // do not delete the fragment unless the dialog confirms
      }

      return deleteFragment();
    };

    editor.insertFragment = (fragment: Array<Node>) => {
      const { selection } = editor;

      if (!selection) return;

      const requiredNodeEntries = getSelectedRequiredFields(editor);
      const entirelySelectedRequiredEntries = requiredNodeEntries.filter(
        ([node, path]: [any, any]) => isEntireFieldSelected(editor, path, selection)
      );
      const insertFragmentCallback = () => insertFragment(fragment);

      if (variant === 'report' && entirelySelectedRequiredEntries.length > 0) {
        return insertContentButPreserveRequiredFields({
          editor,
          entirelySelectedRequiredEntries,
          selection,
          onComplete: insertFragmentCallback,
          enqueueToast,
          variant,
        });
      } else if (
        entirelySelectedRequiredEntries.length > 0 &&
        isDialogOpenRef?.current === false &&
        setConfirmDeletionDialogState != null
      ) {
        setConfirmDeletionDialogState({
          open: true,
          deleteCallback: insertFragmentCallback,
          isMultiple: true,
        });
        return; // do not delete the fragment unless the dialog confirms
      }

      return insertFragment(fragment);
    };

    editor.insertText = (text: string) => {
      const { selection } = editor;

      if (!selection) return;

      if (onInsertTextRequiredField !== undefined) {
        const emptyTouchedRequiredFields = getEmptyTouchedRequiredFields(editor, selection);
        for (const [node] of emptyTouchedRequiredFields) {
          // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("slate-react").Key' is not assignable to parameter of type 'React.Key'.
          onInsertTextRequiredField(ReactEditor.findKey(editor, node));
        }
      }

      // If the selection is collapsed then we won't overwrite anything, so we
      // can just insert the text
      if (Range.isCollapsed(selection)) {
        return insertText(text);
      }

      const selectedRequiredNodeEntries = getSelectedRequiredFields(editor);
      const entirelySelectedRequiredEntries = selectedRequiredNodeEntries.filter(
        ([node, path]: [any, any]) => isEntireFieldSelected(editor, path, selection)
      );

      if (entirelySelectedRequiredEntries.length === 0) {
        return insertText(text);
      }
      const insertTextCallback = () => insertText(text);

      if (variant === 'report' && entirelySelectedRequiredEntries.length > 0) {
        return insertContentButPreserveRequiredFields({
          editor,
          entirelySelectedRequiredEntries,
          selection,
          onComplete: insertTextCallback,
          enqueueToast,
          variant,
        });
      } else if (
        isDialogOpenRef?.current === false &&
        setConfirmDeletionDialogState != null &&
        selectedRequiredNodeEntries.length > 0
      ) {
        setConfirmDeletionDialogState({
          open: true,
          deleteCallback: insertTextCallback,
          isMultiple: selectedRequiredNodeEntries.length > 1,
        });
        return; // do not insert the text unless the dialog confirms
      }

      return insertText(text);
    };

    return editor;
  };
